import React from 'react'
import Modal from 'react-modal'

const modalStyle = {
  content: {
    position: 'absolute', // Make sure it's absolutely positioned
    inset: 'auto 0 0',
    width: '100%', // Full width
    padding: '10px', // Optional padding
    height: 'max-content',
    zIndex: 10,
  },
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 9,
    backgroundColor: '#69696980',
  },
}

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'start',
  margin: '0 0 1em',
  fontSize: '18px',
  fontWeight: 'bold',
}

const xButtonStyle = {
  border: 0,
  background: 0,
  fontSize: '2.5em',
}

const ErrorModal = ({ isOpen, onRequestClose, headerContent, children }) => {
  return (
    <Modal
      style={modalStyle}
      isOpen={isOpen}
      appElement={document.getElementById('main')}
      contentLabel="Menu"
      onRequestClose={onRequestClose}
    >
      <div className="MobileBottomMenu header" style={headerStyle}>
        {headerContent}
        <button
          className="x-button"
          type="button"
          onClick={onRequestClose}
          style={xButtonStyle}
        >
          ×
        </button>
      </div>
      <div className="MobileBottomMenu content">{children}</div>
    </Modal>
  )
}

export default ErrorModal
