import React from 'react'

import './SavedObservationsTable.scss'
import RecordsTable from '../application/RecordsTable'
import {
  savedObservationsColumnsConfig,
  SESSION_STATUS_COMMITTED,
} from './observation_config'

// at a location, there may be many plantings, with some selected trees in each
// we need to summarize that as best we can for the table
// this makes a string like: "Planting Name (count), Planting Name (count)"
const plantingNames = (record) => {
  // We use reduce to accumulate counts of each planting_name
  const plantingToCount = record.wizard_json.selected_trees.reduce(
    (acc, tree) => {
      const name = tree.planting_name
      if (acc[name]) {
        acc[name] += 1
      } else {
        acc[name] = 1
      }
      return acc
    },
    {}
  )

  return Object.entries(plantingToCount)
    .map(([key, value]) => `${key} (${value})`)
    .join(', ')
}

const progress = (record) => {
  if (record.status === SESSION_STATUS_COMMITTED) return 'done'
  const wj = record.wizard_json
  const treeStep =
    wj.current_step === 3
      ? ` (tree ${wj.tree_index + 1} of ${wj.selected_trees.length})`
      : ''
  return `Step ${wj.current_step} of 4${treeStep}`
}

const SavedObservationsTable = ({ records }) => {
  // flatten some deep records fields to the top of the record, to make the RecordsTable render simpler
  const renderRecords = records.map((r) => {
    return {
      id: r.id,
      location: r.wizard_json?.selected_location?.name || 'Unknown',
      updated_at: new Date(r.updated_at).getTime(), // epoch time here for sorting
      plantings: plantingNames(r) || 'Unknown',
      progress: progress(r),
      status: r.status,
    }
  })
  return (
    <div className="SavedObservationsTable">
      <RecordsTable
        records={renderRecords}
        columns={savedObservationsColumnsConfig}
      />
    </div>
  )
}

export default SavedObservationsTable
