import React, { useState, useEffect } from 'react'

import './AddPlantingWizard.scss'
import {
  AddPlantingProgressBar,
  STEP_REVIEW_AND_FINISH,
  STEP_DEFINE_TREE,
  STEP_DEFINE_LOCATION_DETAILS,
  STEP_DEFINE_LOCATION_ADDRESS,
} from './AddPlantingProgressBar'
import { MANY_TREES } from '../../shared/constants'
import AddPlantingNavButtonBar from './AddPlantingNavButtonBar'
import DefinePlantingLocation from './DefinePlantingLocation'
import DefinePlantingDetails from './DefinePlantingDetails'
import ReviewAndFinish from './ReviewAndFinish'
import WarningModal from './WarningModal'
import ErrorModal from './ErrorModal'
import DefineTree from './DefineTree'
import {
  fetchPicklist,
  fetchWizardPlanting,
  deletePlanting,
  createPlanting,
  fetchExistingLocationEntities,
} from '../../shared/utils'

const AddPlantingWizard = ({ chapterId }) => {
  const [treesComplete, setTreesComplete] = useState(false)

  const [locationType, setLocationType] = useState('private')
  const [isNewLocation, setIsNewLocation] = useState(true)
  const [enableNext, setEnableNext] = useState(false)
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorModalDestination, setErrorModalDestination] = useState(null)
  const [errorCount, setErrorCount] = useState(null)
  const [fieldFocus, setFieldFocus] = useState(null)
  const [currTreeIndex, setCurrTreeIndex] = useState(0)
  const [treeTypeOptions, setTreeTypeOptions] = useState([
    { value: '', label: 'Loading...' },
  ])
  const [existingLocationEntities, setExistingLocationEntities] = useState([])

  const [plantingSize, setPlantingSize] = useState(null)
  const [locationName, setLocationName] = useState('')
  const [plantingDate, setPlantingDate] = useState(null)
  const [location, setLocation] = useState(null)
  const [definedTrees, setDefinedTrees] = useState([])
  const [id, setId] = useState(null)
  const [currStep, setCurrStep] = useState(STEP_DEFINE_LOCATION_ADDRESS)

  const [currName, setCurrName] = useState('')
  const [currTreeType, setCurrTreeType] = useState(null)
  const [currPlantedAs, setCurrPlantedAs] = useState(null)
  const [currLat, setCurrLat] = useState(null)
  const [currLng, setCurrLng] = useState(null)

  const [warningHeaderMessage, setWarningHeaderMessage] = useState(null)
  const [warningInfoMessage, setWarningInfoMessage] = useState(null)
  const [warningQuestionMessage, setWarningQuestionMessage] = useState(null)
  const [warningCancelButtonMessage, setWarningCancelButtonMessage] =
    useState(null)
  const [warningContinueButtonMessage, setWarningContinueButtonMessage] =
    useState(null)
  const [warningHandleContinue, setWarningHandleContinue] = useState(null)
  const [warningHandleCancel, setWarningHandleCancel] = useState(null)

  const handleWizardData = (jsonData) => {
    const data = jsonData.wizard_json
    setLocation(data.location)
    setLocationName(data.location.name)
    setLocationType(data.type)
    setPlantingDate(new Date(data.date))
    setPlantingSize(data.plantingSize)
    setCurrTreeIndex(data.currTreeIndex)
    setDefinedTrees(data.trees)
    setTreesComplete(data.treesComplete)
    setIsNewLocation(data.isNewLocation)
    setCurrName(data.currName)
    setCurrTreeType(data.currTreeType)
    setCurrPlantedAs(data.currPlantedAs)
    setCurrLat(data.currLat)
    setCurrLng(data.currLng)

    if (data.trees.length === data.plantingSize) {
      setCurrTreeIndex(data.plantingSize - 1)
      setEnableNext(true)
    } else {
      setCurrTreeIndex(data.trees.length)
    }

    if (data.treesComplete) {
      setCurrStep(STEP_REVIEW_AND_FINISH)
      setEnableNext(true)
    } else {
      setCurrStep(STEP_DEFINE_TREE)
    }
  }

  useEffect(() => {
    let isMounted = true
    fetchPicklist('Tree', 'planted_as').then((picklist) => {
      if (isMounted) setTreeTypeOptions(picklist)
    })
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    setCurrPlantedAs(
      treeTypeOptions.find((option) => option.value === 'seedling')
    )
  }, [treeTypeOptions])

  useEffect(() => {
    if (!location?.id) return
    let isMounted = true
    fetchExistingLocationEntities(location?.id).then((object) => {
      if (isMounted) setExistingLocationEntities(object)
    })
    return () => {
      isMounted = false
    }
  }, [location?.id])

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const lat = urlParams.get('loc_lat')
    const lng = urlParams.get('loc_lng')
    const locName = urlParams.get('loc_name')
    const locId = urlParams.get('loc_id')
    const locPublic = urlParams.get('loc_public')
    const plantingId = urlParams.get('id')

    if (plantingId) {
      setId(plantingId)
      fetchWizardPlanting(plantingId).then((jsonData) => {
        handleWizardData(jsonData)
      })
    } else if (locId && (lat === 'null' || lng === 'null')) {
      setErrorMessage(
        'This location does not have a latitude or longitude. Please update the parcel before continuing.'
      )
      setErrorCount(1)
      setErrorModalDestination(`/parcels/${locId}`)
      setIsErrorModalOpen(true)
    } else if (lat && lng && locName && locId) {
      setLocation({
        id: locId,
        address: locName,
        locationType: locName,
        pos: { lat: parseFloat(lat), lng: parseFloat(lng) },
      })
      setLocationName(locName)
      setLocationType(locPublic === 'true' ? 'public' : 'private')
      setCurrStep(STEP_DEFINE_LOCATION_DETAILS)
      setIsNewLocation(false)
    }
  }, [])

  useEffect(() => {
    if (currStep === STEP_DEFINE_TREE && definedTrees.length > 0)
      setEnableNext(true)
  }, [definedTrees])

  const handleCancelPlantingYes = () => {
    setIsWarningModalOpen(false)
    window.location.href = '/'
  }

  const handleCancelPlantingNo = () => {
    setIsWarningModalOpen(false)
  }

  const handlePlantingDelete = () => {
    if (
      window.confirm(`Delete this planting with ${definedTrees.length} trees?`)
    ) {
      deletePlanting(id)
    }
  }

  const handleContinuePlantingCancel = () => {
    setIsWarningModalOpen(false)
  }

  const handleContinuePlantingContinue = () => {
    setIsWarningModalOpen(false)
    setTreesComplete(true)
    setPlantingSize(definedTrees.length)
    setCurrStep(STEP_REVIEW_AND_FINISH)
  }

  const handleEditLocation = () => {
    setCurrStep(STEP_DEFINE_LOCATION_ADDRESS)
    setEnableNext(true)
  }

  const handleNewLocation = (newLocation) => {
    if (newLocation && newLocation.address && newLocation.pos) {
      setLocation(newLocation)
      setEnableNext(true)
    }
  }

  const handleNewDetails = (newName, newType, newDate, newNumTrees) => {
    setLocationName(newName)
    setLocationType(newType)
    setPlantingDate(newDate)
    setPlantingSize(parseInt(newNumTrees))
    setEnableNext(true)
  }

  const handleInvalidDetails = () => {
    setEnableNext(false)
  }

  const handleDetailsEdit = (field) => {
    setFieldFocus(field)
    setCurrStep(STEP_DEFINE_LOCATION_DETAILS)
  }

  const handleTreeEdit = (treeIndex) => {
    setCurrTreeIndex(treeIndex)
    setCurrStep(STEP_DEFINE_TREE)
  }

  const handleTreeAdd = () => {
    setCurrTreeIndex(definedTrees.length)
    if (plantingSize !== Number.MAX_SAFE_INTEGER) {
      setPlantingSize(plantingSize + 1)
    }
    setCurrStep(STEP_DEFINE_TREE)
  }

  const handleCancel = () => {
    setWarningHeaderMessage('Exit this wizard?')
    setWarningInfoMessage(
      "If you exit, you will lose the data you've entered so far"
    )
    setWarningQuestionMessage(
      'Are you sure you want to leave the New Planting Wizard?'
    )
    setWarningCancelButtonMessage('Go back')
    setWarningContinueButtonMessage('Yes, cancel')
    setWarningHandleCancel(() => handleCancelPlantingNo)
    setWarningHandleContinue(() => handleCancelPlantingYes)
    setIsWarningModalOpen(true)
  }

  const handleNextStep = () => {
    const treeStr = definedTrees.length === 1 ? 'tree' : 'trees'

    const formatErrors = (errors) => {
      return (
        <div>
          {Object.keys(errors).map((model) => {
            return (
              <div key={model}>
                <h2>Errors in {model}</h2>
                <ul>
                  {Array.isArray(errors[model])
                    ? errors[model].map((name) => {
                        return (
                          <li key={name}>
                            <span>{name}</span>
                          </li>
                        )
                      })
                    : Object.keys(errors[model]).map((name) => {
                        return (
                          <li key={name}>
                            <span>{name}</span>
                            <ul>
                              {errors[model][name].map((error) => {
                                return <li key={error}>{error}</li>
                              })}
                            </ul>
                          </li>
                        )
                      })}
                </ul>
              </div>
            )
          })}
        </div>
      )
    }

    const countErrors = (errors) => {
      let count = 0
      Object.keys(errors).forEach((model) => {
        count += 1
        if (Array.isArray(errors[model])) {
          count += errors[model].length + 1
        } else {
          Object.keys(errors[model]).forEach((name) => {
            count += errors[model][name].length + 1
          })
        }
      })
      return count
    }

    if (treesComplete && currStep !== STEP_REVIEW_AND_FINISH)
      setCurrStep(STEP_REVIEW_AND_FINISH)
    else {
      switch (currStep) {
        case STEP_DEFINE_LOCATION_ADDRESS:
          setEnableNext(false)
          setCurrStep(STEP_DEFINE_LOCATION_DETAILS)
          break
        case STEP_DEFINE_LOCATION_DETAILS:
          setEnableNext(definedTrees.length > 0)
          setCurrStep(STEP_DEFINE_TREE)
          break
        case STEP_DEFINE_TREE:
          if (
            (plantingSize > definedTrees.length &&
              plantingSize !== Number.MAX_SAFE_INTEGER) ||
            (definedTrees.length < 10 &&
              plantingSize === Number.MAX_SAFE_INTEGER)
          ) {
            setWarningHeaderMessage(
              `Continue with planting ${definedTrees.length} ${treeStr}?`
            )
            setWarningInfoMessage(
              `The total number of planted trees in this planting is ${
                plantingSize === Number.MAX_SAFE_INTEGER
                  ? MANY_TREES
                  : plantingSize
              }.`
            )
            setWarningQuestionMessage(
              `Do you want to continue with planting ${definedTrees.length} ${treeStr} instead?`
            )
            setWarningCancelButtonMessage('Cancel')
            setWarningContinueButtonMessage('Yes, continue')
            setIsWarningModalOpen(true)
            setWarningHandleContinue(() => handleContinuePlantingContinue)
            setWarningHandleCancel(() => handleContinuePlantingCancel)
          } else {
            setCurrStep(STEP_REVIEW_AND_FINISH)
            setTreesComplete(true)
          }
          break
        case STEP_REVIEW_AND_FINISH:
          createPlanting(
            id,
            locationType,
            plantingSize,
            locationName,
            plantingDate,
            location,
            definedTrees,
            treesComplete,
            isNewLocation,
            currTreeIndex,
            null,
            null,
            null,
            null,
            null,
            'complete'
          ).then((errors) => {
            if (!errors) window.location.href = '/'
            else {
              console.warn('Got errors', errors)
              try {
                setErrorMessage(formatErrors(errors))
                setErrorCount(countErrors(errors))
              } catch {
                setErrorMessage(errors)
                setErrorCount(2)
              }
              setErrorModalDestination(null)
              setIsErrorModalOpen(true)
            }
          })
          break
        default:
          console.warn('Have not implemented logic for', currStep)
      }
    }
  }

  const handleCreatePlantingError = (error) => {
    setErrorMessage(error)
    setErrorCount(1)
    setIsErrorModalOpen(true)
    setErrorModalDestination('/')
  }

  const handleSave = () => {
    createPlanting(
      id,
      locationType,
      plantingSize,
      locationName,
      plantingDate,
      location,
      definedTrees,
      treesComplete,
      isNewLocation,
      currTreeIndex,
      currName,
      currTreeType,
      currPlantedAs,
      currLat,
      currLng,
      'incomplete'
    ).then((error) => {
      if (error) {
        handleCreatePlantingError(error)
      } else {
        window.location.href = '/'
      }
    })
  }

  const handlePrevStep = () => {
    switch (currStep) {
      case STEP_DEFINE_LOCATION_ADDRESS:
        // TODO
        break
      case STEP_DEFINE_LOCATION_DETAILS:
        setCurrStep(STEP_DEFINE_LOCATION_ADDRESS)
        setEnableNext(true)
        break
      case STEP_DEFINE_TREE:
        setCurrStep(STEP_DEFINE_LOCATION_DETAILS)
        setEnableNext(true)
        break
      case STEP_REVIEW_AND_FINISH:
        setCurrStep(STEP_DEFINE_TREE)
        setEnableNext(true)
        break
      default:
        console.warn('Have not implemented prevstep logic for', currStep)
    }
  }

  const addTreeToDefinedTrees = (name, treeType, plantedAs, lat, lng) => {
    const tmpTrees = [...definedTrees]
    const tree = { name, treeType, plantedAs, lat, lng }
    tmpTrees.push(tree)
    setDefinedTrees(tmpTrees)
    setCurrTreeIndex(tmpTrees.length)
  }

  const updateTreeInDefinedTrees = (
    treeIndex,
    name,
    treeType,
    plantedAs,
    lat,
    lng
  ) => {
    const tmpTrees = [...definedTrees]
    tmpTrees[treeIndex] = { name, treeType, plantedAs, lat, lng }
    setDefinedTrees(tmpTrees)
  }

  return (
    <div id="AddPlantingWizard">
      <div className="columns">
        <div className="progress column">
          <AddPlantingProgressBar
            definedTrees={definedTrees}
            handleDetailsEdit={handleDetailsEdit}
            handleTreeEdit={handleTreeEdit}
            locationName={locationName}
            plantingDate={plantingDate}
            plantingSize={plantingSize}
            step={currStep}
          />
        </div>
        <div className="info column">
          {currStep === STEP_DEFINE_LOCATION_ADDRESS && (
            <DefinePlantingLocation
              location={location}
              setLocation={handleNewLocation}
            />
          )}
          {currStep === STEP_DEFINE_LOCATION_DETAILS && (
            <DefinePlantingDetails
              existingLocationEntities={existingLocationEntities}
              fieldFocus={fieldFocus}
              handleEditLocation={handleEditLocation}
              handleInvalidDetails={handleInvalidDetails}
              handleNewDetails={handleNewDetails}
              isNewLocation={isNewLocation}
              location={location}
              prevLocationName={locationName}
              prevLocationType={locationType}
              prevNumTrees={plantingSize}
              prevPlantingDate={plantingDate}
            />
          )}
          {currStep === STEP_DEFINE_TREE && (
            <DefineTree
              addTreeToDefinedTrees={addTreeToDefinedTrees}
              chapterId={chapterId}
              currTreeIndex={currTreeIndex}
              definedTrees={definedTrees}
              lat={currLat}
              lng={currLng}
              location={location}
              existingLocationEntities={existingLocationEntities}
              name={currName}
              numTrees={plantingSize}
              plantedAs={currPlantedAs}
              setCurrTreeIndex={setCurrTreeIndex}
              setDefinedTrees={setDefinedTrees}
              setLat={setCurrLat}
              setLng={setCurrLng}
              setName={setCurrName}
              setPlantedAs={setCurrPlantedAs}
              setPlantingSize={setPlantingSize}
              setTreeType={setCurrTreeType}
              treeType={currTreeType}
              treeTypeOptions={treeTypeOptions}
              updateTreeInDefinedTrees={updateTreeInDefinedTrees}
            />
          )}
          {currStep === STEP_REVIEW_AND_FINISH && (
            <ReviewAndFinish
              definedTrees={definedTrees}
              handleDetailsEdit={handleDetailsEdit}
              handleTreeAdd={handleTreeAdd}
              handleTreeEdit={handleTreeEdit}
              location={location}
              locationName={locationName}
              plantingDate={plantingDate}
            />
          )}
          <AddPlantingNavButtonBar
            enablePrevious={currStep !== STEP_DEFINE_LOCATION_ADDRESS}
            enableNext={enableNext}
            nextStep={handleNextStep}
            prevStep={handlePrevStep}
            enableSave={
              currStep === STEP_DEFINE_TREE ||
              currStep === STEP_REVIEW_AND_FINISH
            }
            save={handleSave}
            isFinal={currStep === STEP_REVIEW_AND_FINISH}
            handleCancel={handleCancel}
            handleDelete={handlePlantingDelete}
            enableDelete={id}
          />
        </div>
      </div>
      <ErrorModal
        message={errorMessage}
        heightHintMessageLines={errorCount}
        destination={errorModalDestination}
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
      />
      <WarningModal
        handleCancel={warningHandleCancel}
        handleContinue={warningHandleContinue}
        isOpen={isWarningModalOpen}
        headerMessage={warningHeaderMessage}
        infoMessage={warningInfoMessage}
        questionMessage={warningQuestionMessage}
        cancelMessage={warningCancelButtonMessage}
        continueMessage={warningContinueButtonMessage}
      />
    </div>
  )
}

export default AddPlantingWizard
