// Can be used as a controlled or uncontrolled component

import React from 'react'
import PropTypes from 'prop-types'
import { AsyncPaginate } from 'react-select-async-paginate'
import { parseRailsInput } from './parseRailsInput'

const SelectAsyncPaginatedRansack = ({
  classNamePrefix,
  defaultValue,
  disabled,
  extraPredicates,
  extraFields,
  input,
  inputId,
  isClearable,
  isMulti,
  json_data,
  label,
  model_name_plural,
  onChange,
  options,
  optionValue,
  ransack_predicate,
  select,
  styles,
  value,
  writeable,
}) => {
  const itemsPerPage = 25
  const parsedInput = parseRailsInput(input)

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const params = {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      json_data,
      filters: {
        [ransack_predicate]: searchQuery,
        ...(extraPredicates || {}),
      },
      writeable,
      select,
      extra_fields: [label, optionValue, ...extraFields],
    }

    const responseJSON = await fetch(
      `/${model_name_plural}/ransack.json?${$.param(params)}`
    ).then((response) => response.json())

    return {
      options: responseJSON,
      hasMore: responseJSON.length === itemsPerPage,
      additional: {
        page: page + 1,
      },
    }
  }

  return (
    <div className="SelectAsyncPaginatedRansack">
      <AsyncPaginate
        additional={{ page: 1 }}
        cacheOptions
        cacheUniqs={[
          model_name_plural,
          ransack_predicate,
          JSON.stringify(extraPredicates),
        ]}
        classNamePrefix={classNamePrefix}
        defaultValue={defaultValue} // when used as an uncontrolled component (ie directly from a haml form)
        isDisabled={disabled}
        getOptionLabel={(e) => e[label]}
        getOptionValue={(e) => e[optionValue]}
        id={parsedInput && parsedInput.id}
        inputId={inputId}
        isClearable={isClearable}
        isMulti={isMulti}
        loadOptions={loadOptions}
        name={parsedInput && parsedInput.name}
        onChange={onChange}
        options={options || undefined}
        styles={styles}
        value={value || ''}
      />
    </div>
  )
}

SelectAsyncPaginatedRansack.propTypes = {
  input: PropTypes.string,
  json_data: PropTypes.bool,
  ransack_predicate: PropTypes.string.isRequired,
  extraPredicates: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  writeable: PropTypes.bool,
  select: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  label: PropTypes.string.isRequired,
  optionValue: PropTypes.string.isRequired,
  model_name_plural: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
  }),
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  styles: PropTypes.object,
  isClearable: PropTypes.bool,
  extraFields: PropTypes.arrayOf(PropTypes.string),
}

SelectAsyncPaginatedRansack.defaultProps = {
  input: undefined,
  json_data: false,
  extraFields: [],
  extraPredicates: {},
  writeable: false,
  select: undefined,
  defaultValue: null,
  isMulti: false,
  options: [],
  styles: {},
  isClearable: true,
}

export default SelectAsyncPaginatedRansack
