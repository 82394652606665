import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SelectAsyncPaginatedRansack from './SelectAsyncPaginatedRansack'
import { OrgSelectInput } from './OrgInputs'
import './ChapterTreeRansackUncontrolled.scss'

const ChapterTreeRansackUncontrolled = ({
  chapters,
  disabled,
  tree,
  input,
  wild,
}) => {
  const [currentTree, setCurrentTree] = useState(tree)

  const extraPredicates = {}
  if (wild) extraPredicates.wild_eq = true
  if (currentTree?.chapter_id)
    extraPredicates.chapter_id_eq = currentTree.chapter_id

  return (
    <div className="ChapterTreeRansackUncontrolled">
      <label
        htmlFor="ChapterTreeRansackUncontrolled-chapter"
        style={{ minWidth: '400px' }}
      >
        Tree Chapter
        <OrgSelectInput
          inputId="ChapterTreeRansackUncontrolled-chapter"
          options={chapters}
          onChange={(chapterId) =>
            setCurrentTree({ chapter_id: chapterId, id: '' })
          }
          value={currentTree?.chapter_id || ''}
          placeholder="Any"
        />
      </label>

      <label
        htmlFor="ChapterTreeRansackUncontrolled-tree"
        style={{ minWidth: '400px' }}
      >
        Tree
        <SelectAsyncPaginatedRansack
          disabled={disabled}
          onChange={setCurrentTree}
          input={input}
          inputId="ChapterTreeRansackUncontrolled-tree"
          model_name_plural="trees"
          optionValue="id"
          json_data={false}
          ransack_predicate="shortcode_cont"
          select={['shortcode', 'id', 'chapter_id']}
          label="shortcode"
          value={currentTree}
          writeable
          placeholder="Select a tree"
          extraPredicates={extraPredicates}
        />
      </label>
    </div>
  )
}

ChapterTreeRansackUncontrolled.defaultProps = {
  disabled: false,
  tree: undefined,
}

ChapterTreeRansackUncontrolled.propTypes = {
  chapters: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  disabled: PropTypes.bool,
  tree: PropTypes.shape({
    id: PropTypes.number,
    shortcode: PropTypes.string,
    chapter_id: PropTypes.number,
  }),
  wild: PropTypes.bool.isRequired,
}

export default ChapterTreeRansackUncontrolled
