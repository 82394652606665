import React from 'react'
import CogtextMenu from './CogtextMenu.jsx'
import UserIconLight from '../../shared/images/user_icon_light.svg'
import UserIconDark from '../../shared/images/user_icon_dark.svg'

const ProfileMenu = ({ menu_items, profile_name }) => {
  const desktopButton = <div>{profile_name}</div>
  const mobileButton = <UserIconLight alt="user menu" />

  const mobileHeaderContent = (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <UserIconDark />
      {profile_name}
    </div>
  )

  return (
    <div className="ProfileMenu">
      <CogtextMenu
        desktopButton={desktopButton}
        menuItems={menu_items}
        mobileButton={mobileButton}
        mobileHeaderContent={mobileHeaderContent}
      />
    </div>
  )
}

export default ProfileMenu
