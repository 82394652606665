import React, { useEffect, useReducer, useState } from 'react'
import _capitalize from 'lodash/capitalize'
import MapCodeInput from './MapCodeInput'
import { deepMerge, fetchRecords } from '../../../shared/utils.js'
import SelectAsyncPaginatedRansack from '../../application/SelectAsyncPaginatedRansack'
import ToggleButtons from '../../application/ToggleButtons.jsx'
import { OrgSelectInput } from '../../application/OrgInputs.jsx'
import GenericModal from '../../application/GenericModal.jsx'
import './PlantingItemModal.scss'

const inputId = 'planting_item_genetic_origin_input'

const itemModeOptions = ['Cross', 'Genet'] // TODO: add graft
const pluralize = (word) => word + (word.endsWith('s') ? 'es' : 's')

const asyncPaginatedProps = (mode) => ({
  key: mode,
  model_name_plural: pluralize(mode.toLocaleLowerCase()),
  ransack_predicate:
    mode === 'Genet' ? 'name_cont_or_shortcode_cont' : 'name_cont',
  optionValue: 'id',
  label: 'name',
})

const PlantingItemModal = ({
  chapters,
  isOpen,
  onCancel,
  plantingItem,
  otherPlantingItems,
  updatePlantingItem,
}) => {
  const [currentPlantingItem, setCurrentPlantingItem] = useState(
    JSON.parse(JSON.stringify(plantingItem)) || {}
  )

  const [errors, updateErrors] = useReducer(deepMerge, {})
  const [itemMode, setItemMode] = useState(itemModeOptions[0])
  const [filters, updateFilters] = useReducer(deepMerge, {})
  const [selectOptions, updateSelectOptions] = useReducer(deepMerge, {})

  const handleChangeItemMode = (mode) => {
    // clear the current planting item when changing modes
    setCurrentPlantingItem({})
    setItemMode(mode)
  }

  // when the itemMode changes, clear th

  useEffect(() => {
    if (itemMode !== 'Cross') return

    updateSelectOptions({ year_eq: null }) // causes "loading..." placeholder
    const loadOptions = async () => {
      const result = await fetchRecords({
        url: '/crosses/ransack.json',
        processResult: (data) =>
          Object.entries(data)
            .sort((a, b) => b[0] - a[0])
            .map(([year, count]) => ({
              value: year,
              label: `${year} (${count})`,
            })),
        params: {
          filters: { chapter_id_eq: filters.Cross?.chapter_id_eq },
          count_by: 'year',
        },
      })
      updateSelectOptions({ year_eq: result })
    }

    loadOptions()
  }, [itemMode, JSON.stringify(filters)])

  const filterInputs = (mode) => {
    const chapterFilter = (
      <OrgSelectInput
        singleRow
        onChange={(id) => {
          itemModeOptions.forEach((m) =>
            updateFilters({ [m]: { chapter_id_eq: id } })
          )
        }}
        options={chapters}
        placeholder="Any..."
        value={filters[mode]?.chapter_id_eq}
        label="Chapter"
      />
    )

    switch (mode) {
      case 'Cross':
        return (
          <>
            {chapterFilter}
            <OrgSelectInput
              singleRow
              placeholder={selectOptions.year_eq ? 'Any...' : 'Loading...'}
              onChange={(year_eq) => updateFilters({ Cross: { year_eq } })}
              options={selectOptions.year_eq || []}
              value={filters.Cross?.year_eq}
              label="Year"
            />
          </>
        )
      case 'Genet':
        return chapterFilter
      default:
        return ''
    }
  }

  const formIsComplete =
    currentPlantingItem.code &&
    currentPlantingItem.genetic_origin_type &&
    !Object.values(errors).some(Boolean)

  const handleEnter = () => {
    const alreadyChosen = otherPlantingItems.find(
      (pi) =>
        pi.genetic_origin_type === currentPlantingItem.genetic_origin_type &&
        pi.genetic_origin_id === currentPlantingItem.genetic_origin_id
    )

    if (alreadyChosen) {
      const text = `This item (${currentPlantingItem.genetic_origin.displayName}) has already been chosen with mapcode ${alreadyChosen.code}. Are you sure you want to add it again?`

      // TODO: replace with a modal
      // eslint-disable-next-line no-alert
      if (!window.confirm(text)) return
    }

    updatePlantingItem(currentPlantingItem)
  }

  return (
    <div id="PlantingItemModal">
      <GenericModal
        isOpen={isOpen}
        header={`${(plantingItem || {}).type ? 'Edit' : 'Add'} Planting Item`}
        appElementId="PlantingItemModal"
        contentLabel="Add or Edit Planting Item"
        onRequestClose={onCancel}
        buttons={[
          { text: 'Cancel', onClick: onCancel, style: 'cancel' },
          {
            text: 'Save',
            onClick: handleEnter,
            style: 'ok',
            disabled: !formIsComplete,
          },
        ]}
      >
        <div className="PlantingItemModalInsideModal">
          <div className="inputs">
            <ToggleButtons
              onChange={handleChangeItemMode}
              value={itemMode}
              options={itemModeOptions.map((value) => ({
                value,
                label: _capitalize(value),
              }))}
            />

            <div className="genetic-origin-chooser">
              <label htmlFor={inputId}>
                <h4>Choose {_capitalize(itemMode)}</h4>
              </label>
              <div className="filter-inputs">
                {filterInputs(itemMode, updateFilters)}
              </div>
              <SelectAsyncPaginatedRansack
                // key={`${itemMode}-${JSON.stringify(filters[itemMode])}`}
                onChange={(genetic_origin) =>
                  setCurrentPlantingItem({
                    code: null,
                    type: `Planting${_capitalize(itemMode)}`,
                    genetic_origin_id: genetic_origin?.id,
                    genetic_origin_type: _capitalize(itemMode),
                    genetic_origin,
                  })
                }
                value={currentPlantingItem.genetic_origin}
                extraPredicates={filters[itemMode]}
                inputId={inputId}
                {...asyncPaginatedProps(itemMode)}
                options={[]}
              />
            </div>

            <MapCodeInput
              mapcodes={otherPlantingItems.map(({ code }) => code)}
              onChange={(code) =>
                setCurrentPlantingItem({ ...currentPlantingItem, code })
              }
              otherPlantingItems={otherPlantingItems}
              currentPlantingItem={currentPlantingItem}
              setErrors={(error) => updateErrors({ mapcode: error })}
            />
          </div>
        </div>
      </GenericModal>
    </div>
  )
}

export default PlantingItemModal
