import React from 'react'
import './PlantingActionsGearMenu.scss'
import CogtextMenu from '../application/CogtextMenu.jsx'

const styles = {
  headerContent: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 'bold',
    fontFamily: '"Helvetica"',
    color: '#333333', // $wizard-charcoal
  },
  headerContentPlanting: {
    color: '#4d8055', // $wizard-enabled-green
  },
}

const PlantingActionsGearMenu = ({ planting, showCitsciObservations }) => {
  const mobileHeaderContent = (
    <div style={styles.headerContent}>
      <div style={styles.headerContentPlanting}>Planting</div>
      <div>{planting.name}</div>
    </div>
  )

  const recordObservationsLink =
    planting.type === 'PlotlessPlanting'
      ? `/observations/wizard?planting_id=${planting.id}`
      : `/plantings/${planting.id}/observations`

  const menuItems = []

  if (showCitsciObservations) {
    menuItems.unshift(['Record observations', recordObservationsLink])
  }

  return (
    <div className="PlantingActionsGearMenu">
      <CogtextMenu
        menuItems={menuItems}
        mobileHeaderContent={mobileHeaderContent}
      />
    </div>
  )
}

export default PlantingActionsGearMenu
