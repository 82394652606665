import React from 'react'
import editSpaceSrc from '../../shared/edit-space.png'
import { toLocaleDateTimeString } from './observation_utils'

// whether to show the selectedTrees debug state (follows all wizard pages)
export const DEBUG_SHOW_SELECTED_TREES = false

// session statuses we care about
export const SESSION_STATUS_COMMITTED = 'committed'

// the configuration for the ObservationSelectTrees table view, step 1 of the wizard
// See RecordTable for configuration options
export const selectTreesColumnsConfig = (isMobileCached) => [
  {
    header: 'Name',
    cssClass: 'name',
    layouts: 'desktop mobile',
    sortableBy: 'name',
    render: (r) => (
      <a href={`/trees/${r?.id}`} target="_blank" rel="noopener noreferrer">
        {r.name}
      </a>
    ),
  },
  {
    header: 'Type',
    cssClass: 'type',
    layouts: 'desktop',
    sortableBy: 'cross_or_genet_name',
    render: (r) => r.cross_or_genet_name,
  },
  {
    header: 'Date Planted',
    cssClass: 'date-planted',
    layouts: 'desktop',
    sortableBy: 'planted_on',
    render: (r) => r.planted_on,
  },
  {
    header: 'Planting',
    cssClass: 'planting',
    layouts: 'desktop',
    sortableBy: 'planting_name',
    render: (r) => (
      <a
        href={`/plantings/${r.planting_id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {r.planting_name}
      </a>
    ),
  },
  {
    header: 'Space',
    cssClass: 'space',
    layouts: 'desktop mobile',
    render: (r) => {
      return (
        <>
          <img
            className={`tooltip-anchor-observation-map ${
              isMobileCached && 'mobile'
            }`}
            src={editSpaceSrc ?? ''}
            alt="Space details"
            data-tooltip-content={r?.id}
          />
          {isMobileCached && (
            <>
              <div className="mobile-space-latlon">
                {parseFloat(r?.latitude)?.toFixed(5)},
                <br />
                {parseFloat(r?.longitude)?.toFixed(5)}
              </div>
              <button
                type="button"
                className="mobile-space-more tooltip-anchor-observation-space"
                data-tooltip-content={r?.id}
              >
                &hellip;
              </button>
            </>
          )}
        </>
      )
    },
  },
]

// the configuration for the SavedObservationsTable in the dashboard
// See RecordTable for configuration options
export const savedObservationsColumnsConfig = [
  {
    header: 'Location',
    cssClass: 'location',
    layouts: 'desktop mobile',
    sortableBy: 'location',
    render: (r) => <span>{r.location}</span>,
  },
  {
    header: 'Planting(s) & tree count',
    cssClass: 'plantings',
    layouts: 'desktop mobile',
    render: (r) => <span>{r.plantings}</span>,
  },
  {
    header: 'Progress',
    cssClass: 'progressStep',
    layouts: 'desktop mobile',
    render: (r) => <span>{r.progress}</span>,
  },
  {
    header: 'Last Updated',
    cssClass: 'last-updated',
    layouts: 'desktop mobile',
    sortableBy: 'updated_at',
    defaultSort: true,
    defaultSortDirection: 'descending',
    render: (r) => <span>{toLocaleDateTimeString(r.updated_at)}</span>,
  },
  {
    header: '',
    cssClass: 'action',
    layouts: 'desktop mobile',
    render: (r) => {
      const action = r.status === SESSION_STATUS_COMMITTED ? 'Review' : 'Resume'
      return (
        <a href={`/observations/wizard?observation_session_id=${r.id}`}>
          {action}
        </a>
      )
    },
  },
]
