import React, { useReducer, useState, useEffect } from 'react'
import _keyBy from 'lodash/keyBy'
import { deepMerge } from '../../../shared/utils.js'
import PlantingItemChooser from './PlantingItemChooser.jsx'
import ChapterOrchardPlotsRansack from '../../application/ChapterOrchardPlotsRansack.jsx'
import PlotLayout from './PlotLayout.jsx'
import SpreadsheetInterface from './SpreadsheetInterface.jsx'
import PlantingAttributes from './PlantingAttributes.jsx'
import './EditPlottedPlanting.scss'
import SavePlantingControls from './SavePlantingControls.jsx'
import AbovePlotControls from './AbovePlotControls.jsx'

// planting_item_id may be string or number, so we use == instead of ===
// eslint-disable-next-line eqeqeq
const hasSamePlantingItem = (a, b) => a.planting_item_id == b.planting_item_id

// const processInitialPlannedTrees = (initialPlanting) => {
//   const plannedTrees = []

//   Object.values(initialPlanting?.planting_items || {}).forEach(
//     (plantingItem) => {
//       console.log(plantingItem)
//       const itemPlannedTrees = initialPlanting.planned_trees?.filter(
//         (tree) => tree.planting_item_id === plantingItem.id
//       )
//       plantingItem.transgenic_array.forEach((transgenic, index) => {
//         itemPlannedTrees[index].transgenic = transgenic
//         plannedTrees.push(itemPlannedTrees[index])
//       })
//     }
//   )

//   return plannedTrees
// }

// const _samplePlantingItems = {
//   'new-planting-item-prefilled3': {
//     type: 'PlantingCross',
//     genetic_origin_id: 53307,
//     genetic_origin_type: 'Cross',
//     genetic_origin: {
//       type: 'Cross',
//       id: 'Cross-53307',
//       name: 'AH04-3340 x CW03-2003',
//       displayName: 'Cross: AH04-3340 x CW03-2003 (2016)',
//       shortcode: null,
//       year: 2016,
//       transgenic: null,
//       transgenic_parents: null,
//       dbId: 53307,
//     },
//     code: 'AB',
//   },
//   'new-planting-item-prefilled4': {
//     type: 'PlantingGenet',
//     genitic_origin_type: 'Genet',
//     genetic_origin_id: 401613,
//     genetic_origin: {
//       type: 'Genet',
//       id: 'Genet-401613',
//       name: 'Gensel',
//       displayName: 'Genet: Gensel',
//       shortcode: 'PA-GlLu',
//       year: null,
//       transgenic: null,
//       transgenic_parents: null,
//       dbId: 401613,
//     },
//     code: 'Gen',
//   },
// }

const floatBottomCenterStyle = {
  position: 'fixed', // Using fixed to keep it at the bottom of the viewport
  top: '20px', // Margin from the bottom
  left: '50%', // Positioning at 50% from the left
  transform: 'translateX(-50%)', // Shifting it back by 50% of its own width to center it
  zIndex: 1000, // Ensures it stays on top of other content
  margin: '0 20px', // Horizontal margin of 20px from both sides
  padding: '10px', // Padding of 10px
  border: '1px solid darkgray', // Dark gray border
}

const BulkApplyFloater = ({ plantingItem, setBulkApplyPlantingItemId }) => (
  <div
    className="BulkApplyFloater"
    style={{ ...floatBottomCenterStyle, backgroundColor: plantingItem?.color }}
  >
    <h5 style={{ margin: 'auto' }}>Applying Planting Item</h5>
    <h4>{plantingItem.genetic_origin.displayName}</h4>
    <h4>Mapcode: {plantingItem.code}</h4>
    <button
      type="button"
      onClick={() => setBulkApplyPlantingItemId(null)}
      style={{ margin: 'auto' }}
    >
      Done
    </button>
  </div>
)

const EditPlottedPlanting = ({
  form_authenticity_token,
  options,
  initialPlanting,
  reviewMode,
}) => {
  const [currentPlanting, setCurrentPlanting] = useState(
    initialPlanting || { planting_items: {} }
  )
  const [plannedTrees, setPlannedTrees] = useState(
    initialPlanting?.planned_trees || []
  )
  const [plantingHasChanged, setPlantingHasChanged] = useState(false)

  const [plotInterfaceMode, setPlotInterfaceMode] = useState('list') // 'list', 'grid' or 'spreadsheet
  const [onlyAvailableSpaces, setOnlyAvailableSpaces] = useState(false)
  const [bulkApplyPlantingItemId, setBulkApplyPlantingItemId] = useState(null)
  const [plotDetails, updatePlotDetails] = useReducer(deepMerge, {})
  const plots = (currentPlanting.plot_ids || [])
    .map((id) => plotDetails[id])
    .filter(Boolean)

  const updateCurrentPlanting = (
    newAttributes,
    updatePlantingHasChanged = true
  ) => {
    if (updatePlantingHasChanged) setPlantingHasChanged(true)
    setCurrentPlanting((prev) => deepMerge(prev, newAttributes))
  }

  const plannedTreesBySpace = _keyBy(
    plannedTrees.filter((plannedTree) => !plannedTree._destroy),
    'space_id'
  )

  const checkRemainingNuts = (newAttributes) => {
    if (newAttributes._destroy) return true
    const remainingNuts =
      currentPlanting.planting_items[newAttributes.planting_item_id]
        .genetic_origin.remaining_nuts
    if (typeof remainingNuts !== 'number') return true

    const alreadyPlannedTreesCount = plannedTrees.filter(
      // planting_item_id may be string or number, so we use == instead of ===
      // eslint-disable-next-line eqeqeq
      (t) => t.planting_item_id == newAttributes.planting_item_id
    ).length

    if (alreadyPlannedTreesCount !== remainingNuts) return true // only give warning once, when we cross the limit

    // eslint-disable-next-line no-alert, no-restricted-globals
    return confirm(
      `This cross has ${remainingNuts} remaining nuts, and you have already planted that many. Are you sure you want to plant more?`
    )
  }

  const updatePlannedTrees = (newAttributes) => {
    if (!checkRemainingNuts(newAttributes)) return

    setPlantingHasChanged(true)
    setPlannedTrees((prev) => {
      const newPlannedTrees = [...prev]
      const plannedTreeInSpace = plannedTreesBySpace[newAttributes.space_id]
      if (plannedTreeInSpace) {
        if (
          newAttributes._destroy ||
          !hasSamePlantingItem(plannedTreeInSpace, newAttributes)
        )
          plannedTreeInSpace._destroy = true // eslint-disable-line no-param-reassign
        else Object.assign(plannedTreeInSpace, newAttributes) // eslint-disable-line no-param-reassign
      }
      if (
        !newAttributes._destroy &&
        (!plannedTreeInSpace ||
          !hasSamePlantingItem(plannedTreeInSpace, newAttributes))
      )
        newPlannedTrees.push(newAttributes)
      return newPlannedTrees
    })
  }

  const handleSetPlots = (newPlots) => {
    const getRemovedPlannedTrees = (plot_id) =>
      plannedTrees.filter((plannedTree) => plot_id === plannedTree.plot_id)

    const removedPlotIds = (currentPlanting.plot_ids || []).filter(
      (id) => !newPlots.find((newPlot) => id === newPlot.id)
    )

    const warning = removedPlotIds
      .map((id) => {
        const removedPlannedTrees = getRemovedPlannedTrees(id)
        if (!removedPlannedTrees?.length) return ''

        const plural =
          removedPlannedTrees.length > 1 ? ['s', 'those'] : ['', 'that']
        return `${plotDetails[id]?.name || 'removed plot'} has ${removedPlannedTrees.length} planned tree${plural[0]}. Are you sure you want to remove the plot and erase ${plural[1]} tree${plural[0]}?`
      })
      .filter(Boolean)
      .join('\n\n')

    if (warning && !confirm(warning)) return false // eslint-disable-line no-restricted-globals

    // User has confirmed, remove all the trees from the removed plots
    removedPlotIds.forEach((id) =>
      getRemovedPlannedTrees(id).forEach((tree) =>
        updatePlannedTrees({ ...tree, _destroy: true })
      )
    )
    updateCurrentPlanting({ plot_ids: newPlots.map(({ id }) => id) })
    return true
  }

  useEffect(() => {
    if (!currentPlanting.orchard_id || !currentPlanting.plot_ids.length) return
    const fetchPlotDetails = async () => {
      for (const plot_id of currentPlanting.plot_ids) {
        if (!plotDetails[plot_id]) {
          try {
            // disable no-await-in-loop because we want to fetch plot details one at a time
            // eslint-disable-next-line no-await-in-loop
            const response = await fetch(
              `/orchards/${currentPlanting.orchard_id}/plots/${plot_id}?data_structure=edit_planting`,
              { headers: { Accept: 'application/json' } }
            )
            updatePlotDetails({ [plot_id]: await response.json() }) // eslint-disable-line no-await-in-loop
          } catch (error) {
            console.error('Error fetching plot details:', error)
          }
        }
      }
    }

    fetchPlotDetails()
  }, [currentPlanting.plot_ids?.sort()?.join(',') || ''])

  const plotLayoutOrPlaceholder = (plot_id) =>
    plotDetails[plot_id] ? (
      <PlotLayout
        bulkApplyPlantingItemId={bulkApplyPlantingItemId}
        gridLayout={plotInterfaceMode === 'grid'}
        key={plot_id}
        onlyAvailableSpaces={onlyAvailableSpaces}
        plannedTreesBySpace={plannedTreesBySpace}
        plantingItems={currentPlanting.planting_items}
        plot={plotDetails[plot_id]}
        reviewMode={reviewMode}
        setBulkApplyPlantingItemId={setBulkApplyPlantingItemId}
        updatePlannedTrees={updatePlannedTrees}
      />
    ) : (
      <div key={plot_id}>Loading plot {plot_id}...</div>
    )

  return (
    <div className="EditPlottedPlanting">
      <SavePlantingControls
        currentPlanting={currentPlanting}
        plannedTrees={plannedTrees}
        plantingHasChanged={plantingHasChanged}
        reviewMode={reviewMode}
        form_authenticity_token={form_authenticity_token}
      />
      <PlantingAttributes
        currentPlanting={currentPlanting}
        updateCurrentPlanting={updateCurrentPlanting}
        options={options}
        reviewMode={reviewMode}
      />
      <PlantingItemChooser
        reviewMode={reviewMode}
        plantingItems={currentPlanting.planting_items}
        undestroyedPlannedTrees={Object.values(plannedTreesBySpace)}
        updatePlantingItems={(planting_items, updatePlantingHasChanged) =>
          updateCurrentPlanting({ planting_items }, updatePlantingHasChanged)
        }
        setBulkApplyPlantingItemId={setBulkApplyPlantingItemId}
        chapters={options.chapters}
        bulkApplyPlantingItemId={bulkApplyPlantingItemId}
      />
      <ChapterOrchardPlotsRansack
        chapters={options.chapters}
        plots={plots.filter(Boolean)}
        setPlots={handleSetPlots}
        disabled={reviewMode || !plots.every(Boolean)}
        orchard={{
          id: currentPlanting.orchard_id,
          name: currentPlanting.orchard_name,
          chapter_id: currentPlanting.orchard_chapter_id,
        }}
        setOrchard={({ id, name, chapter_id }) =>
          updateCurrentPlanting({
            orchard_id: id,
            orchard_name: name,
            orchard_chapter_id: chapter_id,
          })
        }
      />
      {!!currentPlanting.plot_ids?.length && (
        <AbovePlotControls
          onlyAvailableSpaces={onlyAvailableSpaces}
          plotInterfaceMode={plotInterfaceMode}
          reviewMode={reviewMode}
          setOnlyAvailableSpaces={setOnlyAvailableSpaces}
          setPlotInterfaceMode={setPlotInterfaceMode}
        />
      )}
      {plotInterfaceMode === 'spreadsheet' ? (
        <SpreadsheetInterface
          plantingId={currentPlanting.id}
          form_authenticity_token={form_authenticity_token}
          plannedTreesBySpace={plannedTreesBySpace}
          plantingItems={currentPlanting.planting_items}
          plots={plots.filter(Boolean)}
          plantingHasChanged={plantingHasChanged}
        />
      ) : (
        <div className="plot-layouts">
          {currentPlanting.plot_ids?.map((id) => plotLayoutOrPlaceholder(id))}
        </div>
      )}

      {bulkApplyPlantingItemId && (
        <BulkApplyFloater
          plantingItem={currentPlanting.planting_items[bulkApplyPlantingItemId]}
          setBulkApplyPlantingItemId={setBulkApplyPlantingItemId}
        />
      )}
    </div>
  )
}

export default EditPlottedPlanting
