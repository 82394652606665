button.AdvancedTreesSearchObservations {
  margin: 1em;
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}

div.AdvancedTreesSearchObservations {
  margin: 1em;
  padding: 1em;
  border: 1px solid lightgray;
}
div.AdvancedTreesSearchObservations .header-row {
  display: flex;
  justify-content: space-between;
}
div.AdvancedTreesSearchObservations .header-row button {
  font-size: larger;
  background: none;
  border: none;
  cursor: pointer;
}
div.AdvancedTreesSearchObservations .conditionals {
  display: grid;
  gap: 1em;
  justify-content: center;
  align-items: center;
  grid-template-columns: max-content 1fr 1fr;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF8xNWQ4ZjY1MS9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL3RyZWVzIiwic291cmNlcyI6WyJBZHZhbmNlZFRyZWVzU2VhcmNoT2JzZXJ2YXRpb25zLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUVBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7O0FBRUE7RUFDRTtFQUNBOztBQUNBO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7O0FBSUo7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiYnV0dG9uLkFkdmFuY2VkVHJlZXNTZWFyY2hPYnNlcnZhdGlvbnMge1xuICBtYXJnaW46IDFlbTtcblxuICBiYWNrZ3JvdW5kOiBub25lO1xuICBib3JkZXI6IG5vbmU7XG4gIGNvbG9yOiBibHVlO1xuICB0ZXh0LWRlY29yYXRpb246IHVuZGVybGluZTtcbiAgY3Vyc29yOiBwb2ludGVyO1xuICBwYWRkaW5nOiAwO1xufVxuXG5kaXYuQWR2YW5jZWRUcmVlc1NlYXJjaE9ic2VydmF0aW9ucyB7XG4gIG1hcmdpbjogMWVtO1xuICBwYWRkaW5nOiAxZW07XG4gIGJvcmRlcjogMXB4IHNvbGlkIGxpZ2h0Z3JheTtcblxuICAuaGVhZGVyLXJvdyB7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IHNwYWNlLWJldHdlZW47XG4gICAgYnV0dG9uIHtcbiAgICAgIGZvbnQtc2l6ZTogbGFyZ2VyO1xuICAgICAgYmFja2dyb3VuZDogbm9uZTtcbiAgICAgIGJvcmRlcjogbm9uZTtcbiAgICAgIGN1cnNvcjogcG9pbnRlcjtcbiAgICB9XG4gIH1cblxuICAuY29uZGl0aW9uYWxzIHtcbiAgICBkaXNwbGF5OiBncmlkO1xuICAgIGdhcDogMWVtO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiBtYXgtY29udGVudCAxZnIgMWZyO1xuICB9XG59XG4iXX0= */