import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import AddressSearchBox from './AddressSearchBox'
import TreeMarker from './TreeMarker'
import './Map.scss'
import CurrentLocationSvg from '../../shared/location-arrow-solid.svg'
import { TACF_LOCATION, DEFAULT_ZOOM } from '../../shared/constants'

const redX = require('../../shared/red-x.png')

const MAP_OPTIONS = {
  mapTypeId: 'hybrid',
  fullscreenControl: false,
  rotateControl: false,
  tilt: 0,
}

const MAX_MAP_SIZE = { height: '450px', width: '100%' }
const MIN_MAP_SIZE = { height: '388px', width: '388px' }

const Map = ({
  capturePos,
  currTreeIndex,
  currTreeLat,
  currTreeLng,
  definedTrees,
  editMode,
  existingLocationEntities,
  handleTreeClick,
  highlightedTreeIndex,
  miniMap,
  plantingLocation,
  prevAddress,
  setLocation,
  showLocationInputBox,
}) => {
  const [center, setCenter] = useState(plantingLocation)
  const [mapApi, setMapApi] = useState(null)
  const [address, setAddress] = useState(prevAddress)
  const [newTreeMarkerPos, setNewTreeMarkerPos] = useState(null)

  useEffect(() => setNewTreeMarkerPos(null), [definedTrees])

  useEffect(() => {
    if (currTreeLat && currTreeLng)
      setNewTreeMarkerPos({ lat: currTreeLat, lng: currTreeLng })
    else setNewTreeMarkerPos(null)
  }, [currTreeLat, currTreeLng])

  function generateAddressFromLatLng(pos) {
    const geocoder = new mapApi.Geocoder()
    geocoder.geocode({ location: pos }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          setAddress(results[0].formatted_address)
          setLocation({ address: results[0].formatted_address, pos })
        } else {
          console.warn('No reverse geocoding results found')
        }
      } else {
        console.error(`Geocoder failed due to: ${status}`)
      }
    })
  }

  function handleDragEvent(event) {
    const pos = { lat: event.center.lat(), lng: event.center.lng() }
    if (setLocation) {
      generateAddressFromLatLng(pos)
      setCenter(pos)
    }
  }

  function handleClick(arg1, arg2) {
    if (arg2 && handleTreeClick) {
      // This was from a click on a TreeMarker
      // handleTreeClick(arg1);
    } else if (capturePos && arg1.latLng) {
      // This was from a map click
      const pos = {
        lat: arg1.latLng.lat(),
        lng: arg1.latLng.lng(),
      }
      setNewTreeMarkerPos(pos)
      capturePos(pos)
    }
  }

  function getUserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          generateAddressFromLatLng(pos)
          setCenter(pos)
        },
        (error) => {
          window.alert('Could not access location.')
          console.error('Geolocation error', error)
        },
        {
          timeout: 10000,
          maximumAge: 15000,
          enableHighAccuracy: false,
        }
      )
    } else {
      window.alert('Access to your location is not allowed.')
    }
  }

  function handleApiLoaded(map, maps) {
    // use map and maps objects
    if (map && maps) {
      setMapApi(maps)

      map.addListener('click', handleClick)
    }
  }

  function handleAddressChange(results) {
    const lat = results[0].geometry.location.lat()
    const lng = results[0].geometry.location.lng()
    const pos = { lat, lng }
    setLocation({ address: results[0].formatted_address, pos })
    setCenter(pos)
  }

  function getDefinedTreeLocations() {
    return definedTrees.map((tree, index) => {
      if (
        newTreeMarkerPos &&
        index === currTreeIndex &&
        currTreeIndex === definedTrees.length
      ) {
        return (
          <TreeMarker
            current={true}
            key={index}
            lat={newTreeMarkerPos.lat}
            lng={newTreeMarkerPos.lng}
            name={index + 1}
          />
        )
      }
      if (editMode && index === currTreeIndex) {
        return (
          <TreeMarker
            current={true}
            key={index}
            lat={currTreeLat}
            lng={currTreeLng}
            name={index + 1}
          />
        )
      }
      const current =
        (index === currTreeIndex && currTreeIndex === definedTrees.length) ||
        highlightedTreeIndex === index
      return (
        <TreeMarker
          onClick={(event) => handleClick(index, event)}
          current={current}
          key={index}
          lat={tree.lat}
          lng={tree.lng}
          name={index + 1}
        />
      )
    })
  }

  const addingNewTree =
    capturePos && newTreeMarkerPos && currTreeIndex === definedTrees.length

  const existingTreeMarkers = (
    existingLocationEntities?.located_trees || []
  ).map((tree) => (
    <TreeMarker
      key={tree.id}
      lat={tree.latitude}
      lng={tree.longitude}
      color="green"
    />
  ))

  return (
    <div id="Map">
      {showLocationInputBox && (
        <div className="new-location">
          <AddressSearchBox
            mapApi={mapApi}
            onPlacesChanged={handleAddressChange}
            address={address}
            setAddress={setAddress}
          />
          <button className="use-current" onClick={getUserLocation}>
            <CurrentLocationSvg />
            <span>Use my current location</span>
          </button>
        </div>
      )}
      <div
        className="map-container"
        style={miniMap ? MIN_MAP_SIZE : MAX_MAP_SIZE}
      >
        <GoogleMapReact
          data-testid="google-map"
          bootstrapURLKeys={{
            key: process.env.GOOGLE_API_KEY,
            libraries: 'places',
          }}
          onDragEnd={handleDragEvent}
          defaultCenter={TACF_LOCATION}
          options={MAP_OPTIONS}
          onTestingClick={handleClick} // Hack to handle the integration test since I don't know how to get a handle to the internal API
          center={center}
          defaultZoom={DEFAULT_ZOOM}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
          {addingNewTree && (
            <TreeMarker
              current={true}
              lat={newTreeMarkerPos.lat}
              lng={newTreeMarkerPos.lng}
              name={definedTrees.length + 1}
            />
          )}
          {definedTrees && getDefinedTreeLocations()}
          {existingTreeMarkers}
        </GoogleMapReact>
        {showLocationInputBox && (
          <img className="red-x" src={redX} alt="X on center of map" />
        )}
      </div>
    </div>
  )
}

export default Map
