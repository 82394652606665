import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import SelectAsyncPaginatedRansack from './SelectAsyncPaginatedRansack'
import { fetchRoles, fetchRegions, fetchChapters } from '../../shared/utils'

const RoleAndEntityRansack = (props) => {
  const [roles, setRoles] = useState([])
  const [role, setRole] = useState(null)
  const [entity, setEntity] = useState(null)
  const [entities, setEntities] = useState(null)
  const [showSimpleSelect, setShowSimpleSelect] = useState(false)
  const [showPaginatedSelect, setShowPaginatedSelect] = useState(false)

  useEffect(() => {
    fetchRoles().then((results) => {
      setRoles(results)
    })
  }, [])

  useEffect(() => {
    setEntity(null)
    if (role) {
      switch (role?.assignedEntity) {
        case 'Region':
          fetchRegions().then((results) => {
            setEntities(results)
          })
          setShowPaginatedSelect(false)
          setShowSimpleSelect(true)
          break
        case 'Chapter':
          fetchChapters().then((results) => {
            setEntities(results)
          })
          setShowPaginatedSelect(false)
          setShowSimpleSelect(true)
          break
        case 'Parcel':
        case 'Tree':
          setShowPaginatedSelect(true)
          setShowSimpleSelect(false)
          break
        default:
          setShowPaginatedSelect(false)
          setShowSimpleSelect(false)
      }
    }
  }, [role])

  const handleSimpleEntity = (incoming) => {
    setEntity(incoming)
  }

  const label =
    role?.assignedEntity === 'Parcel'
      ? 'name_with_chapter'
      : 'name_shortcode_chapter'

  return (
    <div className="RoleAndEntityRansack" style={{ marginBottom: '10px' }}>
      <div className="role-select">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label htmlFor="role_assignment[role_id]">Role</label>
        </div>
        <Select
          inputId="role_assignment[role_id]"
          name="role_assignment[role_id]"
          value={role}
          options={roles}
          onChange={setRole}
        />
        <input
          name="role_assignment[entity_type]"
          type="hidden"
          value={role?.assignedEntity}
        />
      </div>
      {showSimpleSelect && (
        <div className="entity-select">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label htmlFor="role_assignment[entity_id]">
              {role.assignedEntity}
            </label>
          </div>
          <Select
            inputId="role_assignment[entity_id]"
            name="role_assignment[entity_id]"
            value={entity}
            options={entities}
            onChange={handleSimpleEntity}
          />
        </div>
      )}
      {showPaginatedSelect && (
        <div className="entity-select">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <label htmlFor="role_assignment[entity_id]">
              {role.assignedEntity}
            </label>
          </div>
          <SelectAsyncPaginatedRansack
            model_name_plural={`${role.assignedEntity.toLowerCase()}s`}
            input={props.entityInput}
            optionValue="id"
            json_data={true}
            ransack_predicate="name_cont"
            select="id, name"
            label={label}
            value={entity}
            onChange={setEntity}
          />
        </div>
      )}
    </div>
  )
}

export default RoleAndEntityRansack
