import React, { useState, useEffect } from 'react'
import SortableColumnHeader from './SortableColumnHeader'
import { sortBy } from '../../shared/utils'
import PlantingActionsGearMenu from './PlantingActionsGearMenu.jsx'

import './MyPlantingsWidget.scss'

const MyPlantingsWidget = ({ rawPlantings, showCitsciObservations }) => {
  const [plantings, setPlantings] = useState([])
  const [datePlantedSort, setDatePlantedSort] = useState('descending')
  const [plantingNameSort, setPlantingNameSort] = useState(null)
  const [treesSort, setTreesSort] = useState(null)
  const [statusSort, setStatusSort] = useState(null)
  const [orchardSort, setOrchardSort] = useState(null)
  const [parcelSort, setParcelSort] = useState(null)
  const [shortcodeSort, setShortcodeSort] = useState(null)
  const [plantingTypeSort, setPlantingTypeSort] = useState(null)

  useEffect(() => {
    setPlantings(rawPlantings.sort(sortBy('planted_on', true)))
  }, [])

  const nullifySorts = () => {
    setDatePlantedSort(null)
    setPlantingNameSort(null)
    setTreesSort(null)
    setStatusSort(null)
    setOrchardSort(null)
    setParcelSort(null)
    setShortcodeSort(null)
    setPlantingTypeSort(null)
  }

  const setSort = (sortField, sortDirection) => {
    const reverse = sortDirection === 'descending'
    nullifySorts()
    if (sortDirection && sortField === 'Date Planted') {
      setPlantings(plantings.sort(sortBy('planted_on', reverse)))
      setDatePlantedSort(sortDirection)
    } else if (sortDirection && sortField === 'Trees') {
      setPlantings(plantings.sort(sortBy('tree_count', reverse)))
      setTreesSort(sortDirection)
    } else if (sortDirection && sortField === 'Status') {
      setPlantings(plantings.sort(sortBy('stage', reverse)))
      setStatusSort(sortDirection)
    } else if (sortDirection && sortField === 'Orchard') {
      setPlantings(plantings.sort(sortBy('orchard_name', reverse)))
      setOrchardSort(sortDirection)
    } else if (sortDirection && sortField === 'Parcel / Location') {
      setPlantings(plantings.sort(sortBy('parcel_name', reverse)))
      setParcelSort(sortDirection)
    } else if (sortDirection && sortField === 'Planting Type') {
      setPlantings(plantings.sort(sortBy('planting_type', reverse)))
      setPlantingTypeSort(sortDirection)
    } else if (sortDirection && sortField === 'Shortcode') {
      setPlantings(plantings.sort(sortBy('shortcode', reverse)))
      setShortcodeSort(sortDirection)
    } else {
      setPlantings(plantings.sort(sortBy('name', reverse)))
      setPlantingNameSort(sortDirection)
    }
  }

  const plantingRows = () => {
    return plantings.map((planting, index) => {
      const rowClass = index % 2 === 1 ? 'odd' : 'even'
      let nameLink = <p>{planting.name}</p>
      if (planting.stage === 'incomplete_wizard')
        nameLink = (
          <a href={`/plantings/wizard?id=${planting.id}`}>{planting.name}</a>
        )
      else nameLink = <a href={`/plantings/${planting.id}`}>{planting.name}</a>

      const parcel = (
        <a href={`/parcels/${planting.parcel_id || planting.location_id}`}>
          {planting.parcel_name}
        </a>
      )
      const orchard = planting.orchard_id ? (
        <a href={`/orchards/${planting.orchard_id}`}>{planting.orchard_name}</a>
      ) : (
        ''
      )

      return (
        <tr key={planting.id} className={rowClass}>
          <td>{nameLink}</td>
          <td>
            <p>{planting.shortcode}</p>
          </td>
          <td>
            <p>{orchard}</p>
          </td>
          <td>
            <p>{parcel}</p>
          </td>
          <td>
            <p>{planting.planted_on}</p>
          </td>
          <td>
            <p>{planting.planting_type}</p>
          </td>
          <td>
            <p className="numeric">{planting.tree_count}</p>
          </td>
          <td>
            <p>{planting.stage}</p>
          </td>
          { showCitsciObservations && // for now, observations are the only cog menu item, so hide the cog otherwise
            <td>
              <PlantingActionsGearMenu
                planting={{ ...planting }}
                showCitsciObservations={showCitsciObservations}
              />
            </td>
          }
        </tr>
      )
    })
  }

  return (
    <div id="MyPlantingsWidget">
      <div className="data">
        <table>
          <thead>
            <tr>
              <td>
                <SortableColumnHeader
                  name="Planting Name"
                  sort={plantingNameSort}
                  toggleSort={setSort}
                />
              </td>
              <td>
                <SortableColumnHeader
                  name="Shortcode"
                  sort={shortcodeSort}
                  toggleSort={setSort}
                />
              </td>
              <td>
                <SortableColumnHeader
                  name="Orchard"
                  sort={orchardSort}
                  toggleSort={setSort}
                />
              </td>
              <td>
                <SortableColumnHeader
                  name="Parcel / Location"
                  sort={parcelSort}
                  toggleSort={setSort}
                />
              </td>
              <td>
                <SortableColumnHeader
                  name="Date Planted"
                  sort={datePlantedSort}
                  toggleSort={setSort}
                />
              </td>
              <td>
                <SortableColumnHeader
                  name="Planting Type"
                  sort={plantingTypeSort}
                  toggleSort={setSort}
                />
              </td>
              <td>
                <SortableColumnHeader
                  name="Trees"
                  sort={treesSort}
                  toggleSort={setSort}
                />
              </td>
              <td>
                <SortableColumnHeader
                  name="Status"
                  sort={statusSort}
                  toggleSort={setSort}
                />
              </td>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <td />
            </tr>
          </thead>
          <tbody>{plantingRows()}</tbody>
        </table>
      </div>
    </div>
  )
}

export default MyPlantingsWidget
