import React from 'react'
import PropTypes from 'prop-types'
import SelectAsyncPaginatedRansack from '../application/SelectAsyncPaginatedRansack'
import { OrgSelectInput } from '../application/OrgInputs'
// import './ChapterTreeRansack.scss'

const ChapterTreeRansack = ({
  chapters,
  disabled,
  tree,
  onChange,
  labels,
  writeable,
}) => {
  return (
    <div
      className="ChapterTreeRansack"
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '1rem',
      }}
    >
      <label>
        {labels.chapter || 'Tree Chapter'}
        <OrgSelectInput
          options={chapters}
          onChange={(chapterId) => onChange({ chapter_id: chapterId })}
          value={tree?.chapter_id || ''}
          placeholder="Any"
        />
      </label>

      <label>
        {labels.tree || 'Tree'}
        <SelectAsyncPaginatedRansack
          disabled={disabled}
          onChange={onChange}
          model_name_plural="trees"
          optionValue="id"
          json_data={false}
          ransack_predicate="shortcode_cont"
          select={['shortcode', 'id', 'chapter_id']}
          label="shortcode"
          value={tree?.id ? tree : ''}
          writeable={writeable}
          placeholder="Select a tree"
          extraPredicates={
            tree?.chapter_id ? { chapter_id_eq: tree.chapter_id } : undefined
          }
        />
      </label>
    </div>
  )
}

ChapterTreeRansack.defaultProps = {
  disabled: false,
  labels: {},
  tree: undefined,
  writeable: true,
}

ChapterTreeRansack.propTypes = {
  labels: PropTypes.shape({
    chapter: PropTypes.string,
    tree: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  chapters: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  disabled: PropTypes.bool,
  tree: PropTypes.shape({
    id: PropTypes.number,
    shortcode: PropTypes.string,
    chapter_id: PropTypes.number,
  }),
  writeable: PropTypes.bool,
}

export default ChapterTreeRansack
