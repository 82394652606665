import React from 'react'
import { getDateString } from '../../shared/utils'
import ProgressBar from '../application/ProgressBar.jsx'
import { MANY_TREES } from '../../shared/constants'

const STEP_DEFINE_LOCATION_DETAILS = 'STEP_DEFINE_LOCATION_DETAILS'
const STEP_DEFINE_LOCATION_ADDRESS = 'STEP_DEFINE_LOCATION_ADDRESS'
const STEP_DEFINE_TREE = 'STEP_DEFINE_TREE'
const STEP_REVIEW_AND_FINISH = 'STEP_REVIEW_AND_FINISH'

const AddPlantingProgressBar = ({
  locationName,
  plantingDate,
  plantingSize,
  definedTrees,
  step,
  handleDetailsEdit,
  handleTreeEdit,
}) => {
  function treeList(trees) {
    return trees.map((tree, index) => {
      return (
        <li key={tree.name}>
          <button type="button" onClick={() => handleTreeEdit(index)}>
            <p>{`Tree ${index + 1} - ${tree.name}`}</p>
          </button>
        </li>
      )
    })
  }

  const treePlaceholder =
    step === STEP_DEFINE_TREE &&
    (plantingSize === Number.MAX_SAFE_INTEGER ||
      plantingSize > definedTrees.length) ? (
      <li key="placeholder">{`Tree ${definedTrees.length + 1}`}</li>
    ) : null
  const treeStr = plantingSize === 1 ? 'tree' : 'trees'
  const plantingSizeStr =
    plantingSize === Number.MAX_SAFE_INTEGER ? MANY_TREES : `${plantingSize}`

  const steps = [
    {
      header: 'Planting Details',
      headerFunc: () => handleDetailsEdit(),
      content: (
        <>
          <p className="step-content">
            {locationName || 'New location'}
          </p>
          {plantingDate && (
            <p className="step-content">
              {getDateString(plantingDate)}
            </p>
          )}
          {plantingSize && (
            <p className="step-content">
              {`${plantingSizeStr} ${treeStr}`}
            </p>
          )}
        </>
      ),
    },
  ]

  if (step === STEP_DEFINE_TREE || step === STEP_REVIEW_AND_FINISH)
    steps.push({
      header: `TREES (${plantingSizeStr})`,
      content: (
        <ul>
          {definedTrees && treeList(definedTrees)}
          {treePlaceholder}
        </ul>
      ),
    })

  if (step === STEP_REVIEW_AND_FINISH) steps.push({ header: 'REVIEW & FINISH' })

  return <ProgressBar title="Add a Planting" steps={steps} />
}

export {
  AddPlantingProgressBar,
  STEP_DEFINE_LOCATION_ADDRESS,
  STEP_DEFINE_LOCATION_DETAILS,
  STEP_DEFINE_TREE,
  STEP_REVIEW_AND_FINISH,
}
