import React from 'react'
import PropTypes from 'prop-types'
import './ToggleButtons.scss'

const ToggleButtons = ({ onChange, value, options, disabled }) => (
  <div className="ToggleButtons">
    {options.map((option) => (
      <button
        className={`${option.value === value ? 'selected' : ''}`}
        key={`${option.value}`}
        type="button"
        onClick={() => onChange(option.value)}
        disabled={disabled}
      >
        {option.label}
      </button>
    ))}
  </div>
)

ToggleButtons.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    })
  ).isRequired,
}

export default ToggleButtons
