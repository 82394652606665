import React from 'react'
import ToggleButtons from '../../application/ToggleButtons.jsx'
import './AbovePlotControls.scss'

const AbovePlotControls = ({
  onlyAvailableSpaces,
  plotInterfaceMode,
  reviewMode,
  setOnlyAvailableSpaces,
  setPlotInterfaceMode,
}) => (
  <div className="AbovePlotControls">
    <ToggleButtons
      onChange={setPlotInterfaceMode}
      options={[
        { label: 'List', value: 'list' },
        { label: 'Grid', value: 'grid' },
        reviewMode ? null : { label: 'Spreadsheet', value: 'spreadsheet' },
      ].filter(Boolean)}
      value={plotInterfaceMode}
    />
    <ToggleButtons
      onChange={setOnlyAvailableSpaces}
      options={[
        { label: 'All Spaces', value: false },
        { label: 'Only Available Spaces', value: true },
      ]}
      value={onlyAvailableSpaces}
      disabled={plotInterfaceMode !== 'list'}
    />
  </div>
)

export default AbovePlotControls
