import React, { useState } from 'react'

export const colorPalette = [
  '#BF00FF', // Electric Purple
  '#00BFFF', // Neon Blue
  '#FFF700', // Lemon Yellow
  '#FF2400', // Scarlet Red
  '#FF0090', // Deep Magenta
  '#FF4500', // Flame Orange
  '#4169E1', // Royal Blue
  '#FF1493', // Bright Pink
  '#7FFF00', // Chartreuse
  '#40E0D0', // Turquoise
  '#FF69B4', // Hot Pink
  '#DC143C', // Crimson
  '#FFD700', // Bright Yellow
  '#7851A9', // Royal Purple
  '#39FF14', // Neon Green
  '#FF7F00', // Vivid Orange
  '#3F00FF', // Ultramarine
  '#E6E6FA', // Bright Lavender
  '#32CD32', // Vivid Lime Green
  '#87CEEB', // Sky Blue
  '#FF4040', // Bright Coral
]

const ColorPicker = ({ color, setColor }) => {
  const [isChoosingColor, setIsChoosingColor] = useState(false)

  return (
    <div className="ColorPicker">
      <button
        type="button"
        aria-label={`Choose a color (currently ${color})`}
        onClick={() => setIsChoosingColor((prev) => !prev)}
        style={{
          backgroundColor: color,
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          cursor: 'pointer',
          border: 'none',
        }}
      />
      <div
        className="choices"
        style={{
          width: '200px',
          display: isChoosingColor ? 'grid' : 'none',
          gridTemplateColumns: 'repeat(5, 1fr)',
          flexWrap: 'wrap',
          background: 'white',
          margin: '1em auto',
        }}
      >
        {colorPalette.map((colorOption) => (
          <button
            type="button"
            aria-label={`Choose ${colorOption}`}
            key={colorOption}
            onClick={() => {
              setColor(colorOption)
              setIsChoosingColor(false)
            }}
            style={{
              backgroundColor: colorOption,
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              margin: '5px',
              cursor: 'pointer',
              display: 'inline-block',
              border: 'none',
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default ColorPicker
