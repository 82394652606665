import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import _uniqueId from 'lodash/uniqueId'
import MobileBottomMenu from './MobileBottomMenu.jsx'
import { isMobile } from '../../shared/media_queries.js'
import CogIcon from '../../shared/images/cog_icon.svg'
import './CogtextMenu.scss'

const CogtextMenu = ({
  menuItems,
  mobileHeaderContent,
  desktopButton,
  mobileButton,
}) => {
  const cogId = _uniqueId('cog')
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const buttonContent = (isMobile() && mobileButton) || desktopButton || (
    <CogIcon className="cog" alt="actions menu" />
  )

  const menuItemsRendered = (
    <ul className="CogtextMenuItems">
      {menuItems.map(([text, url, htmlOptions]) => (
        <li key={url}>
          <a href={url} {...htmlOptions}>
            {text}
          </a>
        </li>
      ))}
    </ul>
  )

  const mobileMenu = isMobile() && (
    <MobileBottomMenu
      isOpen={menuIsOpen}
      onRequestClose={() => setMenuIsOpen(false)}
      headerContent={mobileHeaderContent}
    >
      {menuItemsRendered}
    </MobileBottomMenu>
  )

  // for the desktop menu (react-tooltip)
  // we need the delay to ensure the tooltip is closed
  // otherwise we have colflict between react-tooltip's
  // self-handling of being open or closed
  const handleClickCog = () =>
    menuIsOpen
      ? setTimeout(() => setMenuIsOpen(false), 50)
      : setMenuIsOpen(true)

  const desktopMenu = !isMobile() && (
    <Tooltip
      className="CogtextMenuDesktopTooltip"
      anchorSelect={`#${cogId}`}
      variant="light"
      clickable
      openOnClick
      isOpen={menuIsOpen}
      setIsOpen={setMenuIsOpen}
    >
      {menuItemsRendered}
    </Tooltip>
  )

  return (
    <div className="CogtextMenu">
      <button
        type="button"
        className={`cogtext-cog ${isMobile() ? 'mobile' : 'desktop'}`}
        id={cogId}
        onClick={handleClickCog}
      >
        {buttonContent}
      </button>

      {mobileMenu}
      {desktopMenu}
    </div>
  )
}

export default CogtextMenu
