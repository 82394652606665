import React from 'react'
import './PicklistObservation.scss'
import { Trait } from './trait'

const PicklistObservation = ({
  label = '',
  trait,
  setTrait,
  options,
  tooltipId,
}) => {
  return (
    <div className="PicklistObservation">
      <label htmlFor="measurement-input">{label}</label>
      <select
        value={trait.value}
        onChange={(e) =>
          setTrait(Trait.merge(trait, { value: e.target.value }))
        }
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <option key="--" value="" label=" " />
        {options.map((optionItem) => (
          <option key={optionItem.id} value={optionItem.short_value}>
            {optionItem.long_value}
          </option>
        ))}
      </select>
      {tooltipId && (
        <span className="tooltip-icon" id={tooltipId}>
          i
        </span>
      )}
    </div>
  )
}

export default PicklistObservation
