import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import Map from './Map'
import ErrorModal from './ErrorModal'
import WarningModal from './WarningModal'
import { MANY_TREES } from '../../shared/constants'
import { treeNameExists, fetchTreeTypes } from '../../shared/utils'

import './DefineTree.scss'

const DefineTree = ({
  addTreeToDefinedTrees,
  chapterId,
  currTreeIndex,
  definedTrees,
  existingLocationEntities,
  lat,
  lng,
  location,
  name,
  numTrees,
  plantedAs,
  setCurrTreeIndex,
  setDefinedTrees,
  setLat,
  setLng,
  setName,
  setPlantedAs,
  setPlantingSize,
  setTreeType,
  treeType,
  treeTypeOptions,
  updateTreeInDefinedTrees,
}) => {
  const saveRef = useRef(null)
  const mainRef = useRef(null)
  const editRefs = useRef([])

  // const [name, setName] = useState("");
  // const [treeType, setTreeType] = useState(null);
  // const [plantedAs, setPlantedAs] = useState(treeTypeOptions.find(option => option.value === "seedling"));
  // const [lat, setLat] = useState(null);
  // const [lng, setLng] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(true)
  const [addAnotherDisabled, setAddAnotherDisabled] = useState(true)
  const [openButtons, setOpenButtons] = useState([])
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deleteTreeIndex, setDeleteTreeIndex] = useState(null)
  const [deleteQuestionMessage, setDeleteQuestionMessage] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [editMode, setEditMode] = useState(false)

  const resetForm = () => {
    setLat(null)
    setLng(null)
    setName('')
    setTreeType(null)
    setPlantedAs(treeTypeOptions.find((option) => option.value === 'seedling'))
  }

  const addTree = (extraTree = false) => {
    resetForm()
    if (extraTree) {
      setAddAnotherDisabled(true)
      setCurrTreeIndex(definedTrees.length)
    }

    if (numTrees !== Number.MAX_SAFE_INTEGER) {
      setPlantingSize(Math.max(numTrees, definedTrees.length + 1))
    }
    mainRef.current.scrollIntoView()
  }

  useEffect(() => {
    const tmpOpenButtons = definedTrees.map(() => false)
    if (currTreeIndex !== null && definedTrees.length > currTreeIndex) {
      tmpOpenButtons[currTreeIndex] = true
      editRefs.current[currTreeIndex].scrollIntoView()
    } else {
      mainRef.current.scrollIntoView()
    }
    setOpenButtons(tmpOpenButtons)
  }, [currTreeIndex])

  useEffect(() => {
    if (saveDisabled && name && treeType && plantedAs && lat && lng) {
      setSaveDisabled(false)
    }
  }, [name, treeType, plantedAs, lat, lng])

  const getHeader = () => {
    let header
    if (numTrees === 1) {
      header = "Great! Let's plant your tree."
    } else if (definedTrees.length === 0) {
      header = "Great! Let's plant your first tree."
    } else if (definedTrees.length === numTrees) {
      header = `${definedTrees.length} of ${
        numTrees === Number.MAX_SAFE_INTEGER ? MANY_TREES : numTrees
      } added!`
    } else {
      header = `${definedTrees.length} of ${
        numTrees === Number.MAX_SAFE_INTEGER ? MANY_TREES : numTrees
      } added. Add another!`
    }
    return header
  }

  const setNewTreePos = (pos) => {
    setLat(pos.lat)
    setLng(pos.lng)
  }

  const treeNameAlreadyInPlanting = () =>
    definedTrees.some((tree, i) => tree.name === name && i !== currTreeIndex)

  const saveTree = () => {
    if (!saveDisabled) {
      if (treeNameAlreadyInPlanting()) {
        setErrorMessage(
          <h2>Tree name cannot be used twice in the same planting</h2>
        )
        setIsErrorModalOpen(true)
        return
      }

      treeNameExists(name, chapterId).then((exists) => {
        if (exists) {
          setErrorMessage(<h2>Tree name already exists in your chapter</h2>)
          setIsErrorModalOpen(true)
        } else {
          if (currTreeIndex < definedTrees.length) {
            updateTreeInDefinedTrees(
              currTreeIndex,
              name,
              treeType,
              plantedAs,
              lat,
              lng
            )
            if (definedTrees.length === numTrees) {
              setAddAnotherDisabled(false)
            }
            if (editMode) {
              resetForm()
              setCurrTreeIndex(definedTrees.length)
              mainRef.current.scrollIntoView()
            }
          } else if (currTreeIndex >= numTrees - 1) {
            addTreeToDefinedTrees(name, treeType, plantedAs, lat, lng)
            setAddAnotherDisabled(false)
            addTree()
          } else {
            addTreeToDefinedTrees(name, treeType, plantedAs, lat, lng)
            addTree()
          }
          setSaveDisabled(true)
          setEditMode(false)
        }
      })
    }
  }

  const toggleOpenButton = (index) => {
    const tmpOpenButtons = [...openButtons]
    tmpOpenButtons[index] = !tmpOpenButtons[index]
    setOpenButtons(tmpOpenButtons)
  }

  const editTree = (index) => {
    setCurrTreeIndex(index)
    setLat(definedTrees[index].lat)
    setLng(definedTrees[index].lng)
    setName(definedTrees[index].name)
    setTreeType(definedTrees[index].treeType)
    setPlantedAs(definedTrees[index].plantedAs)
    setAddAnotherDisabled(true)
    setSaveDisabled(true)
    setEditMode(true)
    saveRef.current.scrollIntoView()
  }

  const handleDelete = (index) => {
    setDeleteQuestionMessage(
      `Continue with deleting "${definedTrees[index].name}"?`
    )
    setDeleteTreeIndex(index)
    setIsDeleteModalOpen(true)
  }

  const previouslyDefinedTrees = () => {
    return definedTrees.map((tree, index) => {
      return (
        <li key={tree.name}>
          <button
            type="button"
            className="added-tree"
            onClick={() => toggleOpenButton(index)}
            ref={(element) => {
              editRefs.current[index] = element
            }}
          >
            <span>{'>'}</span>
            <span className="name">{tree.name}</span>
            <span className="number">{`Tree ${index + 1}`}</span>
          </button>
          {openButtons[index] && (
            <div className="defined-tree-content">
              <div className="defined-tree-info">
                <div className="labels">
                  <p>Type:</p>
                  <p>Planted as:</p>
                  <p>Location:</p>
                </div>
                <div className="values">
                  <p>{tree.treeType.displayName}</p>
                  <p>{tree.plantedAs.label}</p>
                  <p>{`${tree.lat.toFixed(5)}, ${tree.lng.toFixed(5)}`}</p>
                </div>
              </div>
              <div className="defined-tree-buttons">
                <button
                  type="button"
                  className="delete-tree"
                  onClick={() => handleDelete(index)}
                >
                  Delete tree
                </button>
                <button
                  type="button"
                  className="edit-tree"
                  onClick={() => editTree(index)}
                >
                  Edit tree
                </button>
              </div>
            </div>
          )}
        </li>
      )
    })
  }

  const handleDeleteCancel = () => setIsDeleteModalOpen(false)

  const handleTreeClick = (index) => toggleOpenButton(index)

  const deleteTree = () => {
    const tmpTrees = definedTrees
      .slice(0, deleteTreeIndex)
      .concat(definedTrees.slice(deleteTreeIndex + 1))
    setDefinedTrees(tmpTrees)
    setCurrTreeIndex(tmpTrees.length)
    setIsDeleteModalOpen(false)
  }

  const getInstructions = () => {
    if (definedTrees.length === numTrees) {
      return 'Click "Next" or "Save and Exit" to continue'
    }
    if (!saveDisabled) {
      return 'Click Save to save this tree'
    }
    if (!addAnotherDisabled) {
      return 'Enter tree details to save a tree'
    }
    return "Drop a pin on the map to set this tree's location"
  }

  return (
    <div className="DefineTree">
      <div className="columns">
        <div className="column left">
          <div className="headers">
            <h2>{getHeader()}</h2>
            <h3>{getInstructions()}</h3>
          </div>
          <Map
            capturePos={addAnotherDisabled && setNewTreePos}
            currTreeIndex={currTreeIndex}
            currTreeLat={lat}
            currTreeLng={lng}
            definedTrees={definedTrees}
            editMode={editMode}
            existingLocationEntities={existingLocationEntities}
            handleTreeClick={handleTreeClick}
            miniMap={true}
            plantingLocation={location.pos}
            showLocationInputBox={false}
          />
        </div>
        <div className="column right">
          <div className="scrollable">
            {definedTrees.length > 0 ? (
              <ul>{previouslyDefinedTrees()}</ul>
            ) : (
              <div className="empty-tree-list" />
            )}
            <div className="tree-form">
              <div ref={mainRef} className="tree-name">
                <label>
                  Tree name
                  <input
                    readOnly={!addAnotherDisabled}
                    data-testid="tree-name-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                  />
                </label>
              </div>
              <div className="tree-details">
                <div className="tree-type">
                  <label htmlFor="tree-type" className="top">
                    Tree type
                  </label>
                  <AsyncSelect
                    inputId="tree-type"
                    cacheOptions
                    value={treeType}
                    isDisabled={!addAnotherDisabled}
                    classNamePrefix="wizard-selector"
                    loadOptions={fetchTreeTypes}
                    getOptionLabel={(e) => e.displayName}
                    getOptionValue={(e) => e.id}
                    defaultOptions
                    onChange={setTreeType}
                  />
                </div>
                <div className="tree-planted-as">
                  <label htmlFor="planted-as">Planted as...</label>
                  <Select
                    inputId="planted-as"
                    className="selector"
                    classNamePrefix="wizard-selector"
                    value={plantedAs}
                    isDisabled={!addAnotherDisabled}
                    options={treeTypeOptions}
                    onChange={setPlantedAs}
                  />
                </div>
                <div className="tree-space">
                  <p>Tree space</p>
                  <div className="void">
                    {lat && lng ? (
                      <span>{`${lat.toFixed(5)}, ${lng.toFixed(5)}`}</span>
                    ) : (
                      <p>Drop a pin on the map</p>
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  ref={saveRef}
                  disabled={saveDisabled}
                  className="save"
                  onClick={saveTree}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
          <button
            type="button"
            disabled={addAnotherDisabled}
            className="add-another"
            onClick={() => addTree(true)}
          >
            Add Another Tree
          </button>
        </div>
      </div>
      <ErrorModal
        message={errorMessage}
        isOpen={isErrorModalOpen}
        setIsOpen={setIsErrorModalOpen}
      />
      <WarningModal
        handleCancel={handleDeleteCancel}
        handleContinue={deleteTree}
        isOpen={isDeleteModalOpen}
        headerMessage="Delete Tree?"
        questionMessage={deleteQuestionMessage}
        continueMessage="Delete"
      />
    </div>
  )
}

export default DefineTree
