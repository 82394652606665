import React from 'react'

import './SortableColumnHeader.scss';

const SortableColumnHeader = ({name, sort, toggleSort}) => {

  const upArrowVisibility = sort === 'ascending' ? 'invisible' : 'visible'
  const downArrowVisibility = sort === 'descending' ? 'invisible' : 'visible'

  function handleSort() {
    if (sort === 'ascending') {
      toggleSort(name, 'descending')
    } else if (sort === 'descending') {
      toggleSort(name, null)
    } else {
      toggleSort(name, 'ascending')
    }
  }

  return (
    <div id="SortableColumnHeader">
      <button type="button" onClick={handleSort}>
        <span>{name}</span>
        <div className="arrows">
          <div className={`up-arrow ${upArrowVisibility}`}  />
          <div className={`down-arrow ${downArrowVisibility}`}  />
        </div>
      </button>
    </div>
  );
}

export default SortableColumnHeader
