const TACF_LOCATION = {
  lat: 35.6416355,
  lng: -82.5791359,
}

const DEFAULT_ZOOM = 16
const DEFAULT_ZOOM_OBSERVATION_MAP = 18

const TOO_MANY_TREES = 10
const MANY_TREES = `${TOO_MANY_TREES}+`

const CLOSE_X_ICON = '\u2715' // Unicode U+2715 aka Multiplication X (as HTML entity: &#x2715; )

export { TACF_LOCATION, DEFAULT_ZOOM, DEFAULT_ZOOM_OBSERVATION_MAP, TOO_MANY_TREES, MANY_TREES, CLOSE_X_ICON }
