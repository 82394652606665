import React from 'react'
import './ObservationDate.scss'

export const ObservationDate = ({ defaultValue, onChange }) => (
  // TODO: -MB consider useEffect to set the default, DON'T use defaultValue
  <div className="ObservationDate">
    <input
      type="date"
      defaultValue={defaultValue}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
)
