import React from 'react'
import Modal from 'react-modal'
import './WarningModal.scss'
import { CLOSE_X_ICON } from '../../shared/constants'

const WarningModal = ({
  isOpen,
  headerMessage,
  infoMessage,
  questionMessage,
  handleContinue,
  handleCancel,
  handleOption,
  cancelMessage,
  continueMessage,
  optionMessage,
}) => {
  return (
    <div id="WarningModal">
      <Modal
        style={{
          content: {
            height: 'max-content',
            alignItems: 'center',
            borderRadius: '8px',
            border: 'solid 1px #cdcdcd',
          },
          overlay: {
            backgroundColor: 'rgba(23, 23, 23, 0.4)',
          },
        }}
        isOpen={isOpen}
        appElement={document.getElementById('content')}
        contentLabel="Warning"
      >
        <div id="WarningModalContent">
          <button type="button" onClick={handleCancel} className="cancel-x">
            {CLOSE_X_ICON}
          </button>
          <h2>{headerMessage}</h2>
          {infoMessage && <p className="info">{infoMessage}</p>}
          <p className="question">{questionMessage}</p>
          <div className="buttons">
            <button type="button" onClick={handleCancel} className="cancel">
              {cancelMessage}
            </button>
            {optionMessage && (
              <button type="button" onClick={handleOption} className="option">
                {optionMessage}
              </button>
            )}
            <button type="button" onClick={handleContinue} className="continue">
              {continueMessage}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default WarningModal
