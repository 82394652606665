import React, { useState, useEffect } from "react"
import Map from './Map'

import './DefinePlantingLocation.scss';

function DefinePlantingLocation( { setLocation, location }) {

  return (
    <div id="DefinePlantingLocation">
      <Map 
        showLocationInputBox={true}
        setLocation={setLocation}
        prevAddress={location?.address}
        plantingLocation={location?.pos}
      />
    </div>
  );
}

export default DefinePlantingLocation
