import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { fetchParcels } from '../../shared/utils'

import './NewPlantingWidget.scss'

function NewPlantingWidget({ locations_str }) {
  const [options, setOptions] = useState([{ value: '', label: 'Loading...' }])
  const [location, setLocation] = useState(null)

  useEffect(() => {
    ;(async () => {
      const parcels = await fetchParcels()
      parcels.unshift({ value: '0', label: 'New Location' })
      setOptions(parcels)
    })()
  }, [])

  const wizardUrl =
    location == null || location.value === '0'
      ? '/plantings/wizard'
      : `/plantings/wizard?loc_id=${location.value}&loc_name=${location.label}&loc_lat=${location.lat}&loc_lng=${location.lng}&loc_public=${location.public}`

  return (
    <div id="NewPlantingWidget">
      <div className="header-container">
        <h1>Add CitSci Planting</h1>
      </div>
      <div className="location-container">
        <p>Location</p>
        <div className="select-container">
          <Select
            className="selector"
            onChange={setLocation}
            options={options}
          />
        </div>
      </div>
      {location === null ? (
        <div className="get-started">Get Started</div>
      ) : (
        <a className="get-started" href={wizardUrl}>
          Add Cit Sci Planting
        </a>
      )}
    </div>
  )
}

export default NewPlantingWidget
