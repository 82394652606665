import React from 'react'
import PropTypes from 'prop-types'
import SelectAsyncPaginatedRansack from './SelectAsyncPaginatedRansack'
import { OrgSelectInput } from './OrgInputs'
import './ChapterOrchardPlotsRansack.scss'

const ChapterOrchardPlotsRansack = ({
  chapters,
  disabled,
  orchard,
  setOrchard,
  plots,
  setPlots,
}) => {
  const handleChangeChapter = (newChapterId) => {
    if (newChapterId === orchard.chapter_id) return
    if (!setPlots([])) return
    setOrchard({ id: null, name: null, chapter_id: newChapterId })
  }

  return (
    <div className="ChapterOrchardPlotsRansack">
      <h3 className="title">Choose Plots</h3>
      <div className="chapter-orchard-container">
        <label htmlFor="ChapterOrchardPlots-chap" style={{ minWidth: '400px' }}>
          Chapter
          <OrgSelectInput
            inputId="ChapterOrchardPlots-chap"
            options={chapters}
            onChange={(newChapterId) => handleChangeChapter(newChapterId)}
            value={orchard?.chapter_id || ''}
            placeholder="Any"
          />
        </label>

        <label htmlFor="orchard-select-ransack" style={{ minWidth: '400px' }}>
          Orchard
          <SelectAsyncPaginatedRansack
            disabled={disabled}
            onChange={(newOrchard) => {
              setOrchard(newOrchard)
              setPlots([])
            }}
            inputId="orchard-select-ransack"
            model_name_plural="orchards"
            optionValue="id"
            json_data={false}
            ransack_predicate="name_cont"
            select={['name', 'id', 'chapter_id']}
            label="name"
            value={orchard}
            writeable
            placeholder="Any"
            extraPredicates={
              orchard.chapter_id
                ? { chapter_id_eq: orchard.chapter_id }
                : undefined
            }
          />
        </label>
      </div>
      {orchard?.id && (
        <div className="plots-select-container" style={{ minWidth: '200px' }}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="plot-select-ransack">Plots</label>
          <SelectAsyncPaginatedRansack
            disabled={disabled}
            key={orchard.id}
            onChange={setPlots}
            model_name_plural={`orchards/${orchard.id}/plots`}
            optionValue="id"
            json_data={false}
            ransack_predicate="name_cont"
            select={['id', 'name', 'orchard_id']}
            isMulti
            inputId="plot-select-ransack"
            label="name"
            value={plots}
            extraPredicates={{ orchard_id_eq: orchard.id }}
          />
        </div>
      )}
    </div>
  )
}

ChapterOrchardPlotsRansack.defaultProps = {
  disabled: false,
}

ChapterOrchardPlotsRansack.propTypes = {
  disabled: PropTypes.bool,
  orchard: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  setOrchard: PropTypes.func.isRequired,
  plots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  setPlots: PropTypes.func.isRequired,
}

export default ChapterOrchardPlotsRansack
