.CrossParentChooser {
  border: 1px solid darkslategrey;
}
.CrossParentChooser .content {
  padding: 1em;
}
.CrossParentChooser .content .tree-chooser-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.CrossParentChooser .content .tree-chooser-container label {
  display: flex;
  flex-direction: column;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF8xNWQ4ZjY1MS9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL2Nyb3NzZXMiLCJzb3VyY2VzIjpbIkNyb3NzUGFyZW50Q2hvb3Nlci5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7O0FBRUE7RUFDRTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFDQTtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuQ3Jvc3NQYXJlbnRDaG9vc2VyIHtcbiAgYm9yZGVyOiAxcHggc29saWQgZGFya3NsYXRlZ3JleTtcblxuICAuY29udGVudCB7XG4gICAgcGFkZGluZzogMWVtO1xuXG4gICAgLnRyZWUtY2hvb3Nlci1jb250YWluZXIge1xuICAgICAgZGlzcGxheTogZ3JpZDtcbiAgICAgIGdyaWQtdGVtcGxhdGUtY29sdW1uczogcmVwZWF0KDIsIDFmcik7XG4gICAgICBnYXA6IDFyZW07XG4gICAgICBsYWJlbCB7XG4gICAgICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgICAgIGZsZXgtZGlyZWN0aW9uOiBjb2x1bW47XG4gICAgICB9XG4gICAgfVxuICB9XG59XG4iXX0= */