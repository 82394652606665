/* eslint-disable react/require-default-props */

import React from 'react'
import PropTypes from 'prop-types'
import './PlantingItemBrick.scss'
import ColorPicker from './ColorPicker'

const RemainingNuts = ({ remaining_nuts, numberUsed }) => (
  <div className="RemainingNuts">
    <p>Cross Remaining Nuts: {remaining_nuts}</p>
    <p>Nuts Used: {numberUsed}</p>
    {typeof remaining_nuts === 'number' && (
      <p
        style={{
          color: numberUsed > remaining_nuts ? 'red' : '',
        }}
      >
        Remaining Nuts After Planting: {remaining_nuts - numberUsed}
      </p>
    )}
  </div>
)

const PlantingItemBrickDisplaySection = ({
  code,
  name,
  remaining_nuts,
  shortcode,
  transgenic,
  type,
  year,
  numberUsed,
}) => (
  <div className="PlantingItemBrickDisplaySection">
    <p className="mapcode">Mapcode: {code}</p>
    <p className="year-type">{[year, type].filter(Boolean).join(' ')}</p>
    <p className="name">{name}</p>
    {shortcode && <p className="shortcode">shortcode: {shortcode}</p>}
    {transgenic && <p className="transgenic">Transgenic</p>}
    {typeof remaining_nuts === 'number' && (
      <RemainingNuts remaining_nuts={remaining_nuts} numberUsed={numberUsed} />
    )}
  </div>
)

PlantingItemBrickDisplaySection.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  remaining_nuts: PropTypes.number,
  shortcode: PropTypes.string,
  transgenic: PropTypes.bool,
  type: PropTypes.string,
  year: PropTypes.number,
  numberUsed: PropTypes.number.isRequired,
}

const PlantingItemBrick = ({
  color,
  setColor,
  clearBulkApply,
  isBulkApplyingThis,
  isBulkApplyingSome,
  numberUsed,
  onDelete,
  onEdit,
  plantingItem,
  reviewMode,
  setBulkApplyPlantingItem,
}) => {
  return (
    <div
      className={`PlantingItemBrick${
        isBulkApplyingThis ? ' bulk-applying' : ''
      }`}
      style={isBulkApplyingThis ? { backgroundColor: color } : undefined}
    >
      <PlantingItemBrickDisplaySection
        code={plantingItem.code}
        numberUsed={numberUsed}
        {...plantingItem.genetic_origin}
      />
      <ColorPicker color={color} setColor={setColor} />
      {!reviewMode && (
        <>
          <div className="buttons">
            <button
              type="button"
              onClick={onDelete}
              className="delete"
              disabled={isBulkApplyingSome}
            >
              delete
            </button>
            <button
              type="button"
              className="edit"
              onClick={onEdit}
              disabled={isBulkApplyingSome}
            >
              edit
            </button>
          </div>
          <button
            type="button"
            onClick={
              isBulkApplyingThis ? clearBulkApply : setBulkApplyPlantingItem
            }
          >
            {isBulkApplyingThis
              ? 'Done Bulk Apply'
              : 'Bulk Apply This Planting Item'}
          </button>
        </>
      )}
    </div>
  )
}

PlantingItemBrick.propTypes = {
  plantingItem: PropTypes.shape({
    code: PropTypes.string.isRequired,
    color: PropTypes.string,
    genetic_origin: PropTypes.shape({
      name: PropTypes.string.isRequired,
      remaining_nuts: PropTypes.number,
      shortcode: PropTypes.string,
      transgenic: PropTypes.bool,
      type: PropTypes.string,
      year: PropTypes.number,
    }).isRequired,
  }).isRequired,
  numberUsed: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  reviewMode: PropTypes.bool.isRequired,
  setBulkApplyPlantingItem: PropTypes.func.isRequired,
  clearBulkApply: PropTypes.func.isRequired,
  isBulkApplyingSome: PropTypes.bool.isRequired,
  isBulkApplyingThis: PropTypes.bool.isRequired,
}

export default PlantingItemBrick
