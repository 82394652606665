.ChapterOrchardPlotsRansack {
  margin-top: 1em;
  padding: 1em;
  border: 2px solid darkgray;
}
.ChapterOrchardPlotsRansack .title {
  margin: 0 auto 0.5em;
  width: fit-content;
}
.ChapterOrchardPlotsRansack .chapter-orchard-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
  align-items: center;
  justify-content: center;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF8xNWQ4ZjY1MS9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL2FwcGxpY2F0aW9uIiwic291cmNlcyI6WyJDaGFwdGVyT3JjaGFyZFBsb3RzUmFuc2Fjay5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuQ2hhcHRlck9yY2hhcmRQbG90c1JhbnNhY2sge1xuICBtYXJnaW4tdG9wOiAxZW07XG4gIHBhZGRpbmc6IDFlbTtcbiAgYm9yZGVyOiAycHggc29saWQgZGFya2dyYXk7XG5cbiAgLnRpdGxlIHtcbiAgICBtYXJnaW46IDAgYXV0byAwLjVlbTtcbiAgICB3aWR0aDogZml0LWNvbnRlbnQ7XG4gIH1cbiAgLmNoYXB0ZXItb3JjaGFyZC1jb250YWluZXIge1xuICAgIGRpc3BsYXk6IGdyaWQ7XG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiByZXBlYXQoMiwgMWZyKTtcbiAgICBnYXA6IDFlbTtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICB9XG59XG4iXX0= */