import React, { useEffect, useState } from 'react'
import _uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import PlantingItemBrick from './PlantingItemBrick.jsx'
import PlantingItemModal from './PlantingItemModal.jsx'
import { colorPalette } from './ColorPicker.jsx'
import './PlantingItemChooser.scss'

const PlantingItemChooser = ({
  bulkApplyPlantingItemId,
  chapters,
  plantingItems,
  reviewMode,
  setBulkApplyPlantingItemId,
  undestroyedPlannedTrees,
  updatePlantingItems,
}) => {
  const [modalId, setModalId] = useState(null)
  const numberUsed = (id) =>
    // intentional == because planting_item_id is sometimes a string, sometimes a number
    // eslint-disable-next-line eqeqeq
    undestroyedPlannedTrees.filter((pt) => pt.planting_item_id == id).length

  // give each one a color
  useEffect(() => {
    const usedColors = Object.values(plantingItems).map(({ color }) => color)
    let availableColors = colorPalette.filter((cr) => !usedColors.includes(cr))
    if (availableColors.length === 0) availableColors = colorPalette

    const needsColor = Object.entries(plantingItems).filter(
      ([, item]) => !item.color
    )
    needsColor.forEach(([id], i) => {
      const colorIndex = i % availableColors.length
      updatePlantingItems(
        { [id]: { color: availableColors[colorIndex] } },
        false
      )
    })
  }, [
    Object.values(plantingItems)
      .map(({ id }) => id)
      .join(','),
  ])

  return (
    <div id="PlantingItemChooser">
      <PlantingItemModal
        key={modalId}
        chapters={chapters}
        isOpen={!!modalId}
        plantingItem={plantingItems[modalId] || {}}
        otherPlantingItems={Object.entries(plantingItems)
          .filter(([key]) => key !== modalId)
          .map(([, value]) => value)}
        updatePlantingItem={(obj) => {
          setModalId(null)
          updatePlantingItems({ [modalId]: obj })
        }}
        onCancel={() => setModalId(null)}
      />
      <h3 className="title">Planting Items</h3>
      <div className="planting-items">
        {Object.entries(plantingItems)
          .filter(([, plantingItem]) => !plantingItem._destroy)
          .map(([id, plantingItem]) => (
            <PlantingItemBrick
              reviewMode={reviewMode}
              isBulkApplyingThis={bulkApplyPlantingItemId === id}
              isBulkApplyingSome={!!bulkApplyPlantingItemId}
              key={id}
              numberUsed={numberUsed(id)}
              plantingItem={plantingItem}
              setBulkApplyPlantingItem={() => setBulkApplyPlantingItemId(id)}
              clearBulkApply={() => setBulkApplyPlantingItemId(null)}
              onEdit={() => setModalId(id)}
              onDelete={() => updatePlantingItems({ [id]: { _destroy: true } })}
              color={plantingItem.color}
              setColor={(color) => updatePlantingItems({ [id]: { color } })}
            />
          ))}
      </div>

      {!reviewMode && (
        <button
          type="button"
          onClick={() => setModalId(_uniqueId('new-planting-item'))}
          disabled={!!bulkApplyPlantingItemId}
        >
          Add Planting Item
        </button>
      )}
    </div>
  )
}

const plantingItemShape = {
  chapters: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })
  ),
  code: PropTypes.string,
  cross_id: PropTypes.number,
  genet_id: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quantity: PropTypes.number,
  transgenic_array: PropTypes.arrayOf(PropTypes.bool),
  type: PropTypes.oneOf(['PlantingCross', 'PlantingGenet']),
}
PlantingItemChooser.defaultProps = {
  plantingItems: undefined,
  bulkApplyPlantingItemId: null,
}

PlantingItemChooser.propTypes = {
  reviewMode: PropTypes.bool.isRequired,
  plantingItems: PropTypes.objectOf(PropTypes.shape(plantingItemShape)),
  chapters: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })
  ).isRequired,
  bulkApplyPlantingItemId: PropTypes.string,
  updatePlantingItems: PropTypes.func.isRequired,
  setBulkApplyPlantingItemId: PropTypes.func.isRequired,
  undestroyedPlannedTrees: PropTypes.arrayOf(
    PropTypes.shape({
      planting_item_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })
  ).isRequired,
}

export default PlantingItemChooser
