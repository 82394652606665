import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _keyBy from 'lodash/keyBy'
import UnavailableSpace from './UnavailableSpace'
import {
  AdditionalPlannedTreeInputs,
  additionalPlannedTreeInputAttributes,
} from './AdditionalPlannedTreeInputs'
import './PlotLayout.scss'
import PlannedTreeSelect from './PlannedTreeSelect'
import { getContrastingColor } from '../../../shared/utils'

const spaceInputId = (space) => `space_${space.id}`

const gridLayoutTopLabels = (plot) =>
  plot.horizontals.map((horizontal) => (
    <div key={horizontal} className="grid top-label">
      {horizontal}
    </div>
  ))

const PlotLayout = ({
  bulkApplyPlantingItemId,
  gridLayout,
  onlyAvailableSpaces,
  plannedTreesBySpace,
  plantingItems,
  plot,
  reviewMode,
  updatePlannedTrees,
}) => {
  const spaces = useMemo(() => _keyBy(plot.spaces, 'id'), [plot.id])

  const spacesInRolColOrder = useMemo(
    () =>
      [...plot.spaces].sort((a, b) =>
        a.row === b.row ? a.col - b.col : a.row - b.row
      ),
    [plot.id]
  )

  let maybeFilteredSpacesInOrder = gridLayout
    ? plot.space_ids_in_grid_order.map((id) => spaces[id])
    : [...spacesInRolColOrder]
  if (!gridLayout && onlyAvailableSpaces)
    maybeFilteredSpacesInOrder = maybeFilteredSpacesInOrder.filter(
      (space) => space.available
    )

  const handleSelectPlannedTree = (spaceId) => (value) =>
    updatePlannedTrees({
      space_id: spaceId,
      plot_id: plot.id,
      planting_item_id: value?.value,
      _destroy: !value?.value,
    })

  const numColumns = gridLayout
    ? plot.horizontals.length + 1
    : additionalPlannedTreeInputAttributes.length + 1
  const numRows = gridLayout
    ? plot.verticals.length + 1
    : maybeFilteredSpacesInOrder.length + 1

  const gridLayoutLeftSideLabels = (i) =>
    i % plot.horizontals.length === 0 && (
      <div className="grid side-label">
        {plot.verticals[i / plot.horizontals.length]}
      </div>
    )

  const listLayoutTopLabels = (
    <>
      <div className="list top-label">Row</div>
      <div className="list top-label">Position</div>
      <div className="list top-label">Space Label</div>
      <div className="list top-label">Planting Item</div>
      {additionalPlannedTreeInputAttributes.map(({ label }) => (
        <div className="list top-label" key={label}>
          {label}
        </div>
      ))}
    </>
  )
  const listLayoutLabelGridCells = (space) => (
    <>
      <div>{space.row_designation}</div>
      <div>{space.col_designation}</div>
      <label
        className="space"
        htmlFor={spaceInputId(space)}
        data-testid={space.id}
      >
        {space.label}
      </label>
    </>
  )

  const plantingItemOptions = Object.entries(plantingItems).map(
    ([id, { code, color }]) => ({ value: id, label: code, color })
  )

  const plantingItemInSpaceOption = (space) => {
    const existingPlannedTree = plannedTreesBySpace[space.id]
    if (!existingPlannedTree || existingPlannedTree._destroy) return null
    const value = plantingItemOptions.find(
      // Intentional double equals to allow for type coercion (compare string to number)
      // eslint-disable-next-line eqeqeq
      (o) => o.value == existingPlannedTree?.planting_item_id
    )
    return value
  }

  const bulkApplyButton = (space) => {
    const plantingItemInSpace = plantingItemInSpaceOption(space)
    const thisPlantingItemIsAlreadyInThisSpace =
      // Intentional double equals to allow for type coercion (compare string to number)
      // eslint-disable-next-line eqeqeq
      plantingItemInSpace?.value == bulkApplyPlantingItemId
    const valueToApply = thisPlantingItemIsAlreadyInThisSpace
      ? { _destroy: true }
      : { value: bulkApplyPlantingItemId }
    return (
      <button
        type="button"
        className="bulk-apply-button"
        style={{ backgroundColor: plantingItemInSpace?.color }}
        onClick={() => handleSelectPlannedTree(space.id)(valueToApply)}
      >
        {plantingItemInSpace?.label}
      </button>
    )
  }
  const reviewModeSpace = (space) => {
    const plantingItemInSpace = plantingItemInSpaceOption(space)
    if (plantingItemInSpace)
      return (
        <div
          className="review-mode-planned-tree-cell"
          style={{
            backgroundColor: plantingItemInSpace?.color || 'white',
            color: getContrastingColor(plantingItemInSpace?.color),
          }}
        >
          {plantingItemInSpace.label}
        </div>
      )
    return <UnavailableSpace space={space} />
  }

  const plannedTreeChooserOrUnavailableStatusIndicator = (space) => {
    if (space.available) {
      if (bulkApplyPlantingItemId) return bulkApplyButton(space)
      if (reviewMode) return reviewModeSpace(space)
      return (
        <PlannedTreeSelect
          gridLayout={gridLayout}
          inputId={spaceInputId(space)}
          onChange={(value) => handleSelectPlannedTree(space.id)(value)}
          plantingItemOptions={plantingItemOptions}
          selectedOption={plantingItemInSpaceOption(space)}
          space={space}
        />
      )
    }

    return <UnavailableSpace space={space} key={space.id} />
  }

  return (
    <div className="PlotLayout" key={plot.id}>
      <div className="plot-name-sticky">{plot.name}</div>
      <div className="plot-grid-container">
        <div
          className={`plot-grid ${gridLayout ? 'grid' : 'list'}`}
          style={{
            display: 'grid',
            gap: '3px',
            gridTemplateColumns: gridLayout
              ? `repeat(${numColumns}, auto)`
              : `repeat(3, max-content) repeat(${numColumns}, auto)`,
            gridTemplateRows: `auto repeat(${numRows - 1}, 1fr)`, // make all the rows the same height except the first (header) row
            justifyItems: 'center',
            alignItems: 'center',
          }}
        >
          {gridLayout && <div className="upper-left-corner" />}
          {gridLayout ? gridLayoutTopLabels(plot) : listLayoutTopLabels}
          {maybeFilteredSpacesInOrder.map((space, i) => (
            <React.Fragment key={space.id}>
              {gridLayout
                ? gridLayoutLeftSideLabels(i)
                : listLayoutLabelGridCells(space)}
              {plannedTreeChooserOrUnavailableStatusIndicator(space)}
              {!gridLayout && (
                <AdditionalPlannedTreeInputs
                  space={space}
                  plannedTreesBySpace={plannedTreesBySpace}
                  plantingItems={plantingItems}
                  reviewMode={reviewMode}
                  updatePlannedTree={(obj) =>
                    updatePlannedTrees({
                      ...obj,
                      space_id: space.id,
                      planting_item_id:
                        plannedTreesBySpace[space.id]?.planting_item_id,
                    })
                  }
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

PlotLayout.propTypes = {
  reviewMode: PropTypes.bool.isRequired,
  bulkApplyPlantingItemId: PropTypes.string,
  gridLayout: PropTypes.bool.isRequired,
  onlyAvailableSpaces: PropTypes.bool.isRequired,
  plannedTreesBySpace: PropTypes.objectOf(
    PropTypes.shape({
      _destroy: PropTypes.bool,

      planting_item_id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })
  ).isRequired,
  plantingItems: PropTypes.objectOf(
    PropTypes.shape({
      code: PropTypes.string,
    })
  ).isRequired,
  plot: PropTypes.shape({
    id: PropTypes.number.isRequired,
    spaces: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        available: PropTypes.bool,
        row: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        row_designation: PropTypes.string.isRequired,
        col: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        col_designation: PropTypes.string.isRequired,
        label: PropTypes.string,
      })
    ).isRequired,
    space_ids_in_grid_order: PropTypes.arrayOf(PropTypes.number).isRequired,
    horizontals: PropTypes.arrayOf(PropTypes.string).isRequired,
    verticals: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
  updatePlannedTrees: PropTypes.func.isRequired,
}

PlotLayout.defaultProps = {
  bulkApplyPlantingItemId: null,
}

export default PlotLayout
