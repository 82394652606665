import React, { useState } from 'react'
import Creatable from 'react-select/creatable'
import { OrgSelectInput } from '../application/OrgInputs'
import './AdvancedTreesSearchObservations.scss'

const buttonText = 'Filter by Observations'
const recordedOnKeys = ['recorded_on_gteq', 'recorded_on_lteq']

const predicate_keys = {
  boolean: [
    ['is true', 'true'],
    ['is false', 'false'],
  ],
  text: [
    ['equals', 'eq'],
    ['equals any', 'eq_any'],
    ['equals all', 'eq_all'],
    ['not equal to', 'not_eq'],
    ['not equal to any', 'not_eq_any'],
    ['not equal to all', 'not_eq_all'],
    ['contains', 'cont'],
    ['contains any', 'cont_any'],
    ['contains all', 'cont_all'],
    ["doesn't contain", 'not_cont'],
    ["doesn't contain any", 'not_cont_any'],
    ["doesn't contain all", 'not_cont_all'],
  ],
  integer: [
    ['equals', 'eq'],
    ['equals_any', 'eq_any'],
    ['less than', 'lt'],
    ['less than or equal to', 'lteq'],
    ['greater than', 'gt'],
    ['greater than or equal to', 'gteq'],
    ['not equal to', 'not_eq'],
  ],
  picklist: [
    ['equals', 'eq'],
    ['equals any', 'eq_any'],
    ['not equal to', 'not_eq'],
    ['not equal to any', 'not_eq_any'],
    ['not equal to all', 'not_eq_all'],
  ],
}
predicate_keys.float = predicate_keys.integer

const ValueInput = ({
  predicate,
  picklist,
  trait_type,
  isMulti,
  value,
  setValue,
}) => {
  if (!predicate) return <div /> // grid placeholder

  switch (trait_type) {
    case 'boolean':
      return (
        <input
          type="hidden"
          name="observation_filters[value_eq]"
          value={predicate}
        />
      )

    case 'picklist':
      return (
        <OrgSelectInput
          options={picklist}
          isMulti={isMulti}
          name={`observation_filters[value_${predicate}]${isMulti ? '[]' : ''}`}
          value={value || ''}
          onChange={(selected) => setValue(selected)}
        />
      )

    case 'float':
      return (
        <input
          type="text"
          name={`observation_filters[value_${predicate}]`}
          value={value}
          onChange={(e) =>
            setValue(
              e.target.value.endsWith('.')
                ? e.target.value
                : parseFloat(e.target.value)
            )
          }
        />
      )
    case 'integer':
      return (
        <input
          type="number"
          name={`observation_filters[value_${predicate}]`}
          value={value}
          onChange={(e) => setValue(parseInt(e.target.value))}
        />
      )
    default:
      if (isMulti)
        return (
          <Creatable
            options={[]}
            placeholder="Type to add a value"
            noOptionsMessage={() => ''}
            isMulti={isMulti}
            name={`observation_filters[value_${predicate}][]`}
            value={(value || []).map((v) => ({ value: v, label: v }))}
            onChange={(selected) => setValue(selected.map((s) => s.value))}
          />
        )
      return (
        <input
          type="text"
          name={`observation_filters[value_${predicate}]`}
          value={value || ''}
          onChange={(e) => setValue(e.target.value)}
        />
      )
  }
}

const parseFilters = (filters) => {
  if (!filters) return {}
  const parsed = {}
  parsed.trait_id = filters.trait_id_eq
  parsed.units = filters.units_eq
  recordedOnKeys.forEach((key) => {
    parsed[key] = filters[key] || ''
  })
  const valueKey = Object.keys(filters).find((key) => key.startsWith('value_'))
  if (valueKey) {
    parsed.value_predicate = valueKey.replace('value_', '')
    parsed.value = filters[valueKey]
  }
  return parsed
}
const calculateMulti = (predicate) => !!predicate?.match(/(_any|_all)$/)

const AdvancedTreesSearchObservations = ({ filters, options }) => {
  const [currentFilters, setFilters] = useState(parseFilters(filters))
  const [toggledOn, setToggledOn] = useState(
    Object.values(currentFilters).some(Boolean)
  )

  if (!toggledOn)
    return (
      <button
        onClick={() => setToggledOn(true)}
        type="button"
        className="AdvancedTreesSearchObservations"
      >
        {buttonText}
      </button>
    )

  const trait = options.traits.find(({ id }) => id == currentFilters.trait_id) // eslint-disable-line eqeqeq
  const units = trait ? trait.units || [] : options.all_units
  const predicates = predicate_keys[trait?.trait_type] || predicate_keys.text
  const picklist = options.picklists.find(
    (pl) => pl.trait_id == currentFilters.trait_id // eslint-disable-line eqeqeq
  )?.options_for_collection

  return (
    <div className="AdvancedTreesSearchObservations">
      <div className="header-row">
        <h4>{buttonText}</h4>
        <button onClick={() => setToggledOn(false)} type="button">
          &#x2715;
        </button>
      </div>
      <h5>
        Include trees with at least one observation matching ALL of the
        following
      </h5>
      <div className="conditionals">
        <label htmlFor="observation_filters_trait_id">Trait</label>
        <OrgSelectInput
          name="observation_filters[trait_id_eq]"
          id="observation_filters_trait_id_eq"
          options={options.traits.map((option) => [option.name, option.id])}
          placeholder="any"
          value={currentFilters.trait_id || ''}
          onChange={(selected) =>
            setFilters((prev) => ({
              ...prev,
              trait_id: selected,
              value: '',
              units: '',
            }))
          }
        />
        <div />
        <label htmlFor="observation_filters_units">Units</label>
        <OrgSelectInput
          name="observation_filters[units_eq]"
          id="observation_filters_units"
          options={units}
          disabled={!units.length}
          placeholder={units.length ? 'any' : 'N/A'}
          value={currentFilters.units || ''}
          onChange={(selected) =>
            setFilters((prev) => ({ ...prev, units: selected }))
          }
        />
        <div />
        <label htmlFor="observation_filters_value_predicate">Value</label>
        <OrgSelectInput
          id="observation_filters_value_predicate"
          options={predicates}
          placeholder="any"
          value={currentFilters.value_predicate || ''}
          onChange={(selected) =>
            setFilters((prev) => ({
              ...prev,
              ...(calculateMulti(currentFilters.value_predicate) ===
              calculateMulti(selected)
                ? {}
                : { value: calculateMulti(selected) ? [] : '' }),
              value_predicate: selected,
            }))
          }
        />
        <ValueInput
          predicate={currentFilters.value_predicate}
          picklist={picklist}
          trait_type={trait?.trait_type}
          isMulti={calculateMulti(currentFilters.value_predicate)}
          value={currentFilters.value}
          setValue={(selected) =>
            setFilters((prev) => ({ ...prev, value: selected }))
          }
        />
        <div>Recorded On (date rage)</div>
        {recordedOnKeys.map((name, i) => (
          <input
            key={name}
            placeholder={['Start (any)', 'End (any)'][i]}
            type="date"
            name={`observation_filters[${name}]`}
            value={currentFilters[name] || ''}
            onChange={(e) =>
              setFilters((prev) => ({ ...prev, [name]: e.target.value }))
            }
          />
        ))}
      </div>
    </div>
  )
}

export default AdvancedTreesSearchObservations
