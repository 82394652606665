import React from 'react'
import { serialize } from 'object-to-formdata'

// starting value for date input, https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value
export const today = () => {
  const now = new Date()
  const mm = String(now.getMonth() + 1).padStart(2, '0') // January is 0!
  const dd = String(now.getDate()).padStart(2, '0')
  const yyyy = now.getFullYear()
  return `${yyyy}-${mm}-${dd}`
}

export const toLocaleDateTimeString = (date) => {
  const d = new Date(date)
  const MDY = d.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
  const HMS = d.toLocaleTimeString('en-US')
  return `${MDY} at ${HMS}`
}

export const buildProgressSteps = (
  treeNum,
  treeCount,
  stepNumber,
  selectedTrees
) => {
  const progressStepsNext = [
    {
      header: 'Select trees',
      content: `${treeCount} selected`,
    },
  ]

  if (stepNumber >= 2) {
    progressStepsNext.push({ header: 'Add Observation Details', content: '' })
  }

  if (stepNumber >= 3) {
    progressStepsNext.push({
      header: 'Observe Trees',
      content: (
        <>
          Tree {treeNum + 1}/{treeCount}
          <ul className="step3_list">
            {selectedTrees
              .filter((tree, index) => index < treeNum + 1)
              .map((tree) => (
                <li key={tree.id}>{tree.name}</li>
              ))}
          </ul>
        </>
      ),
    })
  }
  if (stepNumber === 4) {
    progressStepsNext.push({ header: 'Review and Confirm', content: '' })
  }
  return progressStepsNext
}

/*
 * POST to /observations/wizard with two possible intents on the server dictated by the status field:
 * (1) save the current state of the observation session, which amounts to a json blob of UI state
 * (2) commit the observations to the database
 * returns a (probably ignored) boolean for success
 */
export async function createObservationSession(
  status,
  selectedLocation,
  selectedTrees,
  commonFields,
  uiState,
  currentStep,
  treeIndex,
  currentObservationSession
) {
  const csrf = document
    .querySelector("meta[name='csrf-token']")
    .getAttribute('content')

  // this is the resume state, we take out the photos which are not JSON serializable
  const wizard_json = JSON.stringify({
    selected_location: selectedLocation,
    // eslint-disable-next-line no-unused-vars -- for photos key, which we are discarding
    selected_trees: selectedTrees.map(({ photos, ...rest }) => rest),
    common_fields: commonFields,
    ui_state: uiState,
    current_step: currentStep,
    tree_index: treeIndex,
  })

  // the photos (an array of File) shaped to match the server document schema (and later form encoded)
  const documents_attributes = Object.values(
    currentObservationSession.documents
  )
    .map(({ id, _destroy, file, metadata }) => ({
      id,
      _destroy,
      file,
      metadata,
    }))
    // new, or exists and we're deleting
    .filter((document) => !document.id || (document.id && document._destroy))

  const observationSessionParams = {
    status,
    location_id: selectedLocation.id,
    wizard_json,
    documents_attributes,
  }

  const response = await fetch('/observations/wizard', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'X-CSRF-Token': csrf,
      // Content-Type is not needed for FormData
    },
    // serialize everything as form data so we can capture the photo array of File
    body: serialize({
      observation_session_id: currentObservationSession.id,
      observation_session: observationSessionParams,
    }),
  })
  let error = null
  if (!response.ok) {
    error = await response.text()
    console.warn('createObservationSession error:', error)
    throw new Error(error)
  }
  // const json = await response.json()
  return true
}

export  const filterAndReshapeDocuments = (docs, treeId) => {
  return Object.entries(docs).filter(
    // TODO: -MB why is metadata.tree_id a string here? written as in ObservationTree#handlePhotoFiles
    ([, d]) => {
      if (treeId) {
        return parseInt(d.metadata.tree_id) === treeId && !d._destroy
      } else {
        return !d._destroy
      }
    }
  )
}
