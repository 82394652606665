import React, { useEffect, useRef, useState } from "react"

import './AddressSearchBox.scss';
import GreyX from '../../shared/grey-circle-x.svg';

function AddressSearchBox({ mapApi, onPlacesChanged, address, setAddress }){

  const searchBox = useRef(null);
  const input = useRef(null);
  const [inputFocus, setInputFocus] = useState(false)

  const handleOnPlacesChanged = () => {
    const places = searchBox.current.getPlaces();
    if (onPlacesChanged) {
      onPlacesChanged(places);
      setAddress(places[0].formatted_address);
    }
  }

  const handleClearAddress = () => {
    console.log("In handleClearAddress, input is", input)
    setAddress(null);
    setInputFocus(true)
  }

  useEffect(() => {
    if (!searchBox.current && mapApi) {
      searchBox.current = new mapApi.places.SearchBox(input.current);
      searchBox.current.addListener('places_changed', handleOnPlacesChanged);
    }

    return () => {
      if (mapApi) {
        searchBox.current = null;
        mapApi.event.clearInstanceListeners(searchBox);
      }
    };
  }, [mapApi, handleOnPlacesChanged]);

  return (
    <div id="AddressSearchBox">

      <h2>Where is this planting located?</h2>
      <label htmlFor="location-input">
        Enter location address here
      </label>
        {address ? 
          <div className="search-box">
            <input ref={input} value={address} readOnly id="location-input" type="text" placeholder="Enter an address"></input>
            <div className="button" onClick={handleClearAddress}>
              <GreyX className="clear-button" />
            </div>
          </div>
          :
          <input autoFocus={inputFocus} ref={input} id="location-input" type="text" placeholder="Enter an address"></input>
        }
    </div>
  );
}

export default AddressSearchBox
