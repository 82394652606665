import React, { useEffect, useState } from 'react'
import './ObservationTree.scss'
import GoogleMapReact from 'google-map-react'
import { Tooltip } from 'react-tooltip'
import { ObservationDate } from './ObservationDate'
import { isMobile } from '../../shared/media_queries.js'
import TreeMarker from '../planting/TreeMarker'
import { ObservationObservers } from './ObservationObservers'
import NumericObservation from './NumericObservation'
import StringObservation from './StringObservation'
import { Trait, TRAIT_CFG } from './trait'
import PhotoCarousel from './PhotoCarousel'
import AdvancedObservations from './AdvancedObservations'

/*
 * Enter observations for single tree (aka step 3 of the wizard)
 *
 * All props default to "null" values (fns are checked before use) so that the view can be rendered without any data
 *
 * State for each tree is set from the parent component, RecordObservationsWizard, which will use common state
 * set in ObservationCommonFields (step 2) as defaults
 */
const ObservationTree = ({
  currentUserContact = null,
  tree = {},
  updateSelectedTree,
  treeIndex = 0,
  isSameDate = true,
  isSameObserver = true,
  selectedLocation = null,
  documents,
  updateDocuments,
  traitConfigs,
}) => {
  return (
    <>
      {!isMobile() && (
        <div className="header">
          <h2>Record observations for tree</h2>
        </div>
      )}
      <div className="ObservationTree">
        <ObservationTreePhotosAndMap
          tree={tree}
          treeIndex={treeIndex}
          updateSelectedTree={updateSelectedTree}
          documents={documents}
          updateDocuments={updateDocuments}
        />
        <ObservationTreeNameAndDetails
          tree={tree}
          selectedLocation={selectedLocation}
          treeIndex={treeIndex}
        />
        <div className="column-container">
          <div className="column right">
            <ObservationTreeDateAndObservers
              tree={tree}
              currentUserContact={currentUserContact}
              observationDate={tree.observations?.recorded_on}
              setObservationDate={(v) =>
                updateSelectedTree({ observations: { recorded_on: v } })
              }
              observer={tree.observations?.observer}
              setObserver={(v) =>
                updateSelectedTree({ observations: { observer: v } })
              }
              additionalObservers={tree.observations?.additional_observers}
              setAdditionalObservers={(v) =>
                updateSelectedTree({
                  observations: { additional_observers: v },
                })
              }
              isSameDate={isSameDate}
              isSameObserver={isSameObserver}
            />
          </div>
          <div className="column left">
            <ObservationTreeAlive
              tree={tree}
              updateSelectedTree={updateSelectedTree}
              traitConfigs={traitConfigs}
            />
            <ObservationTreeHeightAndDiameter
              tree={tree}
              height={
                tree.observations?.traits?.height ??
                Trait.toDefaultLocalState(
                  Trait.getConfigById(traitConfigs, 160)
                )
              }
              setHeight={(v) =>
                updateSelectedTree(Trait.toMergeGlobalState('height', v))
              }
              diameter={
                tree.observations?.traits?.diameter ??
                Trait.toDefaultLocalState(
                  Trait.getConfigById(traitConfigs, 161)
                )
              }
              setDiameter={(v) =>
                updateSelectedTree(Trait.toMergeGlobalState('diameter', v))
              }
            />
            <ObservationTreeComments
              tree={tree}
              comments={
                tree.observations?.traits?.comments ??
                Trait.toDefaultLocalState(Trait.getConfigById(traitConfigs, 1))
              }
              setComments={(v) =>
                updateSelectedTree(Trait.toMergeGlobalState('comments', v))
              }
            />
            <AdvancedObservations
              traitConfigs={traitConfigs}
              tree={tree}
              updateSelectedTree={updateSelectedTree}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const ObservationTreePhotosAndMap = ({
  tree,
  treeIndex,
  documents,
  updateDocuments,
}) => {
  const coordinates = tree
    ? { lat: Number(tree.latitude), lng: Number(tree.longitude) }
    : null
  const TREE_DETAIL_MAP_SIZE = {
    height: '100px',
    width: '100%',
  }
  const MAP_ZOOM = 18
  const MAP_URL_KEYS = {
    key: process.env.GOOGLE_API_KEY,
    libraries: 'places',
  }
  const MAP_OPTIONS = {
    mapTypeId: 'hybrid',
    fullscreenControl: false,
    rotateControl: false,
    tilt: 0,
  }

  const handleAddPhotoFiles = (fileInputEvent) => {
    const imageFiles = [...fileInputEvent.target.files] // FileList is not iterable, so spread that into array of File
      .filter((file) => file.type.startsWith('image/')) // ignore non image mime types
    const documentsUpload = imageFiles
      .map((file) => ({
        file,
        metadata: {
          tree_id: tree.id,
          size: file.size,
          content_type: file.type,
          last_modified: file.lastModified,
        },
        _destroy: false,
      }))
      .reduce(
        (acc, document) => ({
          ...acc,
          [`${tree.id}-${document.file.name}`]: document,
        }),
        {}
      )
    updateDocuments(documentsUpload)
  }

  return (
    <div className="ObservationTreePhotosAndMap section">
      <div className="add-photos">
        <PhotoCarousel
          tree={tree}
          documents={documents}
          updateDocuments={updateDocuments}
          handleAddPhotoFiles={handleAddPhotoFiles}
          showAddDelete={true}
        />
      </div>
      <div className="map-view" style={TREE_DETAIL_MAP_SIZE}>
        <GoogleMapReact
          bootstrapURLKeys={MAP_URL_KEYS}
          center={coordinates}
          defaultZoom={MAP_ZOOM}
          options={MAP_OPTIONS}
        >
          <TreeMarker
            name={treeIndex + 1}
            current={false}
            key={tree.id}
            lat={tree.latitude}
            lng={tree.longitude}
            tooltipTreeId={tree.id}
          />
        </GoogleMapReact>
      </div>
    </div>
  )
}

const ObservationTreeNameAndDetails = ({
  tree,
  treeIndex,
  selectedLocation,
}) => {
  const [showAllDetails, setShowAllDetails] = useState(!isMobile())
  return (
    <div className="ObservationTreeNameAndMore section">
      <div className="title">
        <strong>{tree.name}</strong> <span>(Tree {treeIndex + 1})</span>
      </div>
      <div className="details">
        <div className="detail">
          <strong>Type:</strong> {tree.cross_or_genet_name}
        </div>
        {showAllDetails ? (
          <>
            {isMobile() && (
              <button
                className="show-more"
                type="button"
                onClick={() => setShowAllDetails(false)}
              >
                Show fewer tree details &#x2715;
              </button>
            )}
            <div className="detail">
              <strong>Planted as:</strong> {tree.planted_as}
            </div>
            <div className="detail">
              <strong>Planting:</strong> {tree.planting_name}
            </div>
            <div className="detail">
              <strong>Location:</strong> {selectedLocation?.name}
            </div>
            <div className="detail">
              <strong>Space:</strong> {tree.latitude}, {tree.longitude}
            </div>
            <div className="detail">
              <strong>Date Planted:</strong> {tree.planted_on}
            </div>
          </>
        ) : (
          isMobile() && (
            <button
              className="show-more"
              type="button"
              onClick={() => setShowAllDetails(true)}
            >
              Show all tree details &#xff0b;
            </button>
          )
        )}
      </div>
    </div>
  )
}

const ObservationTreeDateAndObservers = ({
  tree,
  currentUserContact,
  observer,
  additionalObservers,
  observationDate,
  setObservationDate,
  setObserver,
  setAdditionalObservers,
  isSameDate,
  isSameObserver,
}) => {
  const [isEditDate, setIsEditDate] = useState(!isSameDate)
  const [isEditObservers, setIsEditObservers] = useState(!isSameObserver)

  useEffect(() => {
    // open date and observer sections if observer is not set (either there's one tree, or no common observer was set)
    if (observer === null) {
      setIsEditObservers(true)
      setIsEditDate(true)
    }
  }, [])

  return (
    <div className="ObservationTreeDateAndObservers section">
      <div className="summary subsection">
        <div className="section-title">
          Observation details:{' '}
          {!(isEditDate && isEditObservers) && (
            <button
              className="edit"
              type="button"
              onClick={() => {
                setIsEditDate(true)
                setIsEditObservers(true)
              }}
            >
              Edit
            </button>
          )}
        </div>

        {!isEditDate && (
          <p>
            <strong>Date:</strong> {observationDate || 'n/a'}
          </p>
        )}

        {!isEditObservers && (
          <p>
            <strong>Observer:</strong> {observer?.description || ''}{' '}
            {additionalObservers
              .filter((name) => name.trim().length > 0)
              .join(', ')}
          </p>
        )}
      </div>

      {isEditDate && (
        <div className="date subsection">
          <div className="section-title">
            Date of {tree?.name}&apos;s observation
          </div>
          <div className="date-picker">
            <ObservationDate
              defaultValue={observationDate}
              onChange={setObservationDate}
            />
          </div>
        </div>
      )}

      {isEditObservers && (
        <div className="observers subsection">
          <ObservationObservers
            currentUserContact={currentUserContact}
            observer={observer}
            setObserver={setObserver}
            additionalObservers={additionalObservers}
            setAdditionalObservers={setAdditionalObservers}
          />
        </div>
      )}
    </div>
  )
}

// this trait is tricky: in the UI here we assume no observation and no initial state
// (but we report the tree.value as the previous known state)
// the server "schema" for this trait expects values of 'Alive' or 'Dead'
// the user may select 'yes' or 'no' and we set the trait to 'Alive' or 'Dead'
// or re-select 'no-observation' which will set the trait value to null, causing it to be removed from the observations
// once we commit this data, if present, the server will update tree.alive from this observation
// (note, updateSelectedTree does the pruning of the trait if the value is null)
const ObservationTreeAlive = ({ tree, updateSelectedTree, traitConfigs }) => {
  const survivalTrait = tree.observations?.traits?.survival
  let selectOption = 'no-observation'
  if (survivalTrait !== undefined) {
    selectOption = survivalTrait.value
  }
  return (
    <div className="ObservationTreeAlive section bottom-bordered">
      <div className="section-title">Is {tree.name} alive?</div>
      <div>
        <select
          value={selectOption}
          onChange={(e) => {
            let valueNext = null
            if (e.target.value !== 'no-observation') {
              valueNext = e.target.value
            }
            console.debug('traitConfigs', traitConfigs)
            const survivalTraitNext = Trait.toDefaultLocalState(
              Trait.getConfigById(traitConfigs, 58),
              valueNext
            )
            updateSelectedTree(
              Trait.toMergeGlobalState('survival', survivalTraitNext)
            )
          }}
        >
          <option value="no-observation">&mdash;</option>
          <option value="Alive">Yes</option>
          <option value="Dead">No</option>
        </select>
      </div>
      <span>Last observed as {tree.alive ?? true ? 'alive' : 'dead'}</span>
    </div>
  )
}

const ObservationTreeHeightAndDiameter = ({
  tree,
  height,
  setHeight,
  diameter,
  setDiameter,
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  return (
    <>
      <div className="ObservationTreeHeightAndDiameter section bottom-bordered">
        <div className="section-title">
          What’s the height and diameter of {tree.name}’s largest living stem?
          <span className="subtitle">(OPTIONAL)</span>
        </div>
        <NumericObservation
          label={TRAIT_CFG.height.name}
          trait={height}
          setTrait={setHeight}
          allowedUnits={TRAIT_CFG.height.units}
        />
        <NumericObservation
          tooltipId="diameter-tooltip"
          label={TRAIT_CFG.diameter.name}
          trait={diameter}
          setTrait={setDiameter}
          allowedUnits={TRAIT_CFG.diameter.units}
        />
      </div>
      <Tooltip
        className="tooltip"
        anchorSelect="#diameter-tooltip"
        variant="light"
        clickable
        openOnClick
        isOpen={tooltipIsOpen}
        setIsOpen={setTooltipIsOpen}
      >
        Diameter at Breast Height (DBH) is measured at 4.5 feet off the ground.{' '}
        <a
          href="https://en.wikipedia.org/wiki/Diameter_at_breast_height"
          target="_blank"
          rel="noreferrer noopener"
        >
          More information here.
        </a>
      </Tooltip>
    </>
  )
}

const ObservationTreeComments = ({ tree, comments, setComments }) => {
  return (
    <div className="ObservationTreeComments section bottom-bordered">
      {' '}
      <div className="section-title">
        Comments about {tree.name}
        <span className="subtitle">(OPTIONAL)</span>
      </div>
      <StringObservation trait={comments} setTrait={setComments} />
    </div>
  )
}

export default ObservationTree
