import React from 'react'
import './StringObservation.scss'
import { Trait } from './trait'

const StringObservation = ({ label = '', trait, setTrait, rows = 4 }) => {
  return (
    <div className="StringObservation">
      <label htmlFor="measurement-input">{label}</label>
      <textarea
        id="measurement-input"
        value={trait.value || ''}
        onChange={(e) =>
          setTrait(Trait.merge(trait, { value: e.target.value }))
        }
        rows={rows}
      />
    </div>
  )
}

export default StringObservation
