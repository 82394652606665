import React, { useState } from 'react';
import './MobileExpandableLink.scss';
import caretRight from '../../shared/caret-right-icon.png';

const isCurrentPath = (path) => {
  return path === window.location.pathname + window.location.search;
}

const MobileExpandableLink = ({display, to, isChild, children}) => {
  const [contentOpen, setContentOpen] = useState(false);
  const itemClass = isCurrentPath(to) ? 'bm-item current-path' : 'bm-item';
  return (
    <div className='MobileExpandableLink'>
      <div className="expandable">
        {children 
          ? 
          <button onClick={() => setContentOpen(!contentOpen)}> 
            <img className={contentOpen ? 'down': ''} src={caretRight} alt="expand" />
            <p className={`${itemClass} parent`}>{display}</p>
          </button>
          :
          <a className={itemClass} href={to}>{display}</a>
        }
      </div>
      {contentOpen && children &&
        <div className="children">
          {children}
        </div>
      }
    </div>
  )
}

export default MobileExpandableLink