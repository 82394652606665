import React, { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import MobileExpandableLink from './MobileExpandableLink'
import SearchForm from './SearchForm.jsx'
import './MobileMenu.scss'
import search from '../../shared/search-icon-white.png'
import CloseSVG from '../../shared/close-icon-white.svg'

const MenuButton = (isOpen) => {
  return (
    <div className="MenuButton">
      {isOpen.isOpen ? (
        <CloseSVG />
      ) : (
        <img className="search" src={search} alt="search" />
      )}
      <p>Menu</p>
    </div>
  )
}

const MobileMenu = ({ isAdmin }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [searchEverywhereValue, setSearchEverywhereValue] = useState('')

  const handleMenuOpen = () => {
    document.body.classList.add('fixed-body')
    setIsOpen(true)
  }

  const handleMenuClose = () => {
    document.body.classList.remove('fixed-body')
    setIsOpen(false)

    // Reset the search everywhere form when the menu is closed
    setSearchEverywhereValue('')
  }

  return (
    <div className="MobileMenu">
      <Menu
        isOpen={isOpen}
        customBurgerIcon={<MenuButton isOpen={isOpen} />}
        customCrossIcon={false}
        right
        width="100vw"
        onOpen={handleMenuOpen}
        onClose={handleMenuClose}
      >
        <SearchForm
          value={searchEverywhereValue}
          setValue={setSearchEverywhereValue}
        />
        <MobileExpandableLink to="/" display="Dashboard" />
        <MobileExpandableLink to="/regions" display="Regions" />
        <MobileExpandableLink to="/chapters" display="Chapters" />
        <MobileExpandableLink to="/parcels" display="Parcels" />
        <MobileExpandableLink to="/orchards" display="Orchards" />
        <MobileExpandableLink display="Trees">
          <MobileExpandableLink to="/plantings" display="Plantings" />
          <MobileExpandableLink
            to="/trees?filters%5Bcurrently_planted_eq%5D=true&filters%5Bwild_eq%5D=false"
            display="Planted trees"
          />
          <MobileExpandableLink
            to="/trees?filters%5Bcurrently_planted_eq%5D=false&filters%5Bwild_eq%5D=false"
            display="Removed trees"
          />
          <MobileExpandableLink
            to="/trees?filters%5Bwild_eq%5D=true"
            display="Wild trees"
          />
          <MobileExpandableLink
            to="/trees/advanced"
            display="Advanced Trees Search"
          />
          <MobileExpandableLink to="/genets" display="Genets" />
          <MobileExpandableLink
            to="/distributions?filters%5Btype_eq%5D=GenetDistribution"
            display="Genet Distributions"
          />
        </MobileExpandableLink>
        <MobileExpandableLink display="Crosses">
          <MobileExpandableLink to="/crosses" display="Crosses" />
          <MobileExpandableLink
            to="/distributions?filters%5Btype_eq%5D=SeedlotDistribution"
            display="Seedlot Distributions"
          />
          <MobileExpandableLink
            to="/distributions?filters%5Btype_eq%5D=PollenDistribution"
            display="Pollen Distributions"
          />
        </MobileExpandableLink>
        <MobileExpandableLink to="/traits" display="Traits" />
        <MobileExpandableLink to="/contacts" display="Contacts" />
        <MobileExpandableLink display="Uploads">
          <MobileExpandableLink
            to="/crosses/upload"
            display="Upload cross data in bulk"
          />
          <MobileExpandableLink
            to="/distributions/upload"
            display="Upload distribution data in bulk"
          />
          <MobileExpandableLink
            to="/plantings/historic"
            display="Upload historic planting data"
          />
          <MobileExpandableLink
            to="/trees/observations/prepare-worksheet"
            display="Upload tree observations"
          />
          <MobileExpandableLink
            to="/orchards/historic-observations"
            display="Upload historic observation data"
          />
          <MobileExpandableLink
            to="/trees/bulk-wild-csv"
            display="Upload wild tree data"
          />
          <MobileExpandableLink
            to="/trees/bulk-update-page"
            display="Rename/re-tag trees"
          />
        </MobileExpandableLink>
        {isAdmin && (
          <MobileExpandableLink display="System">
            <MobileExpandableLink to="/roles" display="Roles" />
            <MobileExpandableLink to="/picklists" display="Picklists" />
            <MobileExpandableLink
              to="/terms_and_conds"
              display="Terms and Conditions"
            />
            <MobileExpandableLink to="/user_guide/edit" display="User Guide" />
            <MobileExpandableLink
              to="/dashboard/audit?male_or_female=female"
              display="Cross/Tree Audit (female)"
            />
            <MobileExpandableLink
              to="/dashboard/audit?male_or_female=male"
              display="Cross/Tree Audit (male)"
            />
            <MobileExpandableLink to="/glossary_entries" display="Glossary" />
          </MobileExpandableLink>
        )}
      </Menu>
    </div>
  )
}

export default MobileMenu
