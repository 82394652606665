import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import SortableColumnHeader from './SortableColumnHeader'
import Map from './Map'
import { getDateString, sortBy } from '../../shared/utils'
import editSpace from '../../shared/edit-space.png'

import './ReviewAndFinish.scss'

const flattenTrees = (trees) =>
  trees.map((tree, index) => ({
    name: tree.name,
    treeType: tree.treeType.displayName,
    plantedAs: tree.plantedAs.label,
    lat: tree.lat,
    lng: tree.lng,
    index: index + 1,
  }))

const ReviewAndFinish = ({
  definedTrees,
  locationName,
  plantingDate,
  handleDetailsEdit,
  handleTreeEdit,
  handleTreeAdd,
  location,
}) => {
  const [treeIDSort, setTreeIDSort] = useState('ascending')
  const [treeNameSort, setTreeNameSort] = useState(null)
  const [treeTypeSort, setTreeTypeSort] = useState(null)
  const [sortedTrees, setSortedTrees] = useState(flattenTrees(definedTrees))
  const [highlightedTreeIndex, setHighlightedTreeIndex] = useState(null)

  const setSort = (sortField, sortDirection) => {
    const reverse = sortDirection === 'descending'
    if (sortDirection && sortField === 'Tree Name') {
      setSortedTrees(sortedTrees.sort(sortBy('name', reverse)))
      setTreeNameSort(sortDirection)
      setTreeTypeSort(null)
      setTreeIDSort(null)
    } else if (sortDirection && sortField === 'Type') {
      setSortedTrees(sortedTrees.sort(sortBy('treeType', reverse)))
      setTreeTypeSort(sortDirection)
      setTreeNameSort(null)
      setTreeIDSort(null)
    } else {
      setSortedTrees(sortedTrees.sort(sortBy('index', reverse)))
      setTreeIDSort(sortDirection)
      setTreeTypeSort(null)
      setTreeNameSort(null)
    }
  }

  function handleHover(treeIndex) {
    setHighlightedTreeIndex(treeIndex)
  }

  function reviewRows(trees) {
    return trees.map((tree, index) => {
      return (
        <tr key={tree.index} onMouseEnter={() => handleHover(index)}>
          <td>
            <button
              type="button"
              id="treeIdButton"
              onClick={() => handleTreeEdit(index)}
            >
              <p>{`Tree ${tree.index}`}</p>
            </button>
          </td>
          <td>
            <button type="button" onClick={() => handleTreeEdit(index)}>
              <p>{tree.name}</p>
            </button>
          </td>
          <td>
            <p>{tree.treeType}</p>
          </td>
          <td>
            <p>{tree.plantedAs}</p>
          </td>
          <td>
            <div className="complex-cell">
              <p>{`${tree.lat.toFixed(5)}, ${tree.lng.toFixed(5)}`}</p>
              <button type="button" className="map-tooltip-anchor">
                <img className="edit-space" src={editSpace} alt="edit space" />
              </button>
            </div>
          </td>
        </tr>
      )
    })
  }

  return (
    <div id="ReviewAndFinish">
      {definedTrees.length > 0 && (
        <Tooltip
          anchorSelect=".map-tooltip-anchor"
          className="tooltip-extra-class"
          place="left"
        >
          <Map
            plantingLocation={location.pos}
            showLocationInputBox={false}
            currTreeIndex={highlightedTreeIndex}
            highlightedTreeIndex={highlightedTreeIndex}
            miniMap={true}
            definedTrees={definedTrees}
          />
        </Tooltip>
      )}
      <h2>Review and finish your planting.</h2>
      <div className="columns">
        <div className="column left">
          <div className="data-item">
            <span className="label">Location:</span>
            <span className="value">{locationName}</span>
            <button
              type="button"
              onClick={() => handleDetailsEdit('locationName')}
            >
              Edit
            </button>
          </div>
          <div className="data-item">
            <span className="label">Date of Planting:</span>
            <span className="value">{getDateString(plantingDate)}</span>
            <button
              type="button"
              onClick={() => handleDetailsEdit('plantingDate')}
            >
              Edit
            </button>
          </div>
          <div className="data-item">
            <span className="label">Number Planted:</span>
            <span className="value">{` ${sortedTrees.length}`}</span>
          </div>
        </div>
        <div className="column right">
          <button type="button" onClick={handleTreeAdd}>
            <span className="plus">+</span>
            <span>Add tree</span>
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>
              <SortableColumnHeader
                name="Tree ID"
                sort={treeIDSort}
                toggleSort={setSort}
              />
            </td>
            <td>
              <SortableColumnHeader
                name="Tree Name"
                sort={treeNameSort}
                toggleSort={setSort}
              />
            </td>
            <td>
              <SortableColumnHeader
                name="Type"
                sort={treeTypeSort}
                toggleSort={setSort}
              />
            </td>
            <td>Planted as</td>
            <td>Space</td>
          </tr>
        </thead>
        <tbody>{sortedTrees && reviewRows(sortedTrees)}</tbody>
      </table>
    </div>
  )
}

export default ReviewAndFinish
