import React, { useState, useEffect } from "react"

import './AddPlantingNavButtonBar.scss';

function AddPlantingNavButtonBar({enablePrevious, enableNext, enableSave, nextStep, prevStep, 
  save, isFinal, handleCancel, handleDelete, enableDelete}){

  const nextLabel = isFinal ? 'Finish' : 'Next'

  return (
    <div id="AddPlantingNavButtonBar">
      <button onClick={prevStep} disabled={!enablePrevious} className="previous">Previous</button>
      <button onClick={handleCancel} className="cancel">Cancel</button>
      <button onClick={handleDelete} disabled={!enableDelete} className="delete">Delete</button>
      <button onClick={save} disabled={!enableSave} className="save">Save and Exit</button>
      <button onClick={nextStep} disabled={!enableNext} className="next" >{nextLabel}</button>
    </div>
  );
}

export default AddPlantingNavButtonBar
