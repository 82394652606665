import React, { useRef } from 'react'
import './SpreadsheetInterface.scss'

const tradHeader = [
  'Space ID (internal)',
  'Orchard name',
  'Plot name',
  'Plot ID',
  'Space label',
  'Row',
  'Position',
  '||',
  'Cross or genet map code',
  'Transgenic (Y/N/blank)',
  'Tree shortcode (leave blank for default)',
  'Tree name (leave blank to use shortcode)',
  'Tree tag (optional)',
  'Tree comments (optional)',
  '"Planted as (defaults: cross -> seed, genet -> plantlet)"', // double quotes to escape comma
]

const messages = {
  plantingIsUnsaved: 'This planting is unsaved. Please save before continuing.',
  unsavedChanges: 'You have unsaved changes. Please save before continuing.',
  confirmOverwrite:
    'You have planned trees in this planting. Proceeding will overwrite your current design. Are you sure you want to proceed?',
}

const filteredPlotSpacesInOrder = (plot) =>
  plot.spaces
    .filter((space) => space.available)
    .sort((a, b) => (a.row === b.row ? a.col - b.col : a.row - b.row))

const generateTradSpreadsheet = (
  plots,
  plannedTreesBySpace,
  plantingItems,
  joiner = '\t'
) => {
  const rows = [tradHeader.join(joiner)]
  plots.forEach((plot) =>
    filteredPlotSpacesInOrder(plot).forEach((space) => {
      const plannedTree = plannedTreesBySpace[space.id]
      const plantingItem = plantingItems[plannedTree?.planting_item_id]
      const row = [
        space.id,
        plot.orchard_name,
        plot.name,
        plot.id,
        space.label,
        space.row_designation,
        space.col_designation,
        '||',
        plantingItem?.code || '',
        plannedTree?.transgenic || '',
        plannedTree?.shortcode || '',
        plannedTree?.name || '',
        plannedTree?.tag || '',
        plannedTree?.comments || '',
        plannedTree?.planted_as || '',
      ]
      rows.push(row.join(joiner))
    })
  )
  return rows
}

const copyToClipboard = (text) => {
  const contentToCopy = Array.isArray(text) ? text.join('\n') : text
  navigator.clipboard
    .writeText(contentToCopy)
    .then(() => {
      console.log('Content copied to clipboard') // eslint-disable-line no-console
      alert('Content copied to clipboard') // eslint-disable-line no-alert
    })
    .catch((err) => {
      console.error('Error copying content: ', err) // eslint-disable-line no-console
      alert('Error copying content') // eslint-disable-line no-alert
    })
}

const SpreadsheetInterface = ({
  form_authenticity_token,
  plannedTreesBySpace,
  plantingId,
  plantingItems,
  plantingHasChanged,
  plots,
}) => {
  const formRef = useRef(null)

  const confirmThings = () => {
    if (!plantingId) {
      alert(messages.plantingIsUnsaved) // eslint-disable-line no-restricted-globals
      return false
    }
    if (plantingHasChanged) {
      alert(messages.unsavedChanges) // eslint-disable-line no-restricted-globals
      return false
    }
    const someTrees = !!Object.keys(plannedTreesBySpace).length
    if (someTrees && !confirm(messages.confirmOverwrite)) return false // eslint-disable-line no-restricted-globals
    return true
  }

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData('text')
    if (!confirmThings()) return

    // Create a Blob and File from the pasted text
    const blob = new Blob([pastedText], { type: 'text/csv' })
    const file = new File([blob], 'design.csv', { type: 'text/csv' })

    // Add the file to the existing file input
    if (!formRef.current) return

    const inputFileElement = formRef.current.querySelector(
      'input[name="design_csv"]'
    )
    const dataTransfer = new DataTransfer()
    dataTransfer.items.add(file)
    inputFileElement.files = dataTransfer.files
    formRef.current.submit()
  }

  const downloadFile = (filename) => {
    if (!plots.length) {
      alert('No plots to download')
      return
    }
    const text = generateTradSpreadsheet(
      plots,
      plannedTreesBySpace,
      plantingItems,
      ','
    )
    const fileContent = Array.isArray(text) ? text.join('\n') : text

    const blob = new Blob([fileContent], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handleClickUploadSpreadsheet = (event) => {
    if (!confirmThings) event.preventDefault()
    // Let the browser handle the file input event, attaching the file to the form
  }

  const handleChangeUploadSpreadsheet = () => {
    if (formRef.current) formRef.current.submit()
  }

  return (
    <div className="SpreadsheetInterface">
      <button
        type="button"
        disabled={!plots.length}
        onClick={() =>
          copyToClipboard(
            generateTradSpreadsheet(plots, plannedTreesBySpace, plantingItems)
          )
        }
      >
        Copy to Clipboard
      </button>

      <textarea
        placeholder="Paste from clipboard"
        value=""
        onPaste={handlePaste}
        onChange={() => {}} // Prevent React from complaining about uncontrolled component
      />

      <button
        type="button"
        disabled={!plots.length}
        onClick={() => downloadFile('new_new_spreadsheet.csv')}
      >
        Download as CSV
      </button>

      <form
        action={`/plantings/${plantingId}/submit-design`}
        encType="multipart/form-data"
        ref={formRef}
        method="post"
      >
        <input type="hidden" name="_method" value="put" />
        <input type="hidden" name="review_mode" value="true" />
        <input
          type="hidden"
          name="authenticity_token"
          value={form_authenticity_token}
        />
        <label className="upload-spreadsheet">
          Upload Spreadsheet
          <input
            type="file"
            name="design_csv"
            accept=".csv, .xlsx"
            onClick={handleClickUploadSpreadsheet}
            onChange={handleChangeUploadSpreadsheet}
          />
        </label>
      </form>
    </div>
  )
}

export default SpreadsheetInterface
