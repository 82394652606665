import React from 'react'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { CLOSE_X_ICON } from '../../shared/constants'

const genericModalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    maxWidth: 'calc(100vw - 40px)',
    padding: '24px 32px',
    maxHeight: '80vh',
    overflowY: 'overlay',
    zIndex: 6001, // should be above most things, but under tooltips
  },
  overlay: {
    backgroundColor: '#69696980',
    zIndex: 6000, // should be above most things, but under tooltips
  },
}

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerText: {
    margin: 0,
  },
  closeButton: {
    background: 'none',
    padding: 0,
    border: 'none',
    cursor: 'pointer',
    fontSize: '2.5rem',
  },
  headerSpacer: {
    marginBottom: '16px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginTop: '24px',
  },
  buttons: {
    shared: {
      border: 'none',
      padding: '10px 20px',
      margin: '5px',
      cursor: 'pointer',
    },
    ok: {
      backgroundColor: '#4CAF50',
      color: 'white',
    },
    cancel: {
      backgroundColor: '#f44336',
      color: 'white',
    },
    disabled: {
      backgroundColor: '#d3d3d3',
      color: '#808080',
      cursor: 'not-allowed',
    },
  },
}

const GenericModal = ({
  appElementId,
  buttons,
  children,
  contentLabel,
  customStyles,
  header,
  isOpen,
  onRequestClose,
}) => {
  return (
    <Modal
      appElement={document.getElementById(appElementId)}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      style={{
        content: { ...genericModalStyle.content, ...customStyles.content },
        overlay: { ...genericModalStyle.overlay, ...customStyles.overlay },
      }}
    >
      <div style={styles.header}>
        <h2 style={styles.headerText}>{header}</h2>
        <button
          style={styles.closeButton}
          type="button"
          onClick={onRequestClose}
        >
          {CLOSE_X_ICON}
        </button>
      </div>
      <div style={styles.headerSpacer} />
      <div>{children}</div>
      {buttons.length > 0 && (
        <div style={styles.buttonContainer}>
          {buttons.map(({ onClick, text, style, disabled }) => (
            <button
              key={text}
              type="button"
              onClick={onClick}
              style={{
                ...styles.buttons.shared,
                ...styles.buttons[style],
                ...(disabled && styles.buttons.disabled),
              }}
              disabled={disabled}
            >
              {text}
            </button>
          ))}
        </div>
      )}
    </Modal>
  )
}

GenericModal.defaultProps = {
  appElementId: 'main',
  buttons: [],
  contentLabel: 'modal label',
  customStyles: {},
  header: '',
}

const PropTypeStyleObject = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.number, PropTypes.string])
)

GenericModal.propTypes = {
  appElementId: PropTypes.string,
  contentLabel: PropTypes.string,
  customStyles: PropTypes.shape({
    content: PropTypeStyleObject,
    overlay: PropTypeStyleObject,
  }),
  header: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      text: PropTypes.string.isRequired,
      style: PropTypes.oneOf(['ok', 'cancel']),
      disabled: PropTypes.bool,
    })
  ),
}

export default GenericModal
