.PlantingItemModalInsideModal .genetic-origin-chooser {
  padding: 5px;
  border: 1px solid black;
}
.PlantingItemModalInsideModal .filter-inputs {
  width: 100%;
  display: flex;
  gap: 20px;
}
.PlantingItemModalInsideModal .filter-inputs > * {
  flex: 1;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF8xNWQ4ZjY1MS9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL3BsYW50aW5nL3Bsb3R0ZWQiLCJzb3VyY2VzIjpbIlBsYW50aW5nSXRlbU1vZGFsLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0U7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTtFQUNBOztBQUNBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IFwiLi4vLi4vLi4vLi4vYXNzZXRzL3N0eWxlc2hlZXRzL21peGlucy9idXR0b25zXCI7XG5cbi5QbGFudGluZ0l0ZW1Nb2RhbEluc2lkZU1vZGFsIHtcbiAgLmdlbmV0aWMtb3JpZ2luLWNob29zZXIge1xuICAgIHBhZGRpbmc6IDVweDtcbiAgICBib3JkZXI6IDFweCBzb2xpZCBibGFjaztcbiAgfVxuXG4gIC5maWx0ZXItaW5wdXRzIHtcbiAgICB3aWR0aDogMTAwJTtcbiAgICBkaXNwbGF5OiBmbGV4O1xuICAgIGdhcDogMjBweDtcbiAgICA+ICoge1xuICAgICAgZmxleDogMTtcbiAgICB9XG4gIH1cbn1cbiJdfQ== */