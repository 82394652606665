import React from 'react'
import GoogleMapReact from 'google-map-react'
import { Tooltip } from 'react-tooltip'
import {
  DEFAULT_ZOOM_OBSERVATION_MAP,
  TACF_LOCATION,
} from '../../shared/constants'
import TreeMarker from '../planting/TreeMarker'
import './ObservationMap.scss'
import { isMobile } from '../../shared/media_queries'

/*
 * Wrapper around https://github.com/google-map-react/google-map-react
 * Centers the map on a selected location and shows the trees as markers
 *
 * trees  - array of tree objects which include their own lat, lng (renamed from latitude etc)
 *          these are rendered as map markers
 * center - where to center the map, expects { lat, lng }
 * zoom   - zoom level (defaults to DEFAULT_ZOOM_OBSERVATION_MAP)
 * size   - on of the keys in MAP_SIZES (defaults to desktop)
 * selectedTreeIds - set of tree ids that are currently selected
 * setSelectedTreeIds - function to call when a tree marker is clicked
 * resetSelectedTreeIds - function to call when the "Clear all" button is clicked
 *
 * All props default to "null" values (fns are checked before use) so that the map can be rendered without any data
 */

const MAP_SIZE = { height: '450px', width: '100%' }
const MAP_URL_KEYS = {
  key: process.env.GOOGLE_API_KEY,
  libraries: 'places',
}
const MAP_OPTIONS = {
  mapTypeId: 'hybrid',
  fullscreenControl: false,
  rotateControl: false,
  tilt: 0,
}

const TreeDetailsTooltip = ({ tree }) => {
  return (
    <React.Fragment key={tree.id}>
      <span style={{ color: 'blue' }}>{tree?.name}</span>
      <br />
      <span style={{ fontWeight: 'bold' }}>
        {parseFloat(tree?.latitude)?.toFixed(5)},{' '}
        {parseFloat(tree?.longitude)?.toFixed(5)}
      </span>
      <br />
      {tree?.cross_or_genet_name}
      <br />
      Planted {tree?.planted_on}
      <br />
      Planted by {tree?.planted_by_name}
      <br />
      <a href={`/trees/${tree?.id}`} target="_blank" rel="noopener noreferrer">
        View all details
      </a>
    </React.Fragment>
  )
}

const ObservationMap = ({
  trees = [],
  center = TACF_LOCATION,
  zoom = DEFAULT_ZOOM_OBSERVATION_MAP,
  selectedTreeIds = new Set(),
  setSelectedTreeIds,
}) => {
  const handleTreeMarkerClick = (event, treeId) => {
    event.stopPropagation()
    const nextSelectedTreeIds = new Set(selectedTreeIds)
    // are we adding a tree or removing a tree from the selected list?
    if (nextSelectedTreeIds.has(treeId)) {
      nextSelectedTreeIds.delete(treeId)
    } else {
      nextSelectedTreeIds.add(treeId)
    }

    if (setSelectedTreeIds) setSelectedTreeIds(nextSelectedTreeIds)
  }

  return (
    <>
      <div className="map-container" style={MAP_SIZE}>
        <GoogleMapReact
          bootstrapURLKeys={MAP_URL_KEYS}
          center={center}
          defaultZoom={zoom}
          options={MAP_OPTIONS}
        >
          {trees.map((tree) => {
            return (
              <TreeMarker
                onClick={(event) => handleTreeMarkerClick(event, tree.id)}
                current={selectedTreeIds.has(tree.id)}
                key={tree.id}
                lat={tree.latitude}
                lng={tree.longitude}
                tooltipTreeId={tree.id}
                // note we don't display a "name" for observations (by the design)
              />
            )
          })}
        </GoogleMapReact>
      </div>
      {!isMobile() && (
        <Tooltip
          id="tree-marker-tooltip"
          variant="light"
          clickable
          delayShow={750}
          delayHide={500}
          place="right-end"
          render={({ content }) => {
            const tree = trees.find((t) => t.id === Number(content))
            return <TreeDetailsTooltip tree={tree} />
          }}
        />
      )}
    </>
  )
}

const ObservationMapControls = ({ onClickReset }) => {
  return (
    <div className="map-instructions">
      <span className="text">
        {' '}
        Tap or click on an orange tree marker to select a tree.
      </span>
      <button type="button" className="button-as-link" onClick={onClickReset}>
        Clear all
      </button>
    </div>
  )
}

export { ObservationMap, ObservationMapControls }
