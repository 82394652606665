import React, { useState } from 'react'
import AsyncPaginatedLocationSelect from './AsyncPaginatedLocationSelect'
import RecordsTable from '../application/RecordsTable'
import { TreeTooltips } from './TreeTooltips'
import { ObservationMap, ObservationMapControls } from './ObservationMap'
import { TACF_LOCATION } from '../../shared/constants'
import { isMobile } from '../../shared/media_queries'
import './ObservationSelectTrees.scss'
import { selectTreesColumnsConfig } from './observation_config'

const ObservationSelectTrees = ({
  selectedTrees,
  setSelectedTrees,
  resetSelectedTrees,
  selectedLocation,
  setSelectedLocation,
}) => {
  const [trees, setTrees] = useState(selectedLocation?.located_trees || [])
  const [listOrMapView, setListOrMapView] = useState('list')
  const isMobileCached = isMobile() // use cached to avoid React order of hooks errors
  const mediaClass = isMobileCached ? 'mobile' : 'desktop'

  /*
   * Column configuration, see RecordsTable.jsx
   */
  const columns = selectTreesColumnsConfig(isMobileCached)
    // hides columns based on their participation in desktop and/or mobile layouts
    .map((c) => {
      const colUpdated = c
      if (!colUpdated.layouts.split(' ').includes(mediaClass)) {
        colUpdated.cssClass = `${colUpdated.cssClass} hide`
      }
      return colUpdated
    })

  const toggleButton = (label) => (
    <button
      type="button"
      onClick={() => setListOrMapView(label)}
      className={`toggleButton ${listOrMapView === label ? 'selected' : ''}`}
    >
      {label}
    </button>
  )

  const handleSelectTree = (treeIds, treeList) => {
    const selectedTreesNext = treeList.filter((tree) => treeIds.has(tree.id))
    setSelectedTrees(selectedTreesNext)
  }

  const handleChangeLocation = (newLocation) => {
    resetSelectedTrees()
    setSelectedLocation(newLocation)
    setTrees(newLocation.located_trees)
  }

  // find the center of the map based on the tree locations
  // this *simple* approach finds the corners (ie lat/lng max/min) and averages them
  // this fails if the location crosses the 180th meridian (got any plantings in Kamchatka?)
  const findCenter = (treeList) => {
    const lats = treeList.map((t) => t.latitude)
    const lngs = treeList.map((t) => t.longitude)
    return {
      lat: (Math.min(...lats) + Math.max(...lats)) / 2,
      lng: (Math.min(...lngs) + Math.max(...lngs)) / 2,
    }
  }

  // we'll try to center on the group of trees, falling back to the location itself, falling back to TACF's location
  const getCenterOrDefault = (location, treeList) => {
    if (treeList.length > 0) {
      return findCenter(treeList)
    }
    const lat = Number(location.lat)
    const lng = Number(location.lng)
    // damn js is terrible with data types
    if (lat === 0 || Number.isNaN(lat) || lng === 0 || Number.isNaN(lng)) {
      return TACF_LOCATION
    }
    return {
      lat: Number(location.lat),
      lng: Number(location.lng),
    }
  }

  const computeSelectedTreeIdSet = () => new Set(selectedTrees.map((t) => t.id))

  return (
    <>
      <div className="header">
        <h2>Select one or more trees to observe</h2>
        <div className="list-map-toggle">
          {toggleButton('list')}
          {toggleButton('map')}
        </div>
      </div>
      <div className={`body-observation-select-trees ${listOrMapView}`}>
        <div className="locationChooser">
          <label
            className="locationChooser"
            htmlFor="ro-wizard-location-chooser"
          >
            Choose Location
          </label>
          <AsyncPaginatedLocationSelect
            id="ro-wizard-location-chooser"
            defaultValue={selectedLocation}
            onChange={handleChangeLocation}
          />
        </div>
        <div className="list">
          {trees.length > 0 && (
            <RecordsTable
              records={trees}
              columns={columns}
              selectedIds={computeSelectedTreeIdSet()}
              setSelectedIds={(treeIds) => handleSelectTree(treeIds, trees)}
            >
              <TreeTooltips trees={trees} isMobile={isMobileCached} />
            </RecordsTable>
          )}
        </div>
        <div className="map">
          {selectedLocation && trees.length > 0 && (
            <>
              <ObservationMapControls onClickReset={resetSelectedTrees} />
              <ObservationMap
                center={getCenterOrDefault(selectedLocation, trees)}
                trees={trees}
                selectedTreeIds={computeSelectedTreeIdSet()}
                setSelectedTreeIds={(treeIds) =>
                  handleSelectTree(treeIds, trees)
                }
                resetSelectedTreeIds={() => resetSelectedTrees()}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default ObservationSelectTrees
