import React from 'react'
import './ProgressBar.scss'
import { isMobile } from '../../shared/media_queries.js'

const ProgressBar = ({ title, steps }) => {
  const mobile = isMobile()

  return (
    <div id="ProgressBar" className={mobile ? 'mobile' : 'desktop'}>
      <h2>{title}</h2>
      <ol className="steps">
        {steps.map(
          (step, stepIndex) =>
            (!mobile || stepIndex === steps.length - 1) && (
              <li key={step.header} className="step">
                <div className="step">
                  <div className="header">
                    <button type="button" onClick={step.headerFunc}>
                      {`Step ${stepIndex + 1}:  ${step.header}`}
                    </button>
                  </div>
                  <div className="content">{step.content}</div>
                </div>
              </li>
            )
        )}
      </ol>
    </div>
  )
}

export default ProgressBar
