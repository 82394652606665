import React from 'react'
import './ObservationCommonFields.scss'
import { ObservationDate } from './ObservationDate'
import { ObservationObservers } from './ObservationObservers'

/*
 * By design this step is skipped if only one tree was previously selected.
 * This step attempts to establish if multiple trees were observed
 * (1) on the same date and (2) by the same people.
 * NOTE if any common field is set or changed here, that value is set/re-set on ALL currently selected trees.
 * Else following individual tree observations will require date and people details.
 * Careful with the varying field name casing,
 */
const ObservationCommonFields = ({
  selectedTrees,
  currentUserContact,
  commonFields,
  updateCommonFields,
  uiState,
  updateUiState,
}) => {
  const treeCount = selectedTrees.length

  return (
    <>
      <div className="header">
        <h2>Add observation details</h2>
      </div>
      <div className="body-observation-common-fields">
        <hr />
        <section className="observation-common-fields">
          <h3 className="observation-heading">Observation date(s)</h3>
          <p className="observation-subheader">
            Were all {treeCount} trees observed on the same date?
          </p>
          <label className="observation-option">
            <input
              className="observation-input"
              type="radio"
              name="date"
              checked={uiState.isCommonDate}
              onChange={() => updateUiState({ isCommonDate: true })}
            />
            Yes, all were observed on one date
          </label>
          <label className="observation-option">
            <input
              className="observation-input"
              type="radio"
              name="date"
              checked={!uiState.isCommonDate}
              onChange={() => updateUiState({ isCommonDate: false })}
            />
            No, they were observed on more than one date
          </label>

          {uiState.isCommonDate && (
            <div className="observation-date-picker">
              <label>
                <p className="observation-subheader">
                  What date were these {treeCount} trees observed?
                </p>
                <ObservationDate
                  defaultValue={commonFields.recorded_on}
                  onChange={(v) => updateCommonFields({ recorded_on: v })}
                />
              </label>
            </div>
          )}
        </section>
        <hr />
        <section className="observation-common-fields">
          <h3 className="observation-heading">Observer and team</h3>
          <p className="observation-subheader">
            Were all {treeCount} trees observed by the same people?
          </p>
          <label className="observation-option">
            <input
              className="observation-input"
              type="radio"
              name="observer"
              checked={uiState.isCommonObserver}
              onChange={() => updateUiState({ isCommonObserver: true })}
            />
            Yes, all were observed by the same people
          </label>
          <label className="observation-option">
            <input
              className="observation-input"
              type="radio"
              name="observer"
              checked={!uiState.isCommonObserver}
              onChange={() => updateUiState({ isCommonObserver: false })}
            />
            No, different trees were observed by different people
          </label>

          {uiState.isCommonObserver && (
            <ObservationObservers
              currentUserContact={currentUserContact}
              observer={commonFields.observer}
              setObserver={(v) => updateCommonFields({ observer: v })}
              additionalObservers={commonFields.additional_observers}
              setAdditionalObservers={(v) =>
                updateCommonFields({ additional_observers: v })
              }
            />
          )}
        </section>
      </div>
    </>
  )
}

export { ObservationCommonFields }
