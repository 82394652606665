import React from 'react'
import ClearIcon from '../../shared/images/clear_icon.svg'
import './InputWithClear.scss'

const InputWithClear = ({ name, id, value, setValue, placeholder }) => {
  return (
    <div className="InputWithClear">
      <input
        type="text"
        id={id}
        name={name}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        placeholder={placeholder}
      />
      {value && (
        <button
          className="clear-icon"
          type="button"
          onClick={() => setValue('')}
        >
          <ClearIcon />
        </button>
      )}
    </div>
  )
}

export default InputWithClear
