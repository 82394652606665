import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SelectAsyncPaginatedRansack from './SelectAsyncPaginatedRansack'
import { OrgSelectInput } from './OrgInputs'
// import './ChapterParcelRansackUncontrolled.scss'

const ChapterParcelRansackUncontrolled = ({
  chapters,
  disabled,
  parcel,
  input,
}) => {
  const [currentParcel, setCurrentParcel] = useState(parcel || { id: '' })
  return (
    <div
      className="ChapterParcelRansackUncontrolled"
      style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
    >
      <label
        htmlFor="ChapterParcelRansackUncontrolled-chapter"
        style={{ minWidth: '400px' }}
      >
        Parcel Chapter
        <OrgSelectInput
          inputId="ChapterParcelRansackUncontrolled-chapter"
          options={chapters}
          onChange={(chapterId) =>
            setCurrentParcel({ chapter_id: chapterId, id: '' })
          }
          value={currentParcel.chapter_id || ''}
          placeholder="Any"
        />
      </label>

      <label
        htmlFor="ChapterParcelRansackUncontrolled-parcel"
        style={{ minWidth: '400px' }}
      >
        Parcel
        <SelectAsyncPaginatedRansack
          disabled={disabled}
          onChange={setCurrentParcel}
          input={input}
          inputId="ChapterParcelRansackUncontrolled-parcel"
          model_name_plural="parcels"
          optionValue="id"
          json_data={false}
          extraFields={['description']}
          ransack_predicate="shortcode_or_name_cont"
          select={['id', 'name', 'shortcode', 'chapter_id']}
          label="description"
          value={currentParcel}
          writeable
          placeholder="Select a parcel"
          extraPredicates={
            currentParcel.chapter_id
              ? { chapter_id_eq: currentParcel.chapter_id }
              : undefined
          }
        />
      </label>
    </div>
  )
}

ChapterParcelRansackUncontrolled.defaultProps = {
  disabled: false,
  parcel: undefined,
}

ChapterParcelRansackUncontrolled.propTypes = {
  chapters: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  disabled: PropTypes.bool,
  parcel: PropTypes.shape({
    id: PropTypes.number,
    shortcode: PropTypes.string,
    chapter_id: PropTypes.number,
  }),
}

export default ChapterParcelRansackUncontrolled
