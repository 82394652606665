// can be used as a controlled or uncontrolled component
// if used as a controlled component, pass in value and onChange
// if used as an uncontrolled component, pass in initialValue
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SelectAsyncPaginatedRansack from './SelectAsyncPaginatedRansack'
import { parseRailsInput } from './parseRailsInput'

const ContactRansack = ({
  formLabel,
  initialValue,
  input,
  isMulti,
  onChange,
  optionValue,
  self,
  styles,
  value,
}) => {
  if ([value, onChange].filter(Boolean).length === 1)
    console.error(
      'ContactRansack: if value is passed, onChange must also be passed and vice versa'
    )
  if (!!onChange && !!initialValue)
    console.error(
      'ContactRansack: if initialValue is passed, onChange should not be passed'
    )

  const [uncontrolledValue, setUncontrolledValue] = useState(initialValue)

  const handleEnterSelf = () => {
    const newValue = isMulti ? [self] : self
    setUncontrolledValue(newValue)
    if (onChange) onChange(newValue)
  }

  const handleChange = (inputValues) => {
    setUncontrolledValue(inputValues)
    if (onChange) onChange(inputValues)
  }

  return (
    <div className="ContactRansack" style={{ marginBottom: '10px' }}>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <label htmlFor={parseRailsInput(input)?.id}>{formLabel}</label>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <button
            type="button"
            className="enter-self-button btn-xs btn-primary"
            style={{ marginBottom: '4px', cursor: 'pointer' }}
            onClick={handleEnterSelf}
            tabIndex={-1}
          >
            Enter Self
          </button>
        </div>
        <SelectAsyncPaginatedRansack
          model_name_plural="contacts"
          optionValue={optionValue}
          input={input}
          json_data={true}
          isMulti={isMulti}
          ransack_predicate="long_name_cont"
          select="name, id, user_id"
          label="description"
          value={value || (uncontrolledValue?.id && uncontrolledValue)}
          onChange={handleChange}
          styles={styles}
        />
      </div>
    </div>
  )
}

/* eslint-disable react/require-default-props */

ContactRansack.propTypes = {
  initialValue: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.string,
  }),
  self: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    name: PropTypes.string,
    user_id: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  input: PropTypes.string,
  formLabel: PropTypes.string,
  optionValue: PropTypes.string,
  styles: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      description: PropTypes.string,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        description: PropTypes.string,
      })
    ),
  ]),
}

export default ContactRansack
