import React from 'react'
import PropTypes from 'prop-types'
import './UnavailableSpace.scss'

const UnavailableSpace = ({ space }) => {
  const code = space.status_code
  return <div className={`UnavailableSpace ${code}-space`}>{code}</div>
}

UnavailableSpace.propTypes = {
  space: PropTypes.shape({
    status_code: PropTypes.oneOf(['T', 'G', 'u', 'n', 'd', 'p']),
  }).isRequired,
}

export default UnavailableSpace
