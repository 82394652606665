import React from 'react'
import ContactRansack from '../application/ContactRansack'
import './ObservationObservers.scss'

const UNIT_OF_ADDITIONAL_OBSERVERS = ['', '', '']
const CUSTOM_STYLES = {
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    ':hover': { color: 'white', background: '#2b8797' },
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#206470',
  }),
}

const ObservationObservers = ({
  currentUserContact,
  observer,
  setObserver,
  additionalObservers,
  setAdditionalObservers,
}) => {
  return (
    <div className="ObservationObservers">
      <div className="multi-select-container">
        <label htmlFor="observer-names">
          <span className="observation-subheader">Observer name</span>{' '}
        </label>
        <div className="multi-select">
          <ContactRansack
            isMulti={false}
            value={observer}
            formLabel=""
            optionValue="id"
            self={currentUserContact}
            onChange={({ name, id, description }) =>
              setObserver({ name, id, description })
            }
            styles={CUSTOM_STYLES}
          />
        </div>
      </div>

      <div className="name-fields-container">
        <label htmlFor="name-inputs">
          <span className="observation-subheader">
            Who else was on the observation team for these trees?
          </span>{' '}
          <span className="observation-instruction">(OPTIONAL)</span>
          <AdditionalObserverForm
            observers={additionalObservers}
            setObservers={setAdditionalObservers}
          />
        </label>
      </div>
    </div>
  )
}

/*
 * "Additional observers" is a set of text input fields, starting with 3 fields.
 * A button adds 3 more fields at a time.
 * The state (how many fields to show) is a side effect of adding empty strings in sets of 3 to the
 * state array. This simplifies state management overall, but also...
 * Consumers of this state must filter out empty fields to play well with this simple model.
 */
const AdditionalObserverForm = ({ observers, setObservers }) => {
  const handleInputChange = (index, event) => {
    // Create a new array with the same values as the current observers
    const newObservers = [...observers]
    // Update the value at the specified index
    newObservers[index] = event.target.value
    // Set the state to the new array
    setObservers(newObservers)
  }

  const addMoreObservers = () => {
    // Add N more empty strings to the array to create N new input fields
    setObservers([...observers, ...UNIT_OF_ADDITIONAL_OBSERVERS])
  }

  return (
    <div className="additional-observers">
      {observers.map((observer, index) => (
        // this one really needs the key to be stable by position (I think?)
        // eslint-disable-next-line react/no-array-index-key
        <div className="additional-observer-row" key={index}>
          <label htmlFor={`additional-observer-names-${index}`}>Name</label>
          <input
            id={`additional-observer-names-${index}`}
            type="text"
            value={observer}
            onChange={(event) => handleInputChange(index, event)}
          />
        </div>
      ))}
      <button type="button" onClick={addMoreObservers}>
        + Add 3 more names
      </button>
    </div>
  )
}

export { ObservationObservers, UNIT_OF_ADDITIONAL_OBSERVERS }
