// Entry point for the build script in your package.json

import { Turbo } from '@hotwired/turbo-rails'
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

require('./register_react_components')

window.tippy = tippy
Turbo.session.drive = false
