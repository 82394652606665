import React, { useState } from "react"
import Modal from 'react-modal';
import './ErrorModal.scss';

function ErrorModal({isOpen, setIsOpen, message, destination, heightHintMessageLines}) {

  let height = '200px';
  if (heightHintMessageLines) {
    height = `${200 + (20*heightHintMessageLines)}px`
  }

  function handleClick() {
    setIsOpen(false)
    if (destination)
      window.location.href = destination;
  }

  return (
    <div id="ErrorModal">
      <Modal
        style={{
          content: {
            height: height,
            border: '2px solid black',
            inset: '250px 80px',
          }
        }}
        isOpen={isOpen}
        appElement={document.getElementById('AddPlantingWizard')}
        contentLabel="Error"
      >
        <div id="ErrorModalContent">
          {message}
          <button onClick={handleClick} className="ok">Ok</button>
        </div>
      </Modal>
    </div>
  );
}

export default ErrorModal