import React from 'react'

export const additionalPlannedTreeInputAttributes = [
  // TODO: add transgenic back in
  // { attribute: 'transgenic', label: 'Transgenic', inputType: 'checkbox' },
  {
    attribute: 'shortcode',
    label: 'Tree Shortcode',
    placeholder: 'use default',
  },
  {
    attribute: 'name',
    label: 'Tree Name',
    placeholder: 'use shortcode',
  },
  { attribute: 'tag', label: 'Tree Tag' },
  { attribute: 'comments', label: 'Comments' },
  {
    attribute: 'planted_as',
    label: 'Planted As',
    placeholder: (plantingItem) =>
      plantingItem.type === 'PlantingGenet' ? 'plantlet' : 'seed',
  },
]

const TreeAttributeInput = ({
  attribute,
  inputType,
  placeholder,
  plannedTreesBySpace,
  plantingItems,
  reviewMode,
  space,
  updatePlannedTree,
}) => {
  const plannedTree = plannedTreesBySpace[space.id]
  const plantingItem = plantingItems[plannedTree?.planting_item_id]
  return (
    <input
      key={`${space.id}-${attribute}`}
      type={inputType || 'text'}
      value={plannedTree?.[attribute] || ''}
      onChange={(e) =>
        updatePlannedTree({
          [attribute]:
            inputType === 'checkbox' ? e.target.checked : e.target.value,
        })
      }
      checked={!!plannedTree?.[attribute]}
      disabled={reviewMode}
      placeholder={
        typeof placeholder === 'function'
          ? placeholder(plantingItem)
          : placeholder
      }
    />
  )
}

export const AdditionalPlannedTreeInputs = ({
  plannedTreesBySpace,
  plantingItems,
  space,
  reviewMode,
  updatePlannedTree,
}) => (
  <>
    {additionalPlannedTreeInputAttributes.map((props) =>
      plannedTreesBySpace[space.id] ? (
        <TreeAttributeInput
          key={`${space.id}-${props.attribute}`}
          plannedTreesBySpace={plannedTreesBySpace}
          plantingItems={plantingItems}
          reviewMode={reviewMode}
          space={space}
          updatePlannedTree={updatePlannedTree}
          {...props}
        />
      ) : (
        <div key={`${space.id}-${props.attribute}`} />
      )
    )}
  </>
)
