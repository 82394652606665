import React from 'react'
import './PicklistObservation.scss'
import { Trait } from './trait'

const BooleanObservation = ({ label = '', trait, setTrait, tooltipId }) => {
  return (
    <div className="BooleanObservation">
      <label htmlFor="measurement-input">{label}</label>
      <select
        value={trait.value}
        onChange={(e) =>
          setTrait(Trait.merge(trait, { value: e.target.value }))
        }
      >
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <option key="--" value="" label=" " />
        <option key="true" value="true">
          True
        </option>
        <option key="false" value="false">
          False
        </option>
      </select>
      {tooltipId && (
        <span className="tooltip-icon" id={tooltipId}>
          i
        </span>
      )}
    </div>
  )
}

export default BooleanObservation
