import React from 'react'
import Select from 'react-select'
import './PlantingAttributes.scss'

const PlantingAttributes = ({
  currentPlanting,
  updateCurrentPlanting,
  options,
  reviewMode,
}) => (
  <div className="PlantingAttributes">
    <div className="left-column">
      <label htmlFor="planting-name-input">Name:</label>
      <input
        disabled={reviewMode}
        type="text"
        id="planting-name-input"
        value={currentPlanting.name || ''}
        onChange={(e) => updateCurrentPlanting({ name: e.target.value })}
      />
      <label htmlFor="planting-shortcode-input">Shortcode:</label>
      <input
        disabled={reviewMode}
        type="text"
        id="planting-shortcode-input"
        value={currentPlanting.shortcode || ''}
        onChange={(e) => updateCurrentPlanting({ shortcode: e.target.value })}
      />
      <label htmlFor="planted_on">Planted On:</label>
      <input
        disabled={reviewMode}
        type="date"
        id="planted_on"
        value={currentPlanting.planted_on || ''}
        onChange={(e) => updateCurrentPlanting({ planted_on: e.target.value })}
      />
      <label htmlFor="planting_type-input">Planting Type:</label>
      <Select
        inputId="planting_type-input"
        isDisabled={reviewMode}
        options={options.planting_type}
        value={
          options.planting_type.find(
            ({ value }) => value === currentPlanting.planting_type
          ) || ''
        }
        onChange={(opt) => updateCurrentPlanting({ planting_type: opt.value })}
      />
    </div>
    <div className="right-column">
      <label htmlFor="planting_comments-input">Comments:</label>
      <textarea
        disabled={reviewMode}
        id="planting_comments-input"
        value={currentPlanting.comments || ''}
        onChange={(e) => updateCurrentPlanting({ comments: e.target.value })}
      />
    </div>
  </div>
)

export default PlantingAttributes
