import React from 'react'
import InputWithClear from './InputWithClear.jsx'
import './SearchForm.scss'

const SearchForm = ({ setValue, value }) => {
  return (
    <div className="SearchForm">
      <form
        acceptCharset="UTF-8"
        action="/dashboard/search"
        className="ng-pristine ng-valid"
        method="get"
        noValidate
      >
        <InputWithClear
          name="q"
          id="q"
          value={value}
          setValue={setValue}
          placeholder="search everywhere"
        />
        <input className="btn btn-primary" type="submit" value="Go" />
      </form>
    </div>
  )
}

export default SearchForm
