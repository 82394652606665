import React, { useState, useEffect } from 'react'
import Creatable from 'react-select/creatable'
import { fetchRecords, tally } from '../../../shared/utils.js'

const calculateErrors = (value, mapcodes) => {
  if (mapcodes.includes(value)) return 'mapcode is already in use'

  // Define the allowed characters
  const allowedRegex = /^[a-zA-Z0-9!#&()+\-./:?[\]_\s]+$/
  if (allowedRegex.test(value)) return ''

  // Find characters not matching the allowed, make unique with a Set
  const invalidChars = [
    ...new Set(value.split('').filter((char) => !allowedRegex.test(char))),
  ].join('')
  // Return error message with the list of disallowed characters
  return `The following characters are not allowed: ${invalidChars}`
}

const url = '/planting_items/ransack.json'

const MapCodeInput = ({
  mapcodes,
  currentPlantingItem,
  onChange,
  setErrors, // function to set error state on the parent; will be given a bool
}) => {
  const [mapCodeOptions, setMapCodeOptions] = useState([])
  const [errorString, setErrorString] = useState('')

  const handleChange = (selectedOption) => {
    const errors = calculateErrors(selectedOption?.value, mapcodes)
    setErrors(!!errors)
    setErrorString(errors)
    onChange(errors ? '' : selectedOption?.value)
  }

  useEffect(() => {
    const loadOptions = async () => {
      const params = {
        select: ['id', 'planting_id', 'code'],
        filters: {
          genetic_origin_id_eq: currentPlantingItem.genetic_origin_id,
          genetic_origin_type_eq: currentPlantingItem.genetic_origin_type,
        },
      }

      const processResult = (items) =>
        Object.entries(tally(items.map((pi) => pi.code))).map(
          ([code, count]) => ({
            label: `${code} (used in ${count} planting${count > 1 ? 's' : ''})`,
            value: code,
          })
        )

      const options = await fetchRecords({
        url,
        params,
        processResult,
      })

      setMapCodeOptions(options)
    }
    if (currentPlantingItem.genetic_origin_id) loadOptions()
    else setMapCodeOptions([])
  }, [
    currentPlantingItem.genetic_origin_type,
    currentPlantingItem.genetic_origin_id,
  ])

  let placeholder = 'Select a Genetic Origin'
  if (currentPlantingItem.genetic_origin_id) {
    placeholder = mapCodeOptions.length
      ? 'Select a mapcode or type a new one'
      : 'Type a new mapcode. This item has never been planted before.'
  }

  return (
    <div className="MapCodeInput">
      <label htmlFor="modalMapCodeInput">
        <h4>Mapcode</h4>
      </label>
      <Creatable
        inputId="modalMapCodeInput"
        placeholder={placeholder}
        value={
          currentPlantingItem.code && {
            value: currentPlantingItem.code,
            label: currentPlantingItem.code,
          }
        }
        options={mapCodeOptions}
        onChange={handleChange}
        isDisabled={!currentPlantingItem.genetic_origin_id}
        isClearable
      />

      <div className="errors" style={{ color: 'red' }}>
        {errorString}
      </div>
    </div>
  )
}

export default MapCodeInput
