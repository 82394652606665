import React from 'react'

export const TreeSpaceTooltip = ({ tree }) => {
  return (
    <div className="tree-details">
      <span className="tree-name">{tree?.name}</span>
      <br />
      {tree?.cross_or_genet_name}
      <br />
      Date Planted: {tree?.planted_on}
      <br />
      <span>
        Planting:{' '}
        <a
          href={`/plantings/${tree?.planting_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {tree?.planting_name}
        </a>
      </span>
      <br />
      <span>
        Space: {parseFloat(tree?.latitude)?.toFixed(5)},{' '}
        {parseFloat(tree?.longitude)?.toFixed(5)}
      </span>
    </div>
  )
}
