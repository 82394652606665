import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { getContrastingColor } from '../../../shared/utils'

const PlannedTreeSelect = ({
  plantingItemOptions,
  onChange,
  selectedOption,
  inputId,
  gridLayout,
  space,
}) => {
  const [isFocused, setIsFocused] = React.useState(false)
  const [isFirstLoad, setIsFirstLoad] = React.useState(true)
  const selectRef = React.useRef(null)
  const buttonRef = React.useRef(null)

  const handleChange = (option) => {
    onChange(option)
    setIsFocused(false)
  }

  const handleKeyDown = (e) => {
    if (['Enter', 'Escape'].includes(e.key)) setIsFocused(false)
  }

  React.useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false)
      return
    }
    if (isFocused) selectRef.current?.focus()
    else buttonRef.current?.focus()
  }, [isFocused])

  return (
    <div
      className="PlannedTreeSelect"
      data-test-id={space.id}
      style={{ height: '100%', width: '100%' }}
    >
      {isFocused ? (
        <Select
          ref={selectRef}
          value={selectedOption}
          inputId={inputId}
          isClearable
          options={plantingItemOptions}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={false}
          menuIsOpen
          onBlur={() => setIsFocused(false)}
          styles={{
            menu: (baseStyles) => ({
              ...baseStyles,
              minWidth: 'max-content',
              zIndex: 999999,
            }),
            dropdownIndicator: () => ({ display: 'none' }),
            control: (baseStyles) => ({
              ...baseStyles,
              height: '100%',
              minHeight: '0',
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: '0',
              height: '100%',
              minHeight: '0',
            }),
            option: (baseStyles, { data, isSelected }) => ({
              ...baseStyles,
              margin: '1px 0',
              backgroundColor: data.color || 'white',
              color: getContrastingColor(data.color),
              fontWeight: isSelected ? 'bold' : 'normal',
              textDecoration: isSelected ? 'underline' : 'none',
              fontStyle: isSelected ? 'italic' : 'normal',
              ':active': {
                ...baseStyles[':active'],
                backgroundColor: data.color,
              },
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              ...(gridLayout ? {} : { height: '32px', minHeight: '0' }),
            }),
          }}
        />
      ) : (
        <button
          style={{
            height: '100%',
            width: '100%',
            background: selectedOption?.color || 'white',
            borderColor: 'black',
            minWidth: gridLayout ? '40px' : 'max-content',
            color: getContrastingColor(selectedOption?.color),
          }}
          type="button"
          ref={buttonRef}
          onClick={() => setIsFocused(true)}
        >
          {selectedOption?.label || ''}
        </button>
      )}
    </div>
  )
}

const optionShape = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
})

PlannedTreeSelect.defaultProps = {
  selectedOption: null,
}

PlannedTreeSelect.propTypes = {
  plantingItemOptions: PropTypes.arrayOf(optionShape).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedOption: optionShape,
  inputId: PropTypes.string.isRequired,
  gridLayout: PropTypes.bool.isRequired,
  space: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default PlannedTreeSelect
