import React from 'react'
import GenericModal from '../application/GenericModal.jsx'

const ObservationErrorModal = ({ errors = [], isOpen, onCancel }) => {
  const errorList = errors.map((error, index) => {
    return <li key={index}>{error}</li>
  })

  return (
    <div id="ObservationErrorModal"> 
      <GenericModal
        isOpen={isOpen}
        header="Errors while saving observations:"
        appElementId="ObservationErrorModal"
        onRequestClose={onCancel}
        buttons={[{ text: 'Cancel', onClick: onCancel, style: 'ok' }]}
      >
        <div className="ObservationErrorModalInsideModal">
          <ul>{errorList}</ul>
        </div>
      </GenericModal>
    </div>
  )
}

export default ObservationErrorModal
