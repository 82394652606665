import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import './RecordsTable.scss'
import SortableColumnHeader from '../planting/SortableColumnHeader'
import { sortBy } from '../../shared/utils'

/**
 * Generically renders a table with column specifications and row data
 * Rows are optionally selectable if setSelectedIds is provided
 *
 * columns: array of objects describing column properties, properties include:
 *   header: string to display in the column header
 *   cssClass: css class to apply to the column
 *   layouts: layout(s) that show this column, space separated list of: desktop mobile
 *   defaultSort: boolean that indicates this column should be sorted by default
 *   defaultSortDirection: 'ascending' or 'descending' to indicate the default sort direction
 *   render: function to render the column content (iterating from `records`)
 *   sortableBy: *record* field name to sort by, or undefined if not sortable
 *     sortable columns will render a SortableColumnHeader in the header
 *     the first sortable column will be sorted by default (ascending)
 *     only one column can be sorted at a time
 *
 * records: array of objects to display, used by column.render, and sorting
 * selectedIds: a javascript Set of record ids that are selected
 * setSelectedIds: function to call when a record is selected (leave undefined if records are not selectable)
 * children: renders after the table, convenient for optional components, e.g. tooltips
 */
const RecordsTable = ({
  columns,
  records,
  selectedIds = new Set(),
  setSelectedIds,
  children,
}) => {
  const hasSelectableColumn = !!setSelectedIds
  const selectAllCheckboxRef = useRef()
  const defaultSortBy = columns.find((c) => c.defaultSort)?.sortableBy
  const defaultSortDirection = columns.find(
    (c) => c.defaultSortDirection
  )?.defaultSortDirection
  const firstSortableBy =
    defaultSortBy || columns.find((c) => 'sortableBy' in c)?.sortableBy
  const [currentSort, setCurrentSort] = useState(
    firstSortableBy === undefined
      ? { by: null, direction: null }
      : { by: firstSortableBy, direction: defaultSortDirection || 'ascending' }
  )
  const rows =
    firstSortableBy === undefined
      ? records
      : records.sort(
          sortBy(currentSort.by, currentSort.direction === 'descending')
        )

  const newSortDirection = (sortableBy) => {
    const isChangingSortColumn = currentSort.by !== sortableBy
    const currentDirection = isChangingSortColumn ? null : currentSort.direction
    if (currentDirection === null || currentDirection === 'descending')
      return 'ascending'
    return 'descending'
  }

  const toggleSort = (sortableBy) => {
    const nextSort = {
      by: sortableBy,
      direction: newSortDirection(sortableBy),
    }
    rows.sort(sortBy(nextSort.by, nextSort.direction === 'descending'))
    setCurrentSort(nextSort)
  }

  const thForColumn = (column) => {
    const isCurrentSortedColumn = currentSort.by === column.sortableBy
    return (
      <th key={column.header || 'blank'} className={column.cssClass}>
        {'sortableBy' in column ? (
          <SortableColumnHeader
            name={column.header}
            sort={isCurrentSortedColumn ? currentSort.direction : null}
            toggleSort={() => toggleSort(column.sortableBy)}
          />
        ) : (
          column.header
        )}
      </th>
    )
  }

  const handleCheckboxChange = (recordId, isChecked) => {
    if (!hasSelectableColumn) return
    const newSelectedIds = new Set(selectedIds)
    if (isChecked) newSelectedIds.add(recordId)
    else newSelectedIds.delete(recordId)
    setSelectedIds(newSelectedIds)
  }

  const handleSelectAllChange = (isChecked) =>
    setSelectedIds(isChecked ? new Set(rows.map((r) => r.id)) : new Set())

  const selectAllTh = () => (
    <th>
      <input
        type="checkbox"
        aria-label="Select all"
        ref={selectAllCheckboxRef}
        checked={rows.length && rows.every((r) => selectedIds.has(r.id))}
        onChange={(e) => handleSelectAllChange(e.target.checked)}
      />
    </th>
  )

  useEffect(() => {
    if (selectAllCheckboxRef.current) {
      const countSelected = rows.filter((r) => selectedIds.has(r.id)).length
      selectAllCheckboxRef.current.indeterminate =
        countSelected > 0 && countSelected < rows.length
    }
  }, [selectedIds, rows.length])

  return (
    <div className="RecordsTable">
      <table>
        <thead>
          <tr>
            {hasSelectableColumn && selectAllTh()}
            {columns.map((col) => thForColumn(col))}
          </tr>
        </thead>
        <tbody>
          <TableRows
            rows={rows}
            columns={columns}
            hasSelectableColumn={hasSelectableColumn}
            selectedIds={selectedIds}
            handleCheckboxChange={handleCheckboxChange}
          />
        </tbody>
      </table>
      {children}
    </div>
  )
}

const TableRows = ({
  rows,
  columns,
  hasSelectableColumn,
  selectedIds,
  handleCheckboxChange,
}) => {
  const tdForColumn = (column, record) => (
    <td
      key={column.header || 'blank'}
      className={`col ${column.cssClass ?? ''}`}
      data-testid={column.testId}
    >
      {column.render(record)}
    </td>
  )

  const selectableTd = (recordId) => (
    <td>
      <input
        type="checkbox"
        aria-label="Select row"
        checked={selectedIds.has(recordId)}
        onChange={(e) => handleCheckboxChange(recordId, e.target.checked)}
      />
    </td>
  )

  return (
    <>
      {rows.map((row, index) => (
        <tr key={row.id} className={index % 2 === 1 ? 'odd' : 'even'}>
          {hasSelectableColumn && selectableTd(row.id)}
          {columns.map((column) => tdForColumn(column, row))}
        </tr>
      ))}
    </>
  )
}

export default RecordsTable

RecordsTable.defaultProps = {
  selectedIds: null,
  setSelectedIds: null,
}

const recordType = PropTypes.shape({ id: PropTypes.number.isRequired })
const columnType = PropTypes.shape({
  cssClass: PropTypes.string,
  header: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  testId: PropTypes.string,
})

RecordsTable.propTypes = {
  columns: PropTypes.arrayOf(columnType).isRequired,
  records: PropTypes.arrayOf(recordType).isRequired,
  selectedIds: PropTypes.instanceOf(Set),
  setSelectedIds: PropTypes.func,
}
