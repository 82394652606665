import React from 'react'
import './TreeMarker.scss'

const redMarker = require('../../shared/images/red-tree-marker.png')
const redMarkerSelected = require('../../shared/images/red-tree-marker-selected.png')
const greenMarker = require('../../shared/images/green-tree-marker.png')

const markerColorLookup = {
  'red': {
    'active': redMarkerSelected,
    '': redMarker,
  },
  'green': {
    'active': greenMarker,
    '': greenMarker,
  },
}

const TreeMarker = ({ onClick, name= '', current, color = 'red', tooltipTreeId = null }) => {
  const active = current === true ? 'active' : '';
  const src = markerColorLookup[color][active]
  const nameStr = String(name) // because name might have been typeof Number
  const tooltipId = tooltipTreeId ? 'tree-marker-tooltip' : null

  return (
    <div className="TreeMarker">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
      <img onClick={onClick}
           className={active}
           src={src}
           alt="Tree marker"
           // tooltip props are skipped if null
           data-tooltip-content={tooltipTreeId}
           data-tooltip-id={tooltipId}
      />
      { nameStr.length > 0 &&
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
          <p className="pin-text" onClick={onClick}>
            {nameStr}
          </p>
      }
    </div>
  )
}

export default TreeMarker
