import React from 'react'
import './AdvancedObservations.scss'
import { Trait, TRAIT_CFG } from './trait'
import StringObservation from './StringObservation'
import NumericObservation from './NumericObservation'
import PicklistObservation from './PicklistObservation'
import BooleanObservation from './BooleanObservation'

const AdvancedObservations = ({
  traitConfigs = {},
  tree = {},
  updateSelectedTree,
}) => {
  const advancedConfigs = traitConfigs.filter(
    (traitConfig) =>
      !TRAIT_CFG.top_panel_only_trait_ids.includes(traitConfig.id)
  )

  return (
    <div className="AdvancedObservations">
      <div className="section-title">
        Want to make advanced observations about Chester?
        <span className="subtitle">(OPTIONAL)</span>
      </div>
      {advancedConfigs.map((traitConfig) => {
        const { id } = traitConfig
        const trait =
          Trait.getTrait(tree, id) ?? Trait.toDefaultLocalState(traitConfig)
        return (
          <details key={traitConfig.id} open={!!trait.value}>
            <summary>{traitConfig.name}</summary>
            <div className="trait-widget">
              <TraitWidget
                className="trait-widget"
                traitConfig={traitConfig}
                trait={trait}
                updateSelectedTree={updateSelectedTree}
              />
            </div>
          </details>
        )
      })}
    </div>
  )
}

const TraitWidget = ({ traitConfig = {}, trait = {}, updateSelectedTree }) => {
  const { name, id } = traitConfig
  const type = traitConfig.trait_type
  const allowedUnits = traitConfig.units
  switch (type) {
    case 'text':
      return (
        <StringObservation
          label={name}
          trait={trait}
          setTrait={(v) => updateSelectedTree(Trait.toMergeGlobalState(id, v))}
        />
      )
    case 'float':
    case 'integer':
      return (
        <NumericObservation
          label={name}
          trait={trait}
          setTrait={(v) => updateSelectedTree(Trait.toMergeGlobalState(id, v))}
          allowedUnits={allowedUnits}
        />
      )
    case 'picklist':
      return (
        <PicklistObservation
          label={name}
          trait={trait}
          options={traitConfig.picklist.picklist_options}
          setTrait={(v) => updateSelectedTree(Trait.toMergeGlobalState(id, v))}
        />
      )
    case 'boolean':
      return (
        <BooleanObservation
          label={name}
          trait={trait}
          setTrait={(v) => updateSelectedTree(Trait.toMergeGlobalState(id, v))}
        />
      )
    default:
      return (
        <div className="error">
          &quot;{name}&quot;, id {id}: Type [{type}] not implemented.
        </div>
      )
  }
}

export default AdvancedObservations
