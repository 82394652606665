import React from 'react'
import PropTypes from 'prop-types'
import ToggleButtons from '../application/ToggleButtons'
import ChapterTreeRansack from './ChapterTreeRansack'
import { OrgSelectInput } from '../application/OrgInputs'
import SelectAsyncPaginatedRansack from '../application/SelectAsyncPaginatedRansack'
import './CrossParentChooser.scss'

const maleOptions = [
  'Tree in system',
  'Tree not in system',
  'Stored Pollen',
  'Open Pollinated',
]
const femaleOptions = maleOptions.slice(0, 2)

const getTab = (cross, male_or_female) => {
  if (male_or_female === 'male' && cross.pollen_store_id) return 'Stored Pollen'
  if (cross[`${male_or_female}_parent_id`]) return 'Tree in system'
  if (cross[`${male_or_female}_shortcode`]) return 'Tree not in system'
  if (male_or_female === 'male') return 'Open Pollinated'
  return ''
}

const hiddenInputs = (male_or_female, prefix, values) => (
  <>
    <input
      type="hidden"
      name={`${prefix}[${male_or_female}_parent_id]`}
      value={values.tree_id || ''}
    />
    <input
      type="hidden"
      name={`${prefix}[${male_or_female}_shortcode]`}
      value={values.tree_shortcode || ''}
    />
    <input
      type="hidden"
      name={`${prefix}[${male_or_female}_parent_chapter_id]`}
      value={values.tree_chapter_id || ''}
    />
    {male_or_female === 'male' && (
      <input
        type="hidden"
        name={`${prefix}[pollen_store_id]`}
        value={values.pollen_store_id || ''}
      />
    )}
  </>
)

const ChapterChooser = ({ chapters, defaultValue, name }) => {
  const [currentChapter, setCurrentChapter] = React.useState(defaultValue)
  return (
    <OrgSelectInput
      options={chapters}
      placeholder="Choose Chapter"
      value={currentChapter}
      onChange={setCurrentChapter}
      name={name}
    />
  )
}

ChapterChooser.defaultProps = {
  defaultValue: undefined,
}

ChapterChooser.propTypes = {
  chapters: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
}

const CrossParentChooser = ({ cross, prefix, chapters, male_or_female }) => {
  const availableOptions =
    male_or_female === 'male' ? maleOptions : femaleOptions

  const [tab, setTab] = React.useState(() => getTab(cross, male_or_female))
  const [tree, setTree] = React.useState({
    id: cross[`${male_or_female}_parent_id`],
    shortcode: cross[`${male_or_female}_shortcode`],
    chapter_id: cross[`${male_or_female}_parent_chapter_id`],
  })
  const [pollenStore, setPollenStore] = React.useState({
    id: cross.pollen_store_id,
    tree_shortcode: cross?.male_shortcode,
    tree_chapter_id: cross?.male_parent_chapter_id,
    tree_id: cross?.male_parent_id,
    shortcode: cross?.pollen_store_shortcode,
  })

  if (cross.pollen_store_id && !cross.pollen_store_shortcode)
    console.error('Pollen store shortcode is missing')

  const renderContent = () => {
    switch (tab) {
      case 'Open Pollinated':
        return (
          <div>
            Open Pollinated
            {hiddenInputs(male_or_female, prefix, {})}
          </div>
        )
      case 'Stored Pollen':
        return (
          <div>
            <SelectAsyncPaginatedRansack
              model_name_plural="pollen_stores"
              ransack_predicate="shortcode_cont"
              label="shortcode"
              select={['shortcode', 'id', 'tree_id']}
              extraFields={['tree_shortcode', 'tree_chapter_id']}
              onChange={setPollenStore}
              value={pollenStore}
              optionValue="id"
            />
            {hiddenInputs(male_or_female, prefix, {
              ...pollenStore,
              pollen_store_id: pollenStore?.id,
            })}
          </div>
        )
      case 'Tree in system':
        return (
          <>
            <ChapterTreeRansack
              labels={{ chapter: 'Chapter', tree: 'Shortcode' }}
              chapters={chapters}
              onChange={setTree}
              tree={tree}
              writeable={false}
            />
            {hiddenInputs(male_or_female, prefix, {
              tree_id: tree?.id,
              tree_shortcode: tree?.shortcode,
              tree_chapter_id: tree?.chapter_id,
            })}
          </>
        )
      case 'Tree not in system':
        return (
          <div className="tree-chooser-container">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label>
              Chapter
              <ChapterChooser
                chapters={chapters}
                defaultValue={cross[`${male_or_female}_parent_chapter_id`]}
                name={`${prefix}[${male_or_female}_parent_chapter_id]`}
              />
            </label>
            <label>
              Shortcode
              <input
                type="text"
                defaultValue={cross[`${male_or_female}_shortcode`]}
                name={`${prefix}[${male_or_female}_shortcode]`}
              />
            </label>
          </div>
        )
      default:
        return <div>Select an option</div>
    }
  }

  return (
    <div className="CrossParentChooser">
      <ToggleButtons
        value={tab}
        options={availableOptions.map((opt) => ({ value: opt, label: opt }))}
        onChange={setTab}
      />
      <div className="content"> {renderContent(tab)}</div>
    </div>
  )
}

CrossParentChooser.defaultProps = {}

CrossParentChooser.propTypes = {
  cross: PropTypes.shape({
    pollen_store_id: PropTypes.number,
    male_parent_id: PropTypes.number,
    male_shortcode: PropTypes.string,
    male_parent_chapter_id: PropTypes.number,
    female_shortcode: PropTypes.string,
    female_parent_chapter: PropTypes.string,
    female_parent_id: PropTypes.number,
    pollen_store_shortcode: PropTypes.string,
  }).isRequired,
  prefix: PropTypes.string.isRequired,
  chapters: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  male_or_female: PropTypes.oneOf(['male', 'female']).isRequired,
}

export default CrossParentChooser
