import React from 'react'
import './ObservationReview.scss'
import PhotoCarousel from './PhotoCarousel'
import { filterAndReshapeDocuments } from './observation_utils'
import { Trait, TRAIT_CFG } from './trait'

const ObservationReview = ({
  trees,
  documents,
  editTree,
  isCommitted,
  traitConfigs,
}) => {
  return (
    <>
      <div className="header">
        {isCommitted ? (
          <h2>Review your submitted observations</h2>
        ) : (
          <h2>Review & confirm your observations</h2>
        )}
      </div>
      <div className="ObservationReview">
        {trees.map((tree, index) => (
          <React.Fragment key={tree.id}>
            <SingleTreeReview
              tree={tree}
              documents={filterAndReshapeDocuments(documents, tree.id)}
              index={index}
              editTree={editTree}
              isCommitted={isCommitted}
              traitConfigs={traitConfigs}
            />
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

const SingleTreeReview = ({
  tree,
  documents,
  index,
  editTree,
  isCommitted,
  traitConfigs,
}) => {
  const filteredAdditionalObserverList =
    tree.observations.additional_observers.filter(
      (name) => name.trim().length > 0
    )
  const prettyAdditionalObserverList =
    filteredAdditionalObserverList.length > 0 ? (
      filteredAdditionalObserverList.join(', ')
    ) : (
      <>&mdash;</>
    )

  let alive

  if (tree.observations?.traits?.survival?.value) {
    // User has entered a value for survival
    alive =
      tree.observations?.traits?.survival?.value === 'Alive' ? 'Yes' : 'No'
  } else {
    alive = ''
  }

  const advancedObservations = Object.entries(tree.observations?.traits).reduce(
    (acc, [_, trait]) => {
      if (!TRAIT_CFG.top_panel_only_trait_ids.includes(trait.id)) {
        acc.push(trait)
      }
      return acc
    },
    []
  )

  return (
    <div className="SingleTreeReview">
      <div className="tree-header">
        <h2>
          {tree.name} <span>Tree ({index + 1})</span>
        </h2>
        {!isCommitted && (
          <button type="button" onClick={() => editTree(index)}>
            Edit
          </button>
        )}
      </div>
      <div className="tree-content">
        <div className="tree-image">
          <PhotoCarousel
            tree={tree}
            documents={documents}
            showAddDelete={false}
          />
        </div>
        <div className="tree-info-1">
          <div>
            <span>Alive?</span> {alive}
          </div>
          <div>
            <span>Height?</span> {tree.observations?.traits?.height?.value}{' '}
            {tree.observations?.traits?.height?.units}
          </div>
          <div>
            <span>Diameter (DBH)?</span>{' '}
            {tree.observations?.traits?.diameter?.value}{' '}
            {tree.observations?.traits?.diameter?.units}
          </div>
        </div>
        <div className="tree-info-2">
          <span>Comments</span>
          <p>{tree.observations?.traits?.comments?.value}</p>
          <details>
            <summary>
              Advanced Observations ({advancedObservations.length})
            </summary>
            {advancedObservations.map((observation) => (
              <p key={observation.name}>
                {observation.name}:{' '}
                {Trait.getDisplayValue(
                  traitConfigs,
                  observation.id,
                  observation.value
                )}{' '}
                {observation.units}
              </p>
            ))}
          </details>
        </div>
        <div className="tree-observations">
          <div>
            <span>Observation Date</span> {tree.observations.recorded_on}
          </div>
          <div>
            <span>Observer</span>{' '}
            {tree.observations.observer?.description ||
              tree.observations.observer?.name ||
              'Unknown'}
          </div>
          <div>
            <span>Observation team</span> {prettyAdditionalObserverList}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ObservationReview
