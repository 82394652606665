export const TRAIT_CFG = {
  top_panel_only_trait_ids: [160, 161, 1, 58],
  height: {
    id: 160,
    name: 'Height',
    units: ['in', 'ft', 'cm', 'm'],
    defaultUnit: 'ft',
  },
  diameter: {
    id: 161,
    name: 'Diameter (DBH)',
    units: ['in', 'cm'],
    defaultUnit: 'in',
  },
  comments: {
    id: 1,
    name: 'Comments',
  },
  survival: {
    id: 58,
    name: 'Survival',
    remark: 'Trait values on the server expect Alive/Dead',
  },
}

/**
 * Convenience wrapper for a trait. Wraps and unwraps the raw object.
 * All statics, doesn't really need to be a class but it's nicely namespaced this way.
 */
export class Trait {
  // if a trait is missing in global state, fill in the default for the UI
  // the optional value will override the default
  // (can be null which will cause it to be pruned from the data at some point)
  static toDefaultLocalState = (cfg, value) => ({
    id: cfg.id,
    name: cfg.name,
    value: value !== undefined ? value : '',
    units: cfg.units[0] ?? '',
  })

  // update a trait in global state, basically handles the key path
  static toMergeGlobalState = (key, trait) => ({
    observations: { traits: { [key]: trait } },
  })

  // update a trait, skipping value and units if undefined
  static merge = (prevTrait, { value, units }) => {
    return {
      ...prevTrait,
      ...(value !== undefined && { value }),
      ...(units !== undefined && { units }),
    }
  }

  // pull the trait value from a tree object, by trait id
  static getTrait = (tree, id) => {
    return tree.observations.traits[id]
  }

  static getConfigById = (cfgs, id) => {
    return cfgs.find((cfg) => cfg.id === id)
  }

  static getDisplayValue = (cfgs, id, short) => {
    const cfg = Trait.getConfigById(cfgs, id)
    if (cfg.trait_type === 'picklist') {
      const option = cfg.picklist.picklist_options.find(
        (po) => po.short_value === short
      )
      return option.long_value
    }
    return short
  }
}
