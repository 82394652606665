import React, { useEffect } from 'react'
import './NumericObservation.scss'
import { Trait } from './trait'

const NumericObservation = ({
  label = '',
  trait,
  setTrait,
  allowedUnits = [],
  tooltipId,
}) => {
  useEffect(() => {
    if (!allowedUnits.includes(trait.units)) {
      const defaultUnit = allowedUnits[0]
      setTrait(Trait.merge(trait, { unit: defaultUnit }))
    }
  }, [])

  return (
    <div className="NumericObservation">
      <label htmlFor="measurement-input">{label}</label>
      <input
        id="measurement-input"
        type="number"
        value={trait.value}
        onChange={(e) =>
          setTrait(Trait.merge(trait, { value: e.target.value }))
        }
      />
      {/* Units show as a selector unless there is only one option */}
      {allowedUnits.length > 1 && (
        <select
          value={trait.units}
          onChange={(e) =>
            setTrait(Trait.merge(trait, { units: e.target.value }))
          }
        >
          {allowedUnits.map((unitItem) => (
            <option key={unitItem} value={unitItem}>
              {unitItem}
            </option>
          ))}
        </select>
      )}
      {allowedUnits.length === 1 && (
        <span className="single-units">{allowedUnits[0]}</span>
      )}
      {tooltipId && (
        <span className="tooltip-icon" id={tooltipId}>
          i
        </span>
      )}
    </div>
  )
}

export default NumericObservation
